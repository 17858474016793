import React, { useState } from "react";
import rtarrow from '../../../assets/icons/rtarrow.svg';
import pfp1 from '../../../assets/images/shuo.png';
import pfp2 from '../../../assets/images/liu.png';

const testimonials = [
    {
        text: "It’s a life saver . Working as a Teaching Assistant at UC Berkeley’s Mechanical Engineering division, I'm constantly grading and resolving student queries about course materials that run into many hours each week. With AnswersAi, I am able to gather up information and double check my solutions much more quickly, and allows my students to get quick feedback and problem-solving support. Brilliant!",
        name: "Hubert Liu",
        desig: "Teaching Assistant",
        company: "UC Berkeley",
        image: pfp2,
    },
    {
        text: "This is a wonderful example of generative AI leveling the playing field to accelerate more innovation. The AI Tutor helps answer basic questions of students and allows me to focus on student education with great speed allowing me to spend most time teaching.",
        name: "Shuo Chen",
        desig: "Professor",
        company: "UC Berkeley, Stanford",
        image: pfp1,
    },
];


const TestimonialCard = () => {

    const [currentIndex, setCurrentIndex] = useState(0);
    const handlenext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
        );
    };

    const { text, name, desig, company, image } = testimonials[currentIndex];

    return (
        <div className="flex flex-col pt-16 gap-8 ">
            <div className="flex w-full justify-between px-8">
                <div onClick={handlePrev} className="flex w-12 h-12 p-2 md:w-16 md:h-16 xl:w-20 xl:h-20 rounded-full bg-accent-800 justify-center items-center rotate-180 bg-opacity-10 hover:bg-opacity-40 border-2 border-white cursor-pointer transition-all">
                    <img className="" src={rtarrow} alt="arrow"></img> 
                </div>
                <div onClick={handlenext} className="flex z-20 w-12 h-12 p-2 md:w-16 md:h-16 xl:w-20 xl:h-20 rounded-full bg-accent-800 justify-center items-center bg-opacity-10 hover:bg-opacity-40 border-2 border-white cursor-pointer transition-all">
                    <img src={rtarrow}></img> 
                </div>
            </div>
            <div className="flex flex-col h-[600px] lg:h-[800px] xl:h-[700px] gap-10 max-md:px-8 p-16 lg:p-24 rounded-3xl items-center text-left md:text-center bg-b2btstmonbg bg-cover bg-bottom font-jakarta transition-all">
                <div className="flex flex-col h-full justify-between gap-20 md:gap-24">
                    <p className="text-md md:text-xl lg:text-3xl font-regular leading-tight">"{text}"</p>
                    <div className="flex flex-col gap-4 md:gap-6 items-start md:items-center">
                        <img className="w-16 md:w-20" src={image}></img>
                        <div className="flex flex-col gap-1">
                            <h3 className="font-bold text-2xl">{name}</h3>
                            <p className="">{desig} · {company}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default TestimonialCard;