export default function Banner() {
    return (
        <div className="relative isolate flex justify-center text-center items-center gap-x-6 overflow-hidden px-6 py-2.5 sm:px-3.5 sm:before:flex-1 md:hidden bg-gradient-to-b from-[#564CDA] to-[#2E16CB]">
            <div className="flex flex-wrap items-center gap-y-2">
                <a href="https://onelink.to/9h62gy" className="px-3.5 py-1 text-md font-semibold text-white hover:bg-gray-700 banner-text-hover">
                    Download on Mobile <span className="no-underline" aria-hidden="true">&rarr;</span>
                </a>
            </div>
        </div>
    );
}