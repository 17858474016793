import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import "react-phone-number-input/style.css";
import axios from "axios";
import toast from "react-hot-toast";
import OtpVerifyOtp from "./OtpVerifyOtp";
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { auth } from "../../firebase";
import ClipLoader from "react-spinners/ClipLoader";
import PricingPlans from "./PricingPlans";
import UserAllQuestions from "./UserAllQuestions";
import { FcGoogle } from "react-icons/fc";
import { MdEmail } from "react-icons/md";
import { BsFillPhoneFill } from "react-icons/bs";

function LoginModal({
  setHeroLoader,
  pricingPlansPopup,
  setPricingPlansPopup,
  setFlag,
  flag,
  setEmailData,
  emailData,
  userQuestions,
  setUserQuestions,
  logintype,
  setLoginType,
  show,
  setShow,
  handleClose,
  setLoggedInId,
  setIsPaymentDone,
  paymentPopup,
  setPaymentPopup,
  checkLoggedInStatus,
  showDisplayFeatureModel,
  setDisplayFeatureModel,
}) {
  const [number, setNumber] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  const [result, setResult] = useState("");
  const [otpFlag, setOtpFlag] = useState(false);
  const [otp, setOtp] = useState("");
  const [hideResend, setHideResend] = useState(true);
  const [counter, setCounter] = useState(1);
  const [signUpMethod, setSignUpMethod] = useState(false);
  const [email, setEmail] = useState("");
  const [allQuestion, setAllquestion] = useState(false);
  const [emailUserId, setEmailUserId] = useState("");
  const [emailPhone, setEmailPhone] = useState("");
  const [userQuestion1, setUserQuestion1] = useState("");
  const [userQuestion2, setUserQuestion2] = useState("");
  const [loader, setLoader] = useState(false);
  const [userCustomerId, setUserCustomerId] = useState("");
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [checkPrivacyPolicy, setCheckPrivacyPolicy] = useState(true);

  useEffect(() => {
    if (logintype == 1) {
      setSignUpMethod(true);
      setUserCustomerId(localStorage.getItem("customer_id"));
    } else if (logintype == 2) {
      setSignUpMethod(false);
      setUserCustomerId(localStorage.getItem("customer_id"));
    }
    setAllquestion(true);
  }, [userQuestions]);

  const signInWithGoogle = async () => {
    try {
      let provider = new GoogleAuthProvider();
      provider.setCustomParameters({
        prompt: "select_account ",
      });
      const result = await signInWithPopup(auth, provider);

      const user_token = result.user.accessToken;
      const user_email = result.user.email;

      localStorage.setItem("customer_id", result.user.uid);

      try {
        setLoader(true);
        const response = await axios.post(
          `${process.env.REACT_APP_API_ENDPOINT}/login-user`,
          {
            email: user_email,
            tokenid: user_token,
            refer_id: sessionStorage.getItem("refer_customer_id"),
            accept_privacy_policy: checkPrivacyPolicy,
          }
        );

        if (response.data.status == true) {
          localStorage.setItem("customer_id", response.data.user_id);

          setLoader(false);
          setLoggedInId(response.data.token);
          if (response.data.token && response.data.token !== "") {
            localStorage.setItem("token", JSON.stringify(response.data.token));
          } else {
            setLoader(false);
            localStorage.removeItem("token");
          }

          localStorage.setItem("email", JSON.stringify(email));
          localStorage.setItem("logintype", 2);
          setLoginType(2);
          axios
            .post(
              `${process.env.REACT_APP_API_ENDPOINT}/check-user-questions`,
              {
                user_id: response.data.user_id,
                logintype: logintype,
              },
              {
                headers: {
                  "x-access-token": JSON.parse(localStorage.getItem("token")),
                },
              }
            )
            .then((response) => {
              if (response.data.userQuestion) {
                setEmailData(response.data.data);
                setShow(false);
                setLoader(false);
                setOtpFlag(false);
                setUserQuestions(true);
              } else {
                setShow(false);
                setOtpFlag(false);
                checkLoggedInStatus();
                setLoader(false);
                setHeroLoader(true);
                localStorage.setItem(
                  "number",
                  JSON.stringify(response.data.number)
                );
                if (
                  response.data.paymentStatus != "" &&
                  response.data.paymentStatus != null &&
                  response.data.paymentStatus != undefined
                ) {
                  setPaymentPopup(false);
                  if (response.data.paymentStatus === "canceled") {
                    setPricingPlansPopup(true);
                  }
                } else {
                  setPaymentPopup(true);
                }
              }
            })
            .catch((err) => {
              setLoader(false);
            });
        } else {
          setLoader(false);
          toast.error(response.data.msg);
        }
      } catch (error) {
        console.error("Error", error);
        setLoader(false);
        toast.error(error.message);
      }
    } catch (error) {
      console.error("Google sign-in error:", error.message);
    }
  };

  useEffect(() => {
    if (counter === 0) {
      setHideResend(false);
    }
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const resendCode = async (e) => {
    resendOtp(e);
  };

  async function setUpRecaptcha(number) {
    try {
      if (Window.recaptchaVerifier) {
        Window.recaptchaVerifier.clear();
      }

      Window.recaptchaVerifier = await new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
        },
        auth
      );
      Window.recaptchaVerifier.verify();

      return await signInWithPhoneNumber(
        auth,
        number,
        Window.recaptchaVerifier
      );
    } catch (err) {
      if (Window.recaptchaVerifier) {
        Window.recaptchaVerifier.clear();
      }
      console.error("Error:", err);
      return err.code;
    }
  }

  async function setUpRecaptcha2(number) {
    try {
      if (Window.recaptchaVerifier) {
        Window.recaptchaVerifier.clear();
        const previousRecaptchaContainer = document.getElementById(
          "recaptcha-container"
        );
        if (previousRecaptchaContainer) {
          previousRecaptchaContainer.remove();
        }
        const newRecaptchaContainer = document.createElement("div");
        newRecaptchaContainer.id = "recaptcha-container";
        newRecaptchaContainer.className = "ml-2  mb-5 recaptcha-container";
        document
          .getElementById("repactcha-main-div")
          .append(newRecaptchaContainer);
      }
      Window.recaptchaVerifier = await new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
        },
        auth
      );
      return await signInWithPhoneNumber(
        auth,
        number,
        Window.recaptchaVerifier
      );
    } catch (err) {
      console.error("Error:", err.message);
      return err.message;
    }
  }

  const editFunction = (e) => {
    e.preventDefault();
    setOtp("");
    setFlag(true);
    setOtpFlag(false);
  };
  const getOtp = async (e) => {
    e.preventDefault();

    if (number != "") {
      const checkPolicy = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/check-privacy-policy`,
        {
          mobile_number: number,
        }
      );

      setShowPrivacyPolicy(checkPolicy.data.accept_privacy_policy);
    } else {
      const checkPolicy = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/check-privacy-policy`,
        {
          email: email,
        }
      );
      setShowPrivacyPolicy(checkPolicy.data.accept_privacy_policy);
    }

    if (signUpMethod) {
      if (number == "")
        return toast.error("Please enter a valid phone number!");
    } else {
      if (email == "") return toast.error("Please enter a valid email!");
    }
    setLoader(true);
    try {
      if (number != "") {
        const response = await setUpRecaptcha2(number);
        if (response.verificationId) {
          setResult(response);
          setLoginType(1);
          setFlag(false);
          setLoader(false);
          setOtpFlag(true);
          setCounter(59);
          setHideResend(true);
        } else {
          var msg;
          if (response == "Firebase: Error (auth/too-many-requests).") {
            msg = "Too Many Requests. Please try again later.";
          } else if (
            response == "Firebase: TOO_LONG (auth/invalid-phone-number)."
          ) {
            msg = "Invalid Phone Number. Please enter valid phone number.";
            setNumber("");
          }
          setLoader(false);
          toast.error(msg);
        }
      } else if (email != "") {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (emailRegex.test(email)) {
          const response = await axios.post(
            `${process.env.REACT_APP_API_ENDPOINT}/email`,
            {
              email: email,
            }
          );
          if (response.data.status) {
            setEmailUserId(response.data.id);
            setLoginType(2);
            setFlag(false);
            setLoader(false);
            setOtpFlag(true);
            setCounter(59);
            setHideResend(true);
          }
        } else {
          setDisableButton(false);
          setLoader(false);
          toast.error("Invalid Email");
        }
      }
    } catch (err) {
      setLoader(false);
      toast.error(err.message);
    }
  };

  const resendOtp = async (e) => {
    if (signUpMethod) {
      if (number === "" || number === undefined) {
        toast.error("Please enter a valid phone number!");
      }
      setLoader(true);
      try {
        const response = await setUpRecaptcha2(number);
        if (response.verificationId) {
          setResult(response);
          setLoader(false);
          setHideResend(true);
          setCounter(59);
        } else {
          setLoader(false);
          toast.error(response);
        }
      } catch (err) {
        setLoader(false);
        toast.error("Please enter a valid phone number!");
      }
    } else {
      if (email === "" || email === undefined)
        toast.error("Please enter a valid phone number!");
      axios
        .post(`${process.env.REACT_APP_API_ENDPOINT}/resend-email`, {
          email: email,
        })
        .then((response) => {
          if (response.data.status) {
            toast.success("Email sent successfully");
          } else {
            toast.error(response.data.message);
          }
        });
    }
  };

  const verifyOtp = async (e) => {
    e.preventDefault();

    if (otp === "" || otp === null) return toast.error("Please Enter OTP");
    setLoader(true);

    if (logintype == 1) {
      try {
        const res = await result.confirm(otp);
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_ENDPOINT}/login-user`,
            {
              mobile_number: number,
              tokenid: res._tokenResponse.idToken,
              refer_id: sessionStorage.getItem("refer_customer_id"),
              accept_privacy_policy: checkPrivacyPolicy,
            }
          );
          if (response.data.status == true) {
            setLoggedInId(response.data.token);
            if (response.data.token && response.data.token !== "") {
              localStorage.setItem(
                "token",
                JSON.stringify(response.data.token)
              );
            } else {
              localStorage.removeItem("token");
            }
            localStorage.setItem("customer_id", response.data.user_id);
            localStorage.setItem("number", JSON.stringify(number));
            localStorage.setItem("logintype", logintype);

            axios
              .post(
                `${process.env.REACT_APP_API_ENDPOINT}/check-user-questions`,
                {
                  user_id: response.data.user_id,
                  logintype: logintype,
                },
                {
                  headers: {
                    "x-access-token": JSON.parse(localStorage.getItem("token")),
                  },
                }
              )
              .then((response) => {
                if (response.data.userQuestion) {
                  setEmailData(response.data.data);
                  setShow(false);
                  setLoader(false);
                  setOtpFlag(false);
                  setUserQuestions(true);
                } else {
                  setShow(false);
                  setOtpFlag(false);
                  checkLoggedInStatus();
                  setLoader(false);
                  setHeroLoader(true);
                  if (
                    response.data.paymentStatus != "" &&
                    response.data.paymentStatus != null &&
                    response.data.paymentStatus != undefined
                  ) {
                    setPaymentPopup(false);
                    if (response.data.paymentStatus === "canceled") {
                      setPricingPlansPopup(true);
                    }
                  } else {
                    setPaymentPopup(true);
                  }
                }
              })
              .catch((err) => {
                setLoader(false);
                console.error(err.message);
              });
          } else {
            setLoader(false);
            toast.error(response.data.msg);
          }
        } catch (error) {
          console.error(error.message);

          setLoader(false);
          toast.error(error.message);
        }
      } catch (err) {
        setLoader(false);
        toast.error("Please Enter Valid OTP.");
      }
    } else {
      try {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_ENDPOINT}/verify-email-otp`,
            {
              email: email,
              id: emailUserId,
              OTP: otp,
            }
          );
          if (response.data.status == true) {
            setLoggedInId(response.data.data.token);
            if (response.data.data.token && response.data.data.token != "") {
              localStorage.setItem(
                "token",
                JSON.stringify(response.data.data.token)
              );
            } else {
              localStorage.removeItem("token");
            }
            localStorage.setItem("logintype", logintype);
            axios
              .post(
                `${process.env.REACT_APP_API_ENDPOINT}/check-user-questions`,
                {
                  user_id: response.data.data.user_id,
                  logintype: logintype,
                },
                {
                  headers: {
                    "x-access-token": JSON.parse(localStorage.getItem("token")),
                  },
                }
              )
              .then((response) => {
                if (response.data.userQuestion) {
                  setEmailData(response.data.data);
                  setShow(false);
                  setLoader(false);
                  setOtpFlag(false);
                  setUserQuestions(true);
                } else {
                  setNumber(response.data.number);
                  localStorage.setItem("customer_id", response.data.user_id);
                  localStorage.setItem(
                    "number",
                    JSON.stringify(response.data.number)
                  );
                  setShow(false);
                  setOtpFlag(false);
                  checkLoggedInStatus();
                  setLoader(false);
                  setHeroLoader(true);
                  if (
                    response.data.paymentStatus != "" &&
                    response.data.paymentStatus != null &&
                    response.data.paymentStatus != undefined
                  ) {
                    setPaymentPopup(false);
                    if (response.data.paymentStatus === "canceled") {
                      setPricingPlansPopup(true);
                    }
                  } else {
                    setPaymentPopup(true);
                  }
                }
              });
          } else {
            setLoader(false);
            toast.error(response.data.message);
          }
        } catch (error) {
          console.error("Error", error);
          setLoader(false);
          toast.error(error.message);
        }
      } catch (err) {
        setLoader(false);
        toast.error("Please Enter Valid OTP.");
      }
    }
  };

  const handlSetPhone = (e) => {
    setDisableButton(e?.length > 10 ? true : false);
    setNumber(e);
  };

  const handleChangeSignUpmethod = () => {
    setNumber("");
    setEmail("");
    setSignUpMethod(!signUpMethod);
    setDisableButton(false);
  };

  const handleCloseBtn = () => {
    setOtp("");
    setNumber("");
    setEmail("");
    setOtpFlag(false);
    handleClose();
    setDisableButton(false);
    localStorage.setItem("mdModel", "false");
  };

  const userSignin = () => {
    setLoader(true);
    if (logintype == 1 || logintype == 0) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(emailPhone)) {
        axios
          .post(
            `${process.env.REACT_APP_API_ENDPOINT}/user-signin-question`,
            {
              user_id: userCustomerId,
              logintype: 1,
              email: emailPhone,
              refer_id: sessionStorage.getItem("refer_customer_id"),
              accept_privacy_policy: checkPrivacyPolicy,
            },
            {
              headers: {
                "x-access-token": JSON.parse(localStorage.getItem("token")),
              },
            }
          )
          .then((response) => {
            if (response.data.status) {
              setLoader(false);
              setAllquestion(false);
              setHeroLoader(true);
              checkLoggedInStatus();
              if (
                response.data.paymentStatus != "" &&
                response.data.paymentStatus != null &&
                response.data.paymentStatus != undefined
              ) {
                setPaymentPopup(false);
                if (response.data.paymentStatus === "canceled") {
                  setPricingPlansPopup(true);
                }
              } else {
                setPaymentPopup(true);
              }
            } else {
              setLoader(false);
              toast.error(response.data.message);
            }
          })
          .catch((err) => {
            setLoader(false);

            toast.error(err.message);
          });
      } else {
        setLoader(false);
        toast.error("Please enter a valid email");
      }
    } else if (logintype == 2) {
      if (emailPhone.length > 10) {
        axios
          .post(
            `${process.env.REACT_APP_API_ENDPOINT}/user-signin-question`,
            {
              user_id:
                userCustomerId != "" &&
                userCustomerId != null &&
                userCustomerId != undefined
                  ? userCustomerId
                  : "",
              mobile_number: emailPhone,
              logintype: logintype,
              email: email,
              accept_privacy_policy: checkPrivacyPolicy,
              refer_id: sessionStorage.getItem("refer_customer_id"),
            },
            {
              headers: {
                "x-access-token": JSON.parse(localStorage.getItem("token")),
              },
            }
          )
          .then((response) => {
            if (response.data.status) {
              setLoader(false);
              setAllquestion(false);
              setNumber(emailPhone);
              setLoggedInId(response.data.data.token);
              if (response.data.data.token && response.data.data.token !== "") {
                localStorage.setItem(
                  "token",
                  JSON.stringify(response.data.data.token)
                );
              }
              localStorage.setItem("customer_id", response.data.data.user_id);
              localStorage.setItem("number", JSON.stringify(emailPhone));
              setHeroLoader(true);
              checkLoggedInStatus();
              if (
                response.data.data.paymentStatus != "" &&
                response.data.data.paymentStatus != null &&
                response.data.data.paymentStatus != undefined
              ) {
                setPaymentPopup(false);
                if (response.data.data.paymentStatus === "canceled") {
                  setPricingPlansPopup(true);
                }
              } else {
                setPaymentPopup(true);
              }
            } else {
              setLoader(false);
              toast.error(response.data.message);
            }
          })
          .catch((err) => {
            setLoader(false);
          });
      } else {
        setLoader(false);
        toast.error("Please enter valid number");
      }
    }
  };

  const handleSetEmail = (e) => {
    setEmail(e.target.value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(e.target.value)) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  };

  return (
    <>
      <div style={{ borderRadius: "20px" }}>
        <Modal
          className="swiper-modal"
          id="pricing-modal"
          show={show}
          onHide={handleCloseBtn}
          size={"md"}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          {paymentPopup === true || pricingPlansPopup === true ? (
            <div
              style={{
                backgroundColor: "#F4F5F9",
                borderRadius: "10px",
              }}
            >
              <PricingPlans
                mobile_number={number}
                setShow={setShow}
                setIsPaymentDone={setIsPaymentDone}
                paymentPopup={paymentPopup}
                setPaymentPopup={setPaymentPopup}
              />
            </div>
          ) : (
            <div>
              {loader ? (
                <div className="ring-loader">
                  <ClipLoader size={250} color={"#fff"} loading={loader} />
                </div>
              ) : (
                ""
              )}
              {flag ? (
                <Form onSubmit={getOtp}>
                  <Form.Group>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body className="show-grid">
                      <Container>
                        <div className="sign-form">
                          <Col lg={12} md={12} className="for-app">
                            <div className="swiper-heading"></div>
                            <div className="user-box">
                              <div className="tinder-baseline mb-4">
                                <h3>
                                  Ready to signup and{" "}
                                  <span
                                    style={{
                                      color: "rgb(99, 102, 241)",
                                    }}
                                  >
                                    ace
                                  </span>{" "}
                                  the course?
                                </h3>
                                <p>Let AnswersAi do all the work for you!</p>
                              </div>
                              {signUpMethod ? (
                                <PhoneInput
                                  id="number-input"
                                  international
                                  defaultCountry="US"
                                  value={number}
                                  onChange={(e) => handlSetPhone(e)}
                                  placeholder="Enter Your Phone Number"
                                  disabled={loader ? true : false}
                                />
                              ) : (
                                <input
                                  id="email-input"
                                  type="text"
                                  value={email}
                                  onChange={(e) => handleSetEmail(e)}
                                  placeholder="Email Address"
                                  className="form-control email_input"
                                />
                              )}
                            </div>
                            <div id="repactcha-main-div">
                              <div
                                id="recaptcha-container"
                                className="ml-2  mb-5 recaptcha-container"
                              ></div>
                            </div>
                            <Button
                              type="submit"
                              className={
                                !disableButton
                                  ? "payment-button disabled"
                                  : "payment-button"
                              }
                            >
                              Next
                            </Button>

                            <div className="extenstion-information mt-2 mb-3">
                              <p className="text-light">
                                Message and data rates may apply. By clicking
                                continue, you agree to the
                                <a
                                  style={{
                                    color: "#ffff",
                                  }}
                                  href={`${process.env.PUBLIC_URL}/terms-of-use`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  &nbsp; Terms of Service &nbsp;
                                </a>
                                and &nbsp;
                                <a
                                  style={{
                                    color: "#ffff",
                                  }}
                                  href={`${process.env.PUBLIC_URL}/privacy-policy`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  Privacy Policy
                                </a>
                              </p>
                            </div>
                            <div className="d-flex mb-3 mt-2">
                              <Col>
                                <div className="alternate-login-side-line"></div>
                                <div></div>
                              </Col>
                              <Col
                                className="d-flex justify-content-center ps-4 pe-4"
                                lg={4}
                              >
                                or
                              </Col>
                              <Col>
                                <div className="alternate-login-side-line"></div>
                                <div></div>
                              </Col>
                            </div>

                            <Button
                              id="sign-up-btn"
                              className="payment-button"
                              onClick={() => handleChangeSignUpmethod()}
                            >
                              {signUpMethod ? (
                                <span className="d-flex align-items-center text-dark">
                                  <MdEmail className="h4 mt-2 me-2" />
                                  Continue with Email
                                </span>
                              ) : (
                                <span className="text-dark d-flex align-items-center">
                                  <BsFillPhoneFill className="h4 mt-2 me-2" />
                                  Continue with Phone
                                </span>
                              )}
                            </Button>

                            <div className="d-flex mb-3 mt-3">
                              <Col>
                                <div className="alternate-login-side-line"></div>
                                <div></div>
                              </Col>
                              <Col
                                className="d-flex justify-content-center ps-4 pe-4"
                                lg={4}
                              >
                                or
                              </Col>
                              <Col>
                                <div className="alternate-login-side-line"></div>
                                <div></div>
                              </Col>
                            </div>
                            <div className="login-buttons align-items-center">
                              <Button
                                id="sign-up-btn"
                                onClick={signInWithGoogle}
                                type="button"
                                className="payment-button text-dark"
                              >
                                <FcGoogle className="h2 mb-0 me-2" /> Google
                              </Button>
                            </div>
                          </Col>
                        </div>
                      </Container>
                    </Modal.Body>
                  </Form.Group>
                </Form>
              ) : (
                ""
              )}
              {otpFlag ? (
                <Form
                  className="hidden-form"
                  onSubmit={verifyOtp}
                  style={{
                    borderRadius: "20px",
                  }}
                >
                  <Modal.Header closeButton></Modal.Header>
                  <OtpVerifyOtp
                    checkPrivacyPolicy={checkPrivacyPolicy}
                    setCheckPrivacyPolicy={setCheckPrivacyPolicy}
                    showPrivacyPolicy={showPrivacyPolicy}
                    editFunction={editFunction}
                    otp={otp}
                    setOtp={setOtp}
                    counter={counter}
                    hideResend={hideResend}
                    resendCode={resendCode}
                    signUpMethod={signUpMethod}
                    number={number}
                    email={email}
                  />
                </Form>
              ) : (
                ""
              )}
            </div>
          )}
        </Modal>
        {userQuestions ? (
          <UserAllQuestions
            setUserQuestions={setUserQuestions}
            signUpMethod={signUpMethod}
            emailData={emailData}
            userSignin={userSignin}
            setEmailPhone={setEmailPhone}
            emailPhone={emailPhone}
            setUserQuestion1={setUserQuestion1}
            userQuestion1={userQuestion1}
            setUserQuestion2={setUserQuestion2}
            userQuestion2={userQuestion2}
            allQuestion={allQuestion}
            loader={loader}
          />
        ) : (
          ""
        )}
        <PricingPlans
          handleCloseBtn={handleCloseBtn}
          mobile_number={number}
          showDisplayFeatureModel={showDisplayFeatureModel}
          setDisplayFeatureModel={setDisplayFeatureModel}
          setPricingPlansPopup={setPricingPlansPopup}
          paymentPopup={paymentPopup}
          setShow={setShow}
          setIsPaymentDone={setIsPaymentDone}
          setPaymentPopup={setPaymentPopup}
        />
      </div>
    </>
  );
}

export default LoginModal;
