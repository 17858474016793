import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../Footer";
import TopBar from "../TopBar";
import AfterLoginPage from "../AfterLoginPage";
import Hero from "./Hero";
import Credentials from './Credentials'
import Testimonials from "./Testimonials";
import FeatureCards from "./FeatureCards";
import CTA from './CallToAction'
import FAQ from './Faq';
import ClipLoader from "react-spinners/ClipLoader";
import FetchUserCountry from "../UserInformation/FetchUserCountry";
import ImageGradient from './assets/image/image-gradient-hero.png';

export default function Home({
  heroLoader,
  setHeroLoader,
  loggedInId,
  setLoggedInId,
  isPaymentDone,
  setIsPaymentDone,
  paymentPopup,
  setPaymentPopup,
  showSub,
  setShowSub,
  showDisplayFeatureModel,
  setDisplayFeatureModel,
  userQuestions,
  setUserQuestions,
  emailData,
  setEmailData,
  pricingPlansPopup,
  setPricingPlansPopup,
  flag,
  setFlag,
  logintype,
  setLoginType,
  show,
  setShow,
  handleClose,
  handleShowLogin,
}) {
  const Navigate = useNavigate();

  useEffect(() => {
    analyticalCall();
  }, []);

  useEffect(() => {
    if (heroLoader) {
      document.body.classList.add("body-loader");
    } else {
      document.body.classList.remove("body-loader");
    }
  }, [heroLoader]);

  const analyticalCall = () => {
    window.ttq.track("ViewContent");
  };

  return (
  <div className="w-screen"> 
    <img className="absolute top-0 w-screen -z-50" src={ImageGradient} alt="image-gradient-hero" />
    <div className="gap-y-20 z-20 bg-[#F8F8F8]">
      {heroLoader ? (
        <div className="ring-loader">
          <ClipLoader size={250} color={"#fff"} loading={heroLoader} />
        </div>
      ) : (
        ""
      )}
      <TopBar
        handleShowLogin={handleShowLogin}
        handleClose={handleClose}
        show={show}
        setShow={setShow}
        logintype={logintype}
        setLoginType={setLoginType}
        pricingPlansPopup={pricingPlansPopup}
        setPricingPlansPopup={setPricingPlansPopup}
        emailData={emailData}
        setEmailData={setEmailData}
        userQuestions={userQuestions}
        setUserQuestions={setUserQuestions}
        showDisplayFeatureModel={showDisplayFeatureModel}
        setDisplayFeatureModel={setDisplayFeatureModel}
        setHeroLoader={setHeroLoader}
        setFlag={setFlag}
        flag={flag}
        loggedInId={loggedInId}
        setLoggedInId={setLoggedInId}
        isPaymentDone={isPaymentDone}
        setIsPaymentDone={setIsPaymentDone}
        setPaymentPopup={setPaymentPopup}
        paymentPopup={paymentPopup}
        showSub={showSub}
        setShowSub={setShowSub}
      />
      {isPaymentDone ? (
        <AfterLoginPage />
      ) : (
        <>
          <FetchUserCountry />
          <Hero
            setHeroLoader={setHeroLoader}
            flag={flag}
            setFlag={setFlag}
            loggedInId={loggedInId}
            setLoggedInId={setLoggedInId}
            isPaymentDone={isPaymentDone}
            setIsPaymentDone={setIsPaymentDone}
            setPaymentPopup={setPaymentPopup}
            paymentPopup={paymentPopup}
            showSub={showSub}
            setShowSub={setShowSub}
          />
          <Credentials />
          <FeatureCards />
          <Testimonials />
          <CTA />
          <FAQ />
          <Footer />
          {/** 
          <MobileFeature />
          <FeatureCards />
          <AppStoreFeature />
          <Pricing />
          */}
        </>
      )}
      {/*<Footer />*/}
    </div>
    </div> 
  );
}
