import React from "react";
import uparrow from './assets/icon/icon-arrow-up.svg';
import underline from './assets/image/image-scribble-cta.svg';
import pcimg from '../../assets/images/product demos/desktop/ctadesktop.svg';
import mobileimg from '../../assets/images/product demos/desktop/ctamobile.svg';
import bggrad1 from './assets/image/image-gradient-cta-1.svg';
import bggrad2 from './assets/image/image-gradient-cta-2.svg';

function GetStarted(){
    const handleShow = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    return(
        <section className="col-span-full font-manrope pt-64 pb-24 relative tracking-tight bg-bg-light ">
            <img className="hidden lg:flex absolute top-24 left-0 z-10" src={bggrad2}></img>
            <img className="absolute top-24 right-0 z-10" src={bggrad1}></img>
            <div className="flex flex-col items-center gap-20 p-4 z-30">
               <div className="flex flex-col items-center gap-9">
                <div className="flex flex-col items-center gap-2">
                    <div className="w-fit flex py-1 px-[5px] items-center justify-center gap-[10px] rounded-[40px] border-[0.5px] border-purple bg-purple bg-opacity-5">
                        <img src={uparrow} width={20} height={20} alt="icon-chart"/>
                        <div className="font-manrope leading-[130%] tracking-[-0.32px] text-b2 pr-[5px] font-normal text-grey-90">
                            Used & trusted by 
                            <span className="text-purple font-bold">
                            &nbsp;600K Students
                            </span>
                        </div>
                    </div>
                    <div className="flex flex-col gap-12 p-4 items-center text-center">
                        <div className="flex flex-col font-product font-normal text-[40px] sm:text-6xl items-center">
                            <h1 className="z-30 tracking-tight">What are you waiting for? 
                                <span className="sm:hidden relative w-fit gap-2 z-30">
                                &nbsp;Lets get started!
                                    <img className="absolute top-14 left-0" src={underline}></img>
                                </span>
                            </h1>
                            <h1 className="hidden sm:flex flex-col w-fit items-center gap-2 z-30">
                                Lets get started!
                                <img className="max-w-56 sm:max-w-96" src={underline}></img>
                            </h1>
                        </div>
                        <p className="font-manrope font-normal leading-[26px] text-[20px] text-text-gray max-w-2xl z-30">
                            Join over 600,000 users who trust Answers.ai for instant answers. 
                            With 2.5 million+ queries resolved, your solution is just a tap away.</p>
                    </div>
                    </div>
                    <button className="hover:opacity-90 hover:scale-[0.98] transition-all bg-gradient-to-b from-[#564CDA] to-[#2E16CB] p-[2px] w-fit rounded-[12px] shadow-[0_4px_17px_0px_rgba(70, 58, 209, 0.3)] z-30" onClick={handleShow}>
                        <div className="p-2 px-8 rounded-[10px] border bg-gradient-to-b from-gradient-secondary-start to-gradient-secondary-end shadow-[inset_0_4px_5px_0px_rgba(255,255,255,0.4)]">
                            <p className="font-manrope text-white font-normal tracking-normal" style={{ textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'}}>
                                Start Asking 
                            </p>
                        </div>
                    </button>
                </div>
                <img className="md:hidden flex w-full max-w-md" src={mobileimg}></img>
                <img className="hidden max-xl:md:flex w-full max-w-4xl" src={pcimg}></img>
            </div>
            <img className="hidden xl:flex w-1/2 absolute top-56 -left-96 z-20" src={pcimg}></img>
            <img className="hidden xl:flex absolute top-24 right-12 z-20" src={mobileimg}></img>
            
        </section>
    );
}

export default GetStarted;