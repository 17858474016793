import React from "react";
import FeatureCard2 from "./FeatureCard2";

function FeatureSection2(){
    return(
        <div className="flex flex-col w-full p-8 md:p-16 pt-28 md:pt-28 gap-28 m-4 items-center bg-b2bfeaturebg bg-cover bg-bottom rounded-3xl font-jakarta">
            <h1 className="font-bold text-4xl lg:text-6xl max-w-3xl leading-tight text-center">Your own helper, anywhere with ClassMate</h1>
            <div className="grid lg:grid-cols-3 gap-4">
                <FeatureCard2  
                sno="01"
                title="24/7 Personalized Support"
                description="AI Helper provides personalized support anytime, effectively addressing both general syllabus queries and complex topic explanations."
                />
                <FeatureCard2  
                sno="02"
                title="Private, Safe & Secure."
                description="Your data is fully protected with advanced security measures, ensuring a safe and confidential environment for you and your students."
                />
                <FeatureCard2  
                sno="03"
                title="Fast, reliable and accurate"
                description="Experience swift and dependable AI tools delivering precise results, enabling seamless classroom management without delays."
                />
            </div>
        </div>
    )
}

export default FeatureSection2;