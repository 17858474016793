import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Editor, EditorState, convertFromRaw, RichUtils, getDefaultKeyBinding, } from "draft-js";
import "draft-js/dist/Draft.css";
import { mdToDraftjs } from "draftjs-md-converter";
import { Button } from "react-bootstrap";
import { RiAiGenerate } from "react-icons/ri";
function Notes({
  interimResult,
  notes,
  activeId,
  reGenerateSummFn,
  stopClick,
}) {
  const { id } = useParams();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const notesAPI = async () => {
    try {
      let data = "";
      id == "recording"
        ? notes.map((result) => (data += result.transcript))
        : (data = notes);

      const rawData = mdToDraftjs(data);
      const contentState = convertFromRaw(rawData);
      const newEditorState = EditorState.createWithContent(contentState);
      setEditorState(newEditorState);
    } catch (error) {}
  };

  useEffect(() => {
    if (id || activeId) {
      notesAPI();
    } else {
      setEditorState(
        EditorState.createWithContent(
          convertFromRaw(mdToDraftjs("Please enter media to get notes."))
        )
      );
    }
  }, [id, activeId, notes]);

  const handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return "handled";
    }
    return "not-handled";
  };

  const toggleInlineStyle = (style) => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, style));
  };

  const toggleBlockType = (blockType) => {
    setEditorState(RichUtils.toggleBlockType(editorState, blockType));
  };

  const renderToolbar = () => (
    <div className="flex sticky top-0 bg-white flex-row gap-2 toolbar mb-2 py-2 z-10">
      <Button onClick={() => toggleInlineStyle("BOLD")}>B</Button>
      <Button onClick={() => toggleInlineStyle("ITALIC")}><i>i</i></Button>
      <Button onClick={() => toggleInlineStyle("UNDERLINE")}><u>U</u></Button>
      <Button onClick={() => toggleBlockType("unordered-list-item")}>
        Points
      </Button>
      <Button
        className="btn regenerate_btn"
        style={{ width: "fit-content" }}
        onClick={() => reGenerateSummFn("notes")}
      >
        Regenerate
      </Button>
    </div>
  );

  return (
    <div className="!bg-slate-50 px-2 rounded relative h-full max-h-full overflow-y-auto prose">
      <div className="d-flex justify-content-end pt-2 pe-4 pb-3">
        {id != "recording" ? (
          <div className="w-[100%] max-w-[100%] d-flex justify-content-between">
            {notes != "" ? <div></div> : <div>Notes not generated</div>}
          </div>
        ) : (
          ""
        )}
      </div>
      {id === "recording" ? (
        <div>
          {stopClick ? (
            <div className="relative">
              {renderToolbar()}
              <Editor
                editorState={editorState}
                onChange={setEditorState}
                handleKeyCommand={handleKeyCommand}
              />
            </div>
          ) : (
            <>
              {notes?.map((result, i) => (
                <div key={i}>{result.transcript}</div>
              ))}

              {interimResult && <div>{interimResult}</div>}
            </>
          )}
        </div>
      ) : (
        <>
          {renderToolbar()}
          <Editor
            editorState={editorState}
            onChange={setEditorState}
            handleKeyCommand={handleKeyCommand}
          />
        </>
      )}
    </div>
  );
}

export default Notes;
