import ReactCardFlip from "react-card-flip";
import { useState } from "react";

function FlashCard({ question, answer }) {
  const [flip, setFlip] = useState(false);
  const handleClick = () => {
    setFlip(!flip);
  };
  return (
    <>
      <ReactCardFlip isFlipped={flip} flipDirection="horizontal">
        <div style={{ padding: "10px" }}>
          {question}
          <button className="mt-[20%] py-2 bg-[#6366f1] rounded-lg text-white font-lg" onClick={handleClick}>See Answer</button>
        </div>
        <div>
          {answer}
          <button className="mt-[20%] py-2 bg-[#6366f1] rounded-lg text-white font-lg" onClick={handleClick}>See Question</button>
        </div>
      </ReactCardFlip>
    </>
  );
}

export default FlashCard;