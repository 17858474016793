import React from "react";
import TestimonialCard from "./components/TestimonialCard";

function Testimonial(){

    return(
        <section className="flex flex-col items-center font-jakarta gap-12">
            <div className="flex flex-col items-center text-center px-8">
                <div className="flex flex-col text-center gap-8 p-4">
                    <p className="text-accent-800 text-md font-bold">Testimonials</p>
                    <h1 className="text-4xl lg:text-6xl leading-tight font-bold max-w-3xl">
                        Trusted by students and educators the world over.</h1>
                </div>
                <TestimonialCard/>
            </div>
            <div className="grid lg:grid-cols-3 justify-center gap-12 w-full p-20 font-inter">
                <div className="flex gap-6 lg:justify-center">
                    <div className="w-2 h-full bg-gradient-to-b from-accent-800 to-white"></div>
                    <div className="flex flex-col gap-2">
                        <h2 className="font-black text-5xl">300,000+</h2>
                        <p className="text-gray-600">onboarded students</p>
                    </div>
                </div>
                <div className="flex gap-6 lg:justify-center">
                    <div className="w-2 h-full bg-gradient-to-b from-accent-800 to-white"></div>
                    <div className="flex flex-col gap-2">
                        <h2 className="font-black text-5xl">1.2 million</h2>
                        <p className="text-gray-600">questions answered</p>
                    </div>
                </div>
                <div className="flex gap-6 lg:justify-center">
                    <div className="w-2 h-full bg-gradient-to-b from-accent-800 to-white"></div>
                    <div className="flex flex-col gap-2">
                        <h2 className="font-black text-5xl">91%</h2>
                        <p className="text-gray-600">subscriber engagement</p>
                    </div>
                </div>
            </div>
            

        </section>
    )
}

export default Testimonial;