import React, { useEffect, useRef, useState } from "react";
import TopBar from "../TopBar";
import { MdModeEditOutline } from "react-icons/md";
import Footer from "../Footer";
import ClipLoader from "react-spinners/ClipLoader";
import "react-circular-progressbar/dist/styles.css";
import { IoStopCircleOutline } from "react-icons/io5";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  MessageInput,
  TypingIndicator,
} from "@chatscope/chat-ui-kit-react";
import axios from "axios";
import toast from "react-hot-toast";

const ChatBot = ({
  setHeroLoader,
  loggedInId,
  setLoggedInId,
  isPaymentDone,
  setIsPaymentDone,
  paymentPopup,
  setPaymentPopup,
  showSub,
  setShowSub,
  showDisplayFeatureModel,
  setDisplayFeatureModel,
  userQuestions,
  setUserQuestions,
  emailData,
  setEmailData,
  pricingPlansPopup,
  setPricingPlansPopup,
  flag,
  setFlag,
  logintype,
  setLoginType,
  show,
  setShow,
  handleClose,
  handleShowLogin,
}) => {
  const [pdfFileName, setPdfFileName] = useState([]);
  const [messages, setMessages] = useState([]);
  const [typing, setTyping] = useState(false);
  const historyLoader = useRef();
  const [checkboxActive, setCheckboxActive] = useState(true);
  const [apiCall, setApiCall] = useState(false);
  const [messageInputVal, setMessageInputVal] = useState("");
  const [chatStatus, setChatStatus] = useState("New");
  const [chatId, setChatId] = useState("");
  const [selectActive, setSelectActive] = useState(null);
  const [resMessageQues, setResMessageQues] = useState("");
  const [resMessageAns, setResMessageAns] = useState("");

  const shouldContinueRef = useRef(true);
  const timeoutIdRef = useRef(null);

  const stopRes = async () => {
    axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT}/web-chat-abort`,
        {},
        {
          headers: {
            "x-access-token": JSON.parse(localStorage.getItem("token")),
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          setTyping(false);
          setCheckboxActive(true);
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      });
  };

  const fileName = async () => {
    setApiCall(false);
    setPdfFileName([]);
    try {
      const response = await axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}/chat-list`,
          {},
          {
            headers: {
              "x-access-token": JSON.parse(localStorage.getItem("token")),
            },
          }
        )
        .then((value) => {
          setPdfFileName(value.data.data);
          setApiCall(true);
        });
    } catch (err) {}
  };

  useEffect(() => {
    fileName();
  }, []);

  useEffect(() => {
    if (chatStatus == "Old") {
      webChatFn(resMessageQues);
      setChatStatus("");
    }
  }, [chatStatus]);

  const addMessage = (text) => {
    setResMessageQues("");
    setResMessageAns("");
    setMessages((prevMessages) => [...prevMessages, text]);
  };

  const webChatFn = async (message) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/web-chat`,
      {
        method: "POST",
        headers: {
          "x-access-token": JSON.parse(localStorage.getItem("token")),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: message,
          chat_type: "Old",
          chatId: chatId,
        }),
      }
    );

    if (response.ok) {
      setTyping(false);
      let df = "";
      const reader = response.body
        .pipeThrough(new TextDecoderStream())
        .getReader();
      while (true) {
        const { value, done } = await reader.read();
        if (done) break;
        df += value;
        setResMessageAns((prev) => prev + value);
      }

      const date = new Date();
      const chatTime = date.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
      });

      const newMessage = {
        message: message,
        sender: "user",
        direction: "outgoing",
        sentTime: chatTime,
      };
      const incomingmessages1 = {
        message: df,
        sender: "chatAi",
        direction: "incoming",
        sentTime: chatTime,
      };
      addMessage(newMessage);
      addMessage(incomingmessages1);
      setCheckboxActive(true);
    }
  };

  const handleSendMessage = async (message) => {
    if (resMessageAns !== "") {
      return;
    }
    setResMessageQues(message);
    shouldContinueRef.current = true;

    setMessageInputVal("");
    setCheckboxActive(false);
    setTyping(true);

    try {
      if (chatId) {
        webChatFn(message);
      } else {
        const response = await axios.post(
          `${process.env.REACT_APP_API_ENDPOINT}/make-new-chat`,
          {},
          {
            headers: {
              "x-access-token": JSON.parse(localStorage.getItem("token")),
              "Content-Type": "application/json",
            },
          }
        );
        if (response.data.status) {
          setChatId(response.data.chat_id);
          setChatStatus("Old");
        }
      }
    } catch (err) {
      setTyping(false);
    }
  };

  const writeInput = (str) => {
    setMessageInputVal(str.replace(/(<([^>]+)>)/gi, ""));
  };

  const newChatFn = () => {
    shouldContinueRef.current = false;
    setTyping(false);
    setCheckboxActive(true);
    clearInterval(timeoutIdRef.current);
    setMessageInputVal("");
    setMessages("");
    setChatId("");
    setResMessageQues("");
    setResMessageAns("");
    setSelectActive("");
    fileName();
  };

  const selectChatFn = async (id, index) => {
    if (typing) {
      return;
    }
    shouldContinueRef.current = false;
    setTyping(false);
    setCheckboxActive(true);
    clearInterval(timeoutIdRef.current);
    setMessageInputVal("");
    setTyping(false);
    setResMessageQues("");
    setResMessageAns("");
    setSelectActive(index);
    try {
      const response = await axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}/web-chat-history`,
          {
            chatId: id,
          },
          {
            headers: {
              "x-access-token": JSON.parse(localStorage.getItem("token")),
            },
          }
        )
        .then((value) => {
          const arrSetup = [];
          const oldChatHistory = value.data.data;
          oldChatHistory.map((item, index) => {
            const date = new Date(item.time * 1000);
            const chatTime = date.toLocaleTimeString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
            });

            const newMessage = {
              message: item.question,
              sender: "user",
              direction: "outgoing",
              sentTime: chatTime,
            };
            arrSetup.push(newMessage);
            const incomingmessages = {
              message: item.answer,
              sender: "chatAi",
              direction: "incoming",
              sentTime: chatTime,
            };
            arrSetup.push(incomingmessages);
          });
          setChatId(id);
          setMessages(arrSetup);
        });
    } catch (err) {}
  };
  return (
    <div>
      <TopBar
        handleShowLogin={handleShowLogin}
        handleClose={handleClose}
        show={show}
        setShow={setShow}
        logintype={logintype}
        setLoginType={setLoginType}
        pricingPlansPopup={pricingPlansPopup}
        setPricingPlansPopup={setPricingPlansPopup}
        emailData={emailData}
        setEmailData={setEmailData}
        userQuestions={userQuestions}
        setUserQuestions={setUserQuestions}
        showDisplayFeatureModel={showDisplayFeatureModel}
        setDisplayFeatureModel={setDisplayFeatureModel}
        setHeroLoader={setHeroLoader}
        setFlag={setFlag}
        flag={flag}
        loggedInId={loggedInId}
        setLoggedInId={setLoggedInId}
        isPaymentDone={isPaymentDone}
        setIsPaymentDone={setIsPaymentDone}
        setPaymentPopup={setPaymentPopup}
        paymentPopup={paymentPopup}
        showSub={showSub}
        setShowSub={setShowSub}
      />

      <div className="container pb-4" style={{ paddingTop: "2em" }}>
        <div className="talkbook_heading____">
          <div className="heading">
            <h1 className="mb-4">Talk to ChatBot </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 row-2">
            <div className="books_feed__">
              <div className="book-feed-content ">
                <div className="book-feed-heading d-flex justify-content-between pb-4">
                  <span className="pt-1">Chat History</span>
                  {!typing ? (
                    <button onClick={newChatFn} className="new_history_btn bg-[#6366f1] text-white py-2 rounded-lg">
                      New Chat
                    </button>
                  ) : (
                    <button disabled className="new_history_btn bg-[#6366f1] text-white py-2 rounded-lg">
                      New Chat
                    </button>
                  )}
                </div>
                <div
                  className={`justify-content-center pt-4 book-feed-books ${
                    pdfFileName && pdfFileName.length > 0
                      ? ""
                      : "book-not-available-div"
                  }`}
                >
                  {apiCall ? (
                    pdfFileName && pdfFileName.length > 0 ? (
                      pdfFileName.map((items, index) => {
                        return (
                          <div key={index + "_bookchat"}>
                            <div
                              className={`book2 mt-2 ${
                                index === selectActive
                                  ? "active_his_title"
                                  : "show_border"
                              }`}
                              onClick={() => selectChatFn(items.chat_id, index)}
                            >
                              <div>
                                <div
                                  className={`chatbot-content-heading p-2 `}
                                  style={{ cursor: "pointer" }}
                                >
                                  <div>
                                    {" "}
                                    {items.title
                                      .replace(/&nbsp;/g, "")
                                      .slice(0, 30) +
                                      (items.title.length > 30 ? "..." : "")}
                                  </div>
                                  <div>
                                    <MdModeEditOutline />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="books-not-available"></div>
                    )
                  ) : (
                    <div className="text-center mt-5">
                      <ClipLoader size={50} color={"#000"} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8 row-1 position-relative">
            <div
              className="talk-to-book-loader"
              ref={historyLoader}
              style={{ display: "none", placeContent: "center" }}
            >
              <ClipLoader size={100} color={"#fff"} />
            </div>

            <div className="chat_feed___ ">
              <MainContainer>
                <ChatContainer>
                  <MessageList
                    typingIndicator={
                      typing ? <TypingIndicator content="Tutor" /> : null
                    }
                  >
                    <div className="chat_response">
                      Welcome to our AI Chat Bot
                    </div>
                    {messages.length > 0
                      ? messages.map((message, i) => (
                          <div
                            key={i}
                            className={`${
                              message.direction === "outgoing"
                                ? "chat_question-div"
                                : "chat_response-div"
                            }`}
                          >
                            <div
                              className={`${
                                message.direction === "outgoing"
                                  ? "chat_question"
                                  : "chat_response"
                              }`}
                            >
                              {message.message != undefined
                                ? message?.message.replace(/&nbsp;/g, "")
                                : ""}
                            </div>
                            <div className="pt-1 chat-time">
                              {message.sentTime}
                            </div>
                          </div>
                        ))
                      : ""}

                    {resMessageQues != "" && resMessageQues != undefined ? (
                      <div className="chat_question-div">
                        <div className="chat_question">
                          {resMessageQues != "" && resMessageQues != undefined
                            ? resMessageQues
                            : ""}
                        </div>
                        <div className="pt-1 chat-time">Just Now</div>
                      </div>
                    ) : (
                      ""
                    )}
                    {resMessageAns != "" && resMessageAns != undefined ? (
                      <div className="chat_response-div" key={new Date()}>
                        <div className="chat_response">{resMessageAns}</div>
                        <div className="pt-1 chat-time">Just Now</div>
                      </div>
                    ) : (
                      ""
                    )}
                  </MessageList>
                  {checkboxActive ? (
                    <MessageInput
                      value={messageInputVal}
                      onChange={(e) => writeInput(e)}
                      placeholder="Type here..."
                      onSend={handleSendMessage}
                      attachButton={false}
                      style={{wordBreak:"break-word"}}
                    />
                  ) : (
                    <MessageInput
                      value=""
                      disabled
                      placeholder="Please Wait for Response"
                      attachButton={false}
                    />
                  )}
                </ChatContainer>
              </MainContainer>
            </div>
            {!checkboxActive && !typing && (
              <button className="handle_save_btn" onClick={stopRes}>
                <IoStopCircleOutline />
              </button>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ChatBot;
