import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import Modal from "react-bootstrap/Modal";
import { Editor, EditorState, convertFromRaw } from "draft-js";
import { mdToDraftjs } from "draftjs-md-converter";

const EssayHistory = ({
  titlesStatus,
  setSelectActive,
  selectActive,
}) => {
  const [pdfFileName, setPdfFileName] = useState([]);
  const [lgShow, setLgShow] = useState(false);
  const [typing, setTyping] = useState(false);
  const [apiCall, setApiCall] = useState(false);
  const [essayHistory, setEssayHistory] = useState("");
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const shouldContinueRef = useRef(true);
  const timeoutIdRef = useRef(null);

  useEffect(() => {
    if (essayHistory) {
      const rawData = mdToDraftjs(essayHistory);
      const contentState = convertFromRaw(rawData);
      const newEditorState = EditorState.createWithContent(contentState);
      setEditorState(newEditorState);
    }
  }, [essayHistory]);

  const getEssayTitle = async () => {
    setApiCall(false);
    setPdfFileName([]);
    try {
      const response = await axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}/web-essay-titles`,
          {},
          {
            headers: {
              "x-access-token": JSON.parse(localStorage.getItem("token")),
            },
          }
        )
        .then((value) => {
          setPdfFileName(value.data.data);

          setApiCall(true);
        });
    } catch (err) {}
  };

  useEffect(() => {
    getEssayTitle();
  }, [titlesStatus]);

  const selectChatFn = async (id, index) => {
    if (typing) {
      return;
    }
    shouldContinueRef.current = false;
    setTyping(false);

    clearInterval(timeoutIdRef.current);

    setTyping(false);
    setSelectActive(index);
    try {
      const response = await axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}/web-essay-history`,
          {
            essay_id: id,
          },
          {
            headers: {
              "x-access-token": JSON.parse(localStorage.getItem("token")),
            },
          }
        )
        .then((value) => {
          setLgShow(true);
          setEssayHistory(value.data.data.answer);
        });
    } catch (err) {}
  };
  return (
    <div>
      <div className="mt-5">
        <div className="books_feed__">
          <div className="book-feed-content ">
            <div className="book-feed-heading d-flex justify-content-between pb-4">
              <span className="pt-1">Essay History</span>
            </div>
            <div
              className={`justify-content-center pt-4 book-feed-books ${
                pdfFileName && pdfFileName.length > 0
                  ? ""
                  : "book-not-available-div"
              }`}
            >
              {apiCall ? (
                pdfFileName && pdfFileName.length > 0 ? (
                  pdfFileName.map((items, index) => {
                    return (
                      <div key={index + "_bookchat"}>
                        <div
                          className={`book2 mt-2 ${
                            index === selectActive
                              ? "active_his_title"
                              : "show_border"
                          }`}
                          onClick={() => selectChatFn(items.id, index)}
                        >
                          <div>
                            <div
                              className={`chatbot-content-heading p-2 `}
                              style={{ cursor: "pointer" }}
                            >
                              <div>
                                {" "}
                                {items.question
                                  .replace(/&nbsp;/g, "")
                                  .slice(0, 30) +
                                  (items.question.length > 30 ? "..." : "")}
                              </div>
                              <div>{/* <MdModeEditOutline /> */}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="books-not-available"></div>
                )
              ) : (
                <div className="text-center mt-5">
                  <ClipLoader size={50} color={"#000"} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        centered
      >
        <Modal.Header closeButton className="p-0">
          <Modal.Title id="example-modal-sizes-title-lg">
            {pdfFileName.map(
              (items, index) =>
                index === selectActive && <h1>{items.question}</h1>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            maxHeight: "550px",
            overflowY: "auto",

            backgroundColor: "white",
            color: "black",
            borderBottomRightRadius: "10px",
            borderBottomLeftRadius: "10px",
          }}
        >
          <Editor editorState={editorState} onChange={setEditorState} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EssayHistory;