import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { TbBookUpload } from "react-icons/tb";
import TopBar from "../TopBar";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import RangeSlider from "react-bootstrap-range-slider";
import Footer from "../Footer";
import toast from "react-hot-toast";
import EssayHistory from "./EssayHistory";
import editable from "../images/editable.png";

import {
  Editor,
  EditorState,
  convertFromRaw,
  ContentState,
  convertToRaw,
} from "draft-js";
import "draft-js/dist/Draft.css";
import { mdToDraftjs, draftjsToMd } from "draftjs-md-converter";

function EssayBoard({
  setHeroLoader,
  loggedInId,
  setLoggedInId,
  isPaymentDone,
  setIsPaymentDone,
  paymentPopup,
  setPaymentPopup,
  showSub,
  setShowSub,
  showDisplayFeatureModel,
  setDisplayFeatureModel,
  userQuestions,
  setUserQuestions,
  emailData,
  setEmailData,
  pricingPlansPopup,
  setPricingPlansPopup,
  flag,
  setFlag,
  logintype,
  setLoginType,
  show,
  setShow,
  handleClose,
  handleShowLogin,
}) {
  const [question, setQuestion] = useState("");
  const [btnCall, setBtnCall] = useState(false);
  const [essayStatus, setEssayStatus] = useState(false);
  const [loader, setLoader] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [essayData, setEssayData] = useState("");
  const [lengthAnswerVal, setLengthAnswerVal] = useState(1000);
  const [selectFormat, setSelectFormat] = useState("standard");
  const [pdfFileName, setPdfFileName] = useState("");
  const [pdfFile, setPdfFile] = useState("");
  const [error, setError] = useState(false);
  const essayDiv = useRef("");
  const [resStatus, setResStatus] = useState(false);
  const [titlesStatus, setTitlesStatus] = useState(false);
  const [selectActive, setSelectActive] = useState(null);
  const [likeDislike, setLikeDislike] = useState('');

  const findAnswer = async (e) => {
    if (question !== "") {
      try {
        const formData = new FormData();
        formData.append("file", pdfFile);
        formData.append("question", question);
        formData.append("length_val", lengthAnswerVal);
        formData.append("format_val", selectFormat);

        const response = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/web-essay-board`,
          {
            method: "POST",
            headers: {
              "x-access-token": JSON.parse(localStorage.getItem("token")),
            },
            body: formData,
          }
        );

        if (response.ok) {
          setEssayStatus(true);
          const reader = response.body
            .pipeThrough(new TextDecoderStream())
            .getReader();
          let markdownContent = "";
          while (true) {
            const { value, done } = await reader.read();
            if (done) break;

            markdownContent += value;
            setEssayData((prev) => prev + value);

            const rawData = mdToDraftjs(markdownContent);
            const contentState = convertFromRaw(rawData);
            const newEditorState = EditorState.createWithContent(contentState);
            setEditorState(newEditorState);
          }
          setResStatus(false);
          setBtnCall(false);
          setLoader(false);
          setTitlesStatus(!titlesStatus);

        }
      } catch (error) {
        toast.error(error.message);
      }
    } else {
      setLoader(false);
      setError(true);
      setBtnCall(false);
      setResStatus(false);
    }
  };

  const lengthAnswer = async (data) => {
    setLengthAnswerVal(data);
  };

  useEffect(() => {
    if (btnCall) {
      setEssayStatus(false);
      setLoader(true);
      setEditorState(EditorState.createEmpty());
      setEssayData("");
      findAnswer();
    }
  }, [btnCall]);

  const handlePdfChange = async (e) => {
    setPdfFileName(e.target.files[0].name);
    setPdfFile(e.target.files[0]);
  };

  const handleTextArea = (e) => {
    setQuestion(e.target.value);
    if (e.target.value.length > 0) {
      setError(false);
    }
  };
  const handleHumanizeClick = (e) => {
    setEssayStatus(false);
    setLoader(true);
    humanizeEssay(essayData);
  };
  const stop = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT}/web-essay-board-abort`,
        {},
        {
          headers: {
            "x-access-token": JSON.parse(localStorage.getItem("token")),
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      });
  };

  const copytoclipboard = () => {
    const currentContent = editorState.getCurrentContent();
    const rawContent = draftjsToMd(convertToRaw(currentContent));
    navigator.clipboard.writeText(rawContent);
    toast.success("Copied to clipboard!");
  };
  const humanizeEssay = async (essayText) => {
    try {
      const response = await fetch('https://api.answersai.com/humanize', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "x-access-token": JSON.parse(localStorage.getItem("token")),
        },
        body: JSON.stringify({
          oldText: essayText,  // Make sure the key here matches the server-side expectation
        }),
      });

      if (!response.ok) {
        throw new Error(`${response.status} - ${response.statusText}`);
      }

      const data = await response.json();

      if (data.success) {
        setEssayStatus(true);
        const humanizedText = data.data.humanizedText;

        // Convert the humanized text to Draft.js content state
        const contentState = ContentState.createFromText(humanizedText);
        const newEditorState = EditorState.createWithContent(contentState);

        // Update the editor state with the humanized text
        setEditorState(newEditorState);

        // Optional: Update the raw essay data if needed
        setEssayData(humanizedText);

        console.log('Humanized Text:', humanizedText);
        setLoader(false);
        console.error('Error in humanization:', data.message);
      }
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  return (
    <>
      <div>
        <TopBar
          handleShowLogin={handleShowLogin}
          handleClose={handleClose}
          show={show}
          setShow={setShow}
          logintype={logintype}
          setLoginType={setLoginType}
          pricingPlansPopup={pricingPlansPopup}
          setPricingPlansPopup={setPricingPlansPopup}
          emailData={emailData}
          setEmailData={setEmailData}
          userQuestions={userQuestions}
          setUserQuestions={setUserQuestions}
          showDisplayFeatureModel={showDisplayFeatureModel}
          setDisplayFeatureModel={setDisplayFeatureModel}
          setHeroLoader={setHeroLoader}
          setFlag={setFlag}
          flag={flag}
          loggedInId={loggedInId}
          setLoggedInId={setLoggedInId}
          isPaymentDone={isPaymentDone}
          setIsPaymentDone={setIsPaymentDone}
          setPaymentPopup={setPaymentPopup}
          paymentPopup={paymentPopup}
          showSub={showSub}
          setShowSub={setShowSub}
        />
        <Container>
          <Row className="pb-5">
            <Col lg={3}>
              <EssayHistory
                setEssayData={setEssayData}
                essayData={essayData}
                setEssayStatus={setEssayStatus}
                titlesStatus={titlesStatus}
                setSelectActive={setSelectActive}
                selectActive={selectActive}
              />
            </Col>
            <Col lg={9} className="essay-container-col border_essay_container my-5">
              <div className="essay-container">
                <div className="essay-tool-txt">Essay Board</div>
                <div className="">
                  <Row className="pb-5">
                    <Col lg={10} className="essay-board-length">
                      <div>
                        <label
                          htmlFor="formControlRange"
                          style={{
                            fontSize: "23px",
                            fontWeight: "500",
                          }}
                        >
                          Length
                        </label>

                        <RangeSlider
                          className="form-range"
                          id="customRange1"
                          min={100}
                          max={3000}
                          step={50}
                          value={lengthAnswerVal}
                          tooltipLabel={(lengthAnswerVal) =>
                            `${lengthAnswerVal} Words`
                          }
                          onChange={(event) => lengthAnswer(event.target.value)}
                          tooltip="on"
                        />
                      </div>
                    </Col>

                    <Col lg={2} className="">
                      <div>
                        <label
                          htmlFor="formControlRange"
                          style={{
                            fontSize: "23px",
                            fontWeight: "500",
                          }}
                        >
                          Format
                        </label>
                        <select
                          name=""
                          id=""
                          className="select-col"
                          onChange={(e) => setSelectFormat(e.target.value)}
                        >
                          <option value="standard">Standard</option>
                          <option value="happy">Happy</option>
                        </select>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="row mb-4">
                  <span className="add_prompt">Add Prompt</span>
                  <div className="mt-2 textArea_main col-lg-8 col-md-8 col-sm-8">
                    <textarea
                      className="essay-question-txt w-100"
                      placeholder="Enter here"
                      id="essay_question"
                      onChange={(e) => handleTextArea(e)}
                    ></textarea>
                    {error ? (
                      <div className="esaay_Error__ text-danger">
                        This Field is required
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="mt-1 mb-1 essay_pdf_main col-lg-4 col-md-4 col-sm-4">
                    <input
                      accept="application/pdf"
                      type="file"
                      onChange={(e) => handlePdfChange(e)}
                      id="Myfiles"
                      className="hidden"
                    />
                    <label className="Myfiles_label" htmlFor="Myfiles">
                      <div>
                        <div>
                          <TbBookUpload />
                        </div>
                        <div className="upload_pdf">Upload PDF's</div>
                      </div>
                    </label>
                    <div className="text-center">{pdfFileName}</div>
                  </div>
                </div>

                <div className="flex gap-2 justify-center md:justify-between flex-wrap">
                  {!resStatus ? (
                      <button
                        type="button"
                        onClick={(e) => [setBtnCall(true), setResStatus(true)]}
                        className="essay-question-btn"
                      >
                        Generate
                      </button>
                  ) : (
                    <button
                      type="button"
                      onClick={() => stop()}
                      className="essay-question-btn"
                    >
                      Stop
                    </button>
                  )}

                  {essayStatus ? (
                    <div className="flex flex-wrap justify-center md:justify-end gap-2">
                      <button 
                      className={`${likeDislike === 'dislike' ? 'text-red-500 transition-all' : ''} essay-question-btn`} 
                      onClick={() => setLikeDislike('dislike')}
                      >
                        <svg className='px-[4px] w-full' width="21" height="20" viewBox="0 0 21 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path d="M14.6667 10.8333L11.3334 18.3333C10.6703 18.3333 10.0344 18.07 9.56559 17.6011C9.09675 17.1323 8.83336 16.4964 8.83336 15.8333V12.5H4.11669C3.8751 12.5028 3.63581 12.4529 3.41538 12.354C3.19496 12.2551 2.99868 12.1094 2.84014 11.9271C2.68161 11.7448 2.5646 11.5302 2.49724 11.2981C2.42988 11.0661 2.41377 10.8222 2.45003 10.5833L3.60003 3.08335C3.66029 2.68593 3.86216 2.32368 4.16844 2.06334C4.47471 1.80301 4.86475 1.66214 5.26669 1.66668H14.6667M14.6667 10.8333V1.66668M14.6667 10.8333H16.8917C17.3633 10.8417 17.8216 10.6766 18.1795 10.3693C18.5375 10.0621 18.7701 9.63413 18.8334 9.16668V3.33335C18.7701 2.8659 18.5375 2.43793 18.1795 2.1307C17.8216 1.82346 17.3633 1.65834 16.8917 1.66668H14.6667" stroke="white" stroke-opacity="0.8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </button>
                      <button 
                      className={`${likeDislike === 'like' ? 'text-green-500 transition-all' : ''} essay-question-btn`} 
                      onClick={() => setLikeDislike('like')}
                      >
                        <svg className='px-[4px] w-full' width="19" height="20" viewBox="0 0 19 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path d="M5.33329 9.16663L8.66663 1.66663C9.32967 1.66663 9.96555 1.93002 10.4344 2.39886C10.9032 2.8677 11.1666 3.50358 11.1666 4.16663V7.49996H15.8833C16.1249 7.49722 16.3642 7.54705 16.5846 7.64597C16.805 7.7449 17.0013 7.89057 17.1598 8.07289C17.3184 8.2552 17.4354 8.46981 17.5027 8.70184C17.5701 8.93386 17.5862 9.17776 17.55 9.41663L16.4 16.9166C16.3397 17.314 16.1378 17.6763 15.8315 17.9366C15.5253 18.197 15.1352 18.3378 14.7333 18.3333H5.33329M5.33329 9.16663V18.3333M5.33329 9.16663H2.83329C2.39127 9.16663 1.96734 9.34222 1.65478 9.65478C1.34222 9.96734 1.16663 10.3913 1.16663 10.8333V16.6666C1.16663 17.1087 1.34222 17.5326 1.65478 17.8451C1.96734 18.1577 2.39127 18.3333 2.83329 18.3333H5.33329" stroke="white" stroke-opacity="0.8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </button>
                      <button onClick={copytoclipboard} className="essay-question-btn">
                        Copy Essay
                      </button>
                      <button
                        type="button"
                        className="essay-question-btn"
                        onClick={handleHumanizeClick}
                      >
                        Humanize
                      </button>
                    </div>

                  ) : (
                    <div className="flex flex-wrap justify-center md:justify-end gap-2">
                      {loader ? (
                        <div className="loaderClip" >
                          <ClipLoader size={42} color={"#000"} />
                        </div>
                        ) : (""
                      )}
                      <button className= "essay-question-btn-off" >
                        <svg className='px-[4px] w-full' width="21" height="20" viewBox="0 0 21 20" fill="white" xmlns="http://www.w3.org/2000/svg">
                          <path d="M14.6667 10.8333L11.3334 18.3333C10.6703 18.3333 10.0344 18.07 9.56559 17.6011C9.09675 17.1323 8.83336 16.4964 8.83336 15.8333V12.5H4.11669C3.8751 12.5028 3.63581 12.4529 3.41538 12.354C3.19496 12.2551 2.99868 12.1094 2.84014 11.9271C2.68161 11.7448 2.5646 11.5302 2.49724 11.2981C2.42988 11.0661 2.41377 10.8222 2.45003 10.5833L3.60003 3.08335C3.66029 2.68593 3.86216 2.32368 4.16844 2.06334C4.47471 1.80301 4.86475 1.66214 5.26669 1.66668H14.6667M14.6667 10.8333V1.66668M14.6667 10.8333H16.8917C17.3633 10.8417 17.8216 10.6766 18.1795 10.3693C18.5375 10.0621 18.7701 9.63413 18.8334 9.16668V3.33335C18.7701 2.8659 18.5375 2.43793 18.1795 2.1307C17.8216 1.82346 17.3633 1.65834 16.8917 1.66668H14.6667" stroke="white" stroke-opacity="0.8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </button>
                      <button className="essay-question-btn-off">
                        <svg className='px-[4px] w-full' width="19" height="20" viewBox="0 0 19 20" fill="white" xmlns="http://www.w3.org/2000/svg">
                          <path d="M5.33329 9.16663L8.66663 1.66663C9.32967 1.66663 9.96555 1.93002 10.4344 2.39886C10.9032 2.8677 11.1666 3.50358 11.1666 4.16663V7.49996H15.8833C16.1249 7.49722 16.3642 7.54705 16.5846 7.64597C16.805 7.7449 17.0013 7.89057 17.1598 8.07289C17.3184 8.2552 17.4354 8.46981 17.5027 8.70184C17.5701 8.93386 17.5862 9.17776 17.55 9.41663L16.4 16.9166C16.3397 17.314 16.1378 17.6763 15.8315 17.9366C15.5253 18.197 15.1352 18.3378 14.7333 18.3333H5.33329M5.33329 9.16663V18.3333M5.33329 9.16663H2.83329C2.39127 9.16663 1.96734 9.34222 1.65478 9.65478C1.34222 9.96734 1.16663 10.3913 1.16663 10.8333V16.6666C1.16663 17.1087 1.34222 17.5326 1.65478 17.8451C1.96734 18.1577 2.39127 18.3333 2.83329 18.3333H5.33329" stroke="white" stroke-opacity="0.8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </button>
                      <button className="essay-question-btn-off">
                        Copy Essay
                      </button>
                      <button
                        type="button"
                        className="essay-question-btn-off"
                      >
                        Humanize
                      </button>
                      
                    </div>
                  )}

                  
                </div>
                  <div className="py-8  w-100">
                    <div className="w-100" ref={essayDiv}>
                      {essayStatus ? (
                        <>
                          <div className="flex flex-col">

                            <Editor
                              editorState={editorState}
                              onChange={setEditorState}
                              placeholder="Type your essay here..."
                            />
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    </>
  );
}

export default EssayBoard;