import React, { useState, useMemo, useRef, useEffect } from "react";
import {
  useElements,
  useStripe,
  CardCvcElement,
  CardNumberElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import axios from "axios";
import { Form } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import mastercard from "../images/mastercard-logo.png";
import amex from "../images/amex-logo.png";
import visa from "../images/visa-logo.png";
import diners_club from "../images/diners_club.png";
import discover from "../images/discover-logo.png";
import jcb from "../images/jcb_logo.png";
import union_pay from "../images/union_pay.png";
import ClipLoader from "react-spinners/ClipLoader";
import toast from "react-hot-toast";
import GoogleApplePay from "../GoogleApplePay";
import daimond from "../images/daimond.png";
import Pricing from "../Home/Pricing";

export default function PaymentForm(props) {
  const DARK_CARD_OPTIONS = {
    iconStyle: "solid",
    style: {
      base: {
        iconColor: "#6D28D9",
        color: "#fff",
        "::placeholder": {
          color: "grey",
        },
        ":-webkit-autofill": {
          color: "white",
        },
      },
    },
  };

  const paymentLoader = useRef();
  const [imgSrc, setImgSrc] = useState();
  const [billingDetails, setBillingDetails] = useState({
    name: "",
  });
  const [nameError, setNameError] = useState(false);
  const [carNumError, setCardNumError] = useState(false);
  const [expDateError, setExpDateError] = useState(false);
  const [cvcError, setCvcError] = useState(false);
  const [returnUrl, setReturnUrl] = useState("");
  const [intentId, setIntentId] = useState("");
  const [stripeCoupon, setStripeCoupon] = useState("");
  const [showPromoCodeText, setShowPromoCodeText] = useState(false);
  const [showTailSpin, setShowTailSpin] = useState(false);
  const [showstripeCouponErr, setShowStripeCouponErr] = useState(false);
  const [stripeCouponErr, setStripeCouponErr] = useState("");
  const [stripePromoTag, setStripePromoTag] = useState(false);
  const [stripePromoTagName, setStripePromoTagName] = useState("");
  const [stripeCouponID, setStripeCouponID] = useState("");
  const [exSubcriptionType, setExSubcriptionType] = useState("");
  const [addressError, setAddressError] = useState(false);
  const [billingAddress, setBillingAddress] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [isPromoCodeValid, setIsPromoCodeValid] = useState(false);
  const [amountOff, setAmountOff] = useState(null);

  const imageChange = (e) => {
    if (e.empty === false) {
      setCardNumError(false);
    }
    if (e.brand === "visa") {
      setImgSrc(visa);
    } else if (e.brand === "mastercard") {
      setImgSrc(mastercard);
    } else if (e.brand === "amex") {
      setImgSrc(amex);
    } else if (e.brand === "diners") {
      setImgSrc(diners_club);
    } else if (e.brand === "discover") {
      setImgSrc(discover);
    } else if (e.brand === "jcb") {
      setImgSrc(jcb);
    } else if (e.brand === "unionpay") {
      setImgSrc(union_pay);
    }
  };

  const Field = ({
    label,
    id,
    name,
    type,
    placeholder,
    required,
    autoComplete,
    value,
    onChange,
  }) => (
    <div className="FormRow">
      <label htmlFor={id} className="FormRowLabel">
        {label}
      </label>{" "}
      <br />
      <input
        className="FormRowInput"
        id={id}
        type={type}
        placeholder={placeholder}
        required={required}
        autoComplete={autoComplete}
        value={value}
        onChange={onChange}
        name={name}
      />
    </div>
  );
  const useOptions = () => {
    const options = useMemo(() => ({
      style: {
        base: {
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          fontSize: "20px",
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }));

    return options;
  };
  const CARD_OPTIONS = {
    iconStyle: "solid",
    style: {
      base: {
        color: "#fff",
        fontWeight: 500,
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
      },
      invalid: {
        iconColor: "#ffc7ee",
        color: "#ffc7ee",
      },
    },
  };

  const [secondTimeCouponUseError, setSecondTimeCouponUseError] =
    useState(false);
  const [secondTimeCouponUseText, setSecondTimeCouponUseText] = useState("");
  const stripe = useStripe();
  const elements = useElements();

  const pay = async (e) => {
    e.preventDefault();
    const dat = stripe
      .confirmCardPayment(
        intentId,
        {
          payment_method: {
            card: elements.getElement(CardNumberElement),
          },
          return_url: returnUrl,
        },
        // Disable the default next action handling.
        { handleActions: false }
      )
      .then(function (result) {
        if (result.paymentIntent.status === "requires_action") {
          window.open(
            result.paymentIntent.next_action.redirect_to_url.url,
            "_blank",
            "popup=yes"
          );
        }
      })
      .catch(function (err) { });
  };

  useEffect(() => {
    if (props.success) {
      document.body.classList.add("body-loader");
    } else {
      document.body.classList.remove("body-loader");
    }
  }, [props.success]);

  function on3DSComplete() {
    // Hide the 3DS UI

    // Check the PaymentIntent
    stripe.retrievePaymentIntent(intentId).then(function (result) {
      if (result.error) {
        // PaymentIntent client secret was invalid
      } else {
        if (result.paymentIntent.status === "succeeded") {
          // Show your customer that the payment has succeeded
        } else if (result.paymentIntent.status === "requires_payment_method") {
          // Authentication failed, prompt the customer to enter another payment method
        }
      }
    });
  }

  window.addEventListener(
    "message",
    function (ev) {
      if (ev.data === "3DS-authentication-complete") {
        on3DSComplete();
      }
    },
    false
  );
  var RunCallbackFunction = function () { }; //reference holder only
  function myFunc() {
    alert("I'm a function in the parent window");
  }

  const returnBack = () => {
    props.setExtPayment(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const mobile_number = props.mobile_number
      ? props.mobile_number
      : JSON.parse(localStorage.getItem("number"));

    if (billingDetails.name === "") {
      setNameError(true);
    }
    if (billingAddress == "") {
      setAddressError(true);
    }

    paymentLoader.current.style.display = "flex";

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement),
      billing_details: billingDetails,
    });
    if (error) {
      if (error.code === "incomplete_number") {
        setCardNumError(true);
      }
      if (error.code === "incomplete_expiry") {
        setExpDateError(true);
      }
      if (error.code === "incomplete_cvc") {
        setCvcError(true);
      }
    }

    if (nameError || carNumError || expDateError || cvcError) {
      paymentLoader.current.style.display = "none";
      return;
    }
    if (!error) {
      const { id } = paymentMethod;
      completePaymentProcess(id);
    } else {
      paymentLoader.current.style.display = "none";
      toast.error(error.message);
    }
  };

  const completePaymentProcess = async (paymentMethodId) => {
    try {
      const mobile_number = props.mobile_number
        ? props.mobile_number
        : JSON.parse(localStorage.getItem("number"));
      const urlAddCard = `${process.env.REACT_APP_API_ENDPOINT}/make-payment`;

      const response = await axios.post(
        urlAddCard,
        {
          name: billingDetails.name,
          id: paymentMethodId,
          mobile_number: mobile_number,
          coupon: stripeCouponID ? stripeCouponID : undefined,
          couponCode: stripeCoupon.trim() !== "" ? stripeCoupon : undefined,
          subscription_plan: exSubcriptionType,
          address_line1: billingAddress,
          address_line2: addressLine2,
          country: country,
          city: city,
          zipCode: zipCode,
          countryCode: localStorage.getItem("countryCode"),
        },

        {
          headers: {
            "x-access-token": JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      if (response.data.status === true) {
        if (response.data.payment_status == "succeeded") {
          paymentLoader.current.style.display = "none";
          props.setSuccess(true);
          if (response.data.msg === "Subscription Added") {
            localStorage.setItem("payment", true);
            props.setIsPaymentDone(true);
          }
          setTimeout(() => {
            document.location.reload();
          }, 3000);
        } else if (response.data.payment_status === "requires_action") {
          const result = await stripe.confirmCardPayment(
            response.data.client_secret,
            {
              payment_method: paymentMethodId,
              return_url: response.data.return_url,
            }
          );
          if (result.error) {
            paymentLoader.current.style.display = "none";
            toast.error(result.error.message);
          } else {
            if (result.paymentIntent.status === "succeeded") {
              const apiResponse = await axios.get(
                `${response.data.return_url}&payment_intent=${result.paymentIntent.id}`
              );
              paymentLoader.current.style.display = "none";
              if (apiResponse.data.status === true) {
                props.setSuccess(true);
                localStorage.setItem("payment", true);
                props.setIsPaymentDone(true);
                setTimeout(() => {
                  document.location.reload();
                }, 3000);
              } else {
                toast.error(result.data.msg);
              }
            }
          }
        }
      } else if (response.data.subscribed === true) {
        props.setIsPaymentDone(true);
        toast.success(response.data.msg);
        paymentLoader.current.style.display = "none";
        props.setPaymentPopup(false);
      } else {
        setSecondTimeCouponUseText(response.data.msg);
        setSecondTimeCouponUseError(true);
        paymentLoader.current.style.display = "none";
        toast.error(response.data.msg);
      }
    } catch (error) {
      paymentLoader.current.style.display = "none";
    }
  };

  const analticCall = () => {
    window.ttq.track("CompletePayment", { value: 9.99, currency: "USD" });
    window.fbq("track", "CompletePayment", {
      value: 9.99,
      currency: "USD",
    });

    axios
      .get(
        "https://forbusiness.snapchat.com/blog/the-snap-pixel-how-it-works-and-how-to-install-it#:~:text=%2D%20We%20have%20standard%20pixel%20events,event%20you%20want%20to%20track"
      )
      .then(function (response) { });
  };

  const handlePromoCodeChange = (e) => {
    setStripeCoupon(e.target.value);
    setIsPromoCodeValid(false);
    setShowStripeCouponErr(false);
  };
  const handleValidatePromoCode = () => {
    if (stripeCoupon.trim() !== '') {
      checkStripeCoupon(stripeCoupon);
    }
  };

  const checkStripeCoupon = async () => {
    setShowTailSpin(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/valid-promo-code`,
        {
          promo_code: stripeCoupon,
          stripeCustomerID: localStorage.getItem("stripeCustomerId"),
        },

        {
          headers: {
            "x-access-token": JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      if (response.data.status) {
        setStripePromoTagName(response.data.couponName);
        setStripePromoTag(true);
        setShowTailSpin(false);
        setStripeCouponID(response.data.data.data[0].id);
        setIsPromoCodeValid(true);
        setAmountOff(response.data.data.data[0].coupon.amount_off / 100);
      } else {
        setShowStripeCouponErr(true);
        setStripeCouponErr(response.data.msg);
        setShowTailSpin(false);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  const handleRemoveTag = (e) => {
    setStripeCoupon("");
    setStripeCouponID("");
    setStripePromoTag(false);
    setShowPromoCodeText(false);
    setStripeCoupon("");
    setIsPromoCodeValid(false);
    setAmountOff(null);
  };

  return (
    <div>
      {props.extPayment ? (
        <div>
          <div
            className="ring-loader"
            ref={paymentLoader}
            style={{ display: "none", placeContent: "center", height: "100vh" }}
          >
            <ClipLoader size={250} color={"#fff"} />
          </div>

          <Row id="paymentModal" className="payment-modal">
            <Row style={{ display: "contents" }}></Row>
            <div
              className="back_return text-light"
              style={{ cursor: "pointer" }}
              onClick={returnBack}
            >
              {" "}
              &#8592; back
            </div>
            <Form onSubmit={handleSubmit} className="">
              <Col lg={12} md={12} className="Card">
                <div className="google-apple-pay-btn mt-2 mb-2">
                  <GoogleApplePay
                    completePaymentProcess={completePaymentProcess}
                  />
                </div>
              </Col>
              <Col lg={12} md={12}>
                <div className="answerAi_subscription__ p-3 mt-4">
                  <div className="d-flex align-items-center">
                    <img width={50} height={50} src={daimond} alt="daimond" />
                    <div
                      className="ps-2"
                      style={{ color: "#fff", fontSize: "15px" }}
                    >
                      AnswersAi Subscription{" "}
                      {exSubcriptionType === "YEARLY" ? (
                        <span
                          style={{
                            color: "#fff",
                            fontSize: "15px",
                            fontWeight: "lighter !important",
                          }}
                        >
                          Yearly 
                          {localStorage.getItem("countryCode") == "AU"
                            ? " 5.83*12 = A$69.99"
                            : " 3.33*12 = $39.99"}
                        </span>
                      ) : (
                        ""
                      )}
                      <div
                        className="mt-1"
                        style={{
                          color: "rgba(255, 255, 255, 0.60)",
                          fontSize: "12px",
                        }}
                      >
                        {exSubcriptionType === "MONTHLY"
                          ? localStorage.getItem("countryCode") === "AU"
                            ? "A$8.99 charged now, recurs every month"
                            : "$9.99 charged now, recurs every month"
                          : localStorage.getItem("countryCode") === "AU"
                            ? "A$ 69.99 charged now, recurs every year"
                            : "$39.99 charged now, recurs every year"}
                        . Cancel anytime.{" "}
                      </div>
                    </div>
                  </div>
                </div>

                <fieldset className="FormGroup mt-4">
                  <div className="FormRow mb-3">
                    <Form.Group className="" controlId="formBasicEmail">
                      <Form.Label>Name</Form.Label>
                      <input
                        type="text"
                        className="mt-2"
                        placeholder="Type here"
                        id="name"
                        name="name"
                        value={billingDetails.name}
                        onChange={(e) => {
                          setBillingDetails({
                            ...billingDetails,
                            name: e.target.value,
                          });
                          if (e.target.value !== "") {
                            setNameError(false);
                          }
                        }}
                      />
                    </Form.Group>
                    {nameError === true ? (
                      <p className="payment-form-error">Name is required</p>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="FormRow">
                    <div className="mb-3">
                      <label>
                        Card number
                        <CardNumberElement
                          className="text-light mycard"
                          name="card-number"
                          options={DARK_CARD_OPTIONS}
                          onChange={(event) => {
                            imageChange(event);
                          }}
                        />
                        {carNumError === true ? (
                          <p className="payment-form-error">
                            Card number is empty or incomplete
                          </p>
                        ) : (
                          ""
                        )}
                      </label>
                    </div>
                    <div className="d-flex">
                      <label style={{ width: "100%" }} className="mb-3">
                        Expiration date
                        <CardExpiryElement
                          className="expiry-date"
                          name="expiry-date"
                          options={DARK_CARD_OPTIONS}
                          onChange={(event) => {
                            if (event.empty === false) {
                              setExpDateError(false);
                            }
                          }}
                        />
                        {expDateError === true ? (
                          <p className="payment-form-error">
                            Expiration Date is empty or incorrect
                          </p>
                        ) : (
                          ""
                        )}
                      </label>
                      <label style={{ width: "100%" }}>
                        CVC
                        <CardCvcElement
                          className=" cvc-number"
                          placeholder="Type here"
                          name="cvc-number"
                          options={DARK_CARD_OPTIONS}
                          onChange={(event) => {
                            if (event.empty === false) {
                              setCvcError(false);
                            }
                          }}
                        />
                        {cvcError === true ? (
                          <p className="payment-form-error">CVC is required</p>
                        ) : (
                          ""
                        )}
                      </label>
                    </div>
                    <div
                      className="text-light mb-5 mt-1"
                      style={{ width: "100%" }}
                    >
                      <input
                        placeholder="Address line 1"
                        onChange={(e) => setBillingAddress(e.target.value)}
                        type="text"
                      />
                      {addressError === true ? (
                        <p className="payment-form-error">
                          Address is required
                        </p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div
                      className="text-light mb-5 mt-1"
                      style={{ width: "100%" }}
                    >
                      <input
                        placeholder="Address line 2"
                        onChange={(e) => setAddressLine2(e.target.value)}
                        type="text"
                      />
                    </div>

                    <div className="d-md-flex">
                      <div
                        className="text-light mb-5 mt-1 mx-1"
                        style={{ width: "100%" }}
                      >
                        <input
                          placeholder="country"
                          onChange={(e) => setCountry(e.target.value)}
                          type="text"
                        />
                      </div>
                      <div
                        className="text-light mb-5 mt-1 mx-1"
                        style={{ width: "100%" }}
                      >
                        <input
                          placeholder="city"
                          onChange={(e) => setCity(e.target.value)}
                          type="text"
                        />
                      </div>
                      <div
                        className="text-light mb-5 mt-1 mx-1"
                        style={{ width: "100%" }}
                      >
                        <input
                          placeholder="zip code"
                          onChange={(e) => setZipCode(e.target.value)}
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="FormRow promo-code-section">
                    <Form.Group controlId="promoCode" className="d-flex align-items-center w-100">
                      <Form.Control
                        type="text"
                        placeholder="Enter Promo Code"
                        value={stripeCoupon}
                        onChange={handlePromoCodeChange}
                        isValid={isPromoCodeValid}
                        isInvalid={showstripeCouponErr}
                        className="text-light flex-grow-1"
                      />
                      <button
                        variant="outline-primary"
                        onClick={handleValidatePromoCode}
                        disabled={stripeCoupon.trim() === '' || showTailSpin}
                        className={`validate-btn ms-2 ${stripeCoupon.trim() === '' || showTailSpin ? 'disabled-btn' : ''}`}
                      >
                        {showTailSpin ? <ClipLoader size={20} color={"#6366f1"} /> : "Apply"}
                      </button>
                    </Form.Group>
                    {showstripeCouponErr && (
                      <Form.Control.Feedback type="invalid" className="d-block mt-1">
                        {stripeCouponErr}
                      </Form.Control.Feedback>
                    )}
                    {amountOff && (
                      <div className="text-green-500 font-bold">
                        Coupon applied successfully. ${amountOff} off your purchase.
                      </div>
                    )}
                  </div>
                  {stripePromoTag && (
                    <div className="promo-tag mt-2">
                      <span>{stripePromoTagName}</span>
                      <button onClick={handleRemoveTag} className="remove-tag">×</button>
                    </div>
                  )}

                  <div
                    className="text-center"
                    style={{
                      color: "#000",
                      marginTop: "10px",
                      marginBottom: "6px",
                      fontSize: "17px",
                    }}
                  >
                    Powered By <b>Stripe</b>
                  </div>
                </fieldset>
              </Col>
              {billingAddress.length > 0 &&
                country.length > 0 &&
                city.length > 0 &&
                (stripeCoupon.trim() === '' || isPromoCodeValid) &&
                zipCode.length > 0 ? (
                <button
                  type="submit"
                  id="custom-button"
                  className="pay-btn mb-4"
                  onClick={() => {
                    const element = document.getElementsByTagName("body");
                    element[0].scrollIntoView({ behavior: "smooth" });
                    analticCall();
                  }}
                >
                  Next
                </button>
              ) : (
                <button className="pay-btn disabled-pay-btn  mb-4" disabled>
                  Next
                </button>
              )}{" "}
            </Form>
          </Row>
        </div>
      ) : !props.success ? (
        <Pricing
          setExSubcriptionType={setExSubcriptionType}
          extPayment={props.extPayment}
          setExtPayment={props.setExtPayment}
        />
      ) : (
        <div>
          <div className="p-4 text-center">
            <div>
              <div className="wrapper">
                <svg
                  className="checkmark"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 52 52"
                  exSubcriptionType
                >
                  <circle
                    className="checkmark__circle"
                    cx="26"
                    cy="26"
                    r="25"
                    fill="none"
                  />{" "}
                  <path
                    className="checkmark__check"
                    fill="none"
                    d="M14.1 27.2l7.1 7.2 16.7-16.8"
                  />
                </svg>
              </div>
            </div>
            <div className="success-text">Payment Successful</div>
          </div>
        </div>
      )}
    </div>
  );
}
