import React from "react";
import FeatureCard1 from "./FeatureCard1";
import img1 from "../../../assets/images/featurescroll1.png";
import img2 from "../../../assets/images/featurescroll2.png";
import img3 from "../../../assets/images/featurescroll3.png";
import img4 from "../../../assets/images/featurescroll4.png";

function FeatureScroll({toggleSlideout}){
    return(
        <section className="grid relative gap-36 w-fit font-jakarta p-8 md:p-32 py-0 md:pb-0">
                <div className="flex flex-col lg:flex-row gap-16 py-32 max-md:pb-0 pt-0">
                    <div className="flex flex-col lg:h-screen lg:sticky top-40 max-w-lg mt-8 gap-8">
                        <div className="flex flex-col gap-4">
                            <h1 className="text-4xl lg:text-5xl font-bold leading-tight">
                                Put your time into what's important.
                            </h1>
                            <p>Our suite of AI powered tools help you effortlessly create new class material, conduct and grade tests, track student progress and more, letting you focus on teaching.</p>
                        </div>
                        <button onClick={toggleSlideout} className="p-6 py-3 bg-black hover:bg-accent-800 text-white rounded-full w-fit drop-shadow-[0_0_24px_rgba(0,0,0,0.10)] transition-all">
                            Learn More
                        </button>
                    </div>
                    <div className="flex flex-col gap-y-16 mb-64">
                        <FeatureCard1
                        title="Track Student Progress"
                        description="Monitor real-time student performance with insightful analytics, ensuring you stay informed about their learning journey."
                        image={img1}
                        />
                        <FeatureCard1
                        title="Assignment Grading"
                        description="Automatically grade assignments with AI precision, saving hours of manual work while providing detailed feedback."
                        image={img2}
                        />
                        <FeatureCard1
                        title="Course Material Generation"
                        description="Generate customized worksheets and quizzes instantly, tailored to your lesson objectives and student needs."
                        image={img3}
                        />
                        <FeatureCard1
                        title="Deploy to Students"
                        description="Easily distribute materials to students, providing them with instant access to assignments, quizzes, and resources."
                        image={img4}
                        />  
                    </div>
                </div>
        </section>
    )
}

export default FeatureScroll;