import React, { useEffect, useRef, useState } from "react";
import { FaBars } from 'react-icons/fa';
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import LoginModal from "../LoginModal";
import Payment from "../Payment";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import logo from "../images/answerai_icon.svg";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import NavDropdown from "react-bootstrap/NavDropdown";
import Modal from "react-bootstrap/Modal";
import { handleApiError } from "../../utils/apiErrorHandler";

const notify = () => toast.success("Logged out successfully.");

function TopBar({
  setHeroLoader,
  flag,
  setFlag,
  loggedInId,
  setLoggedInId,
  isPaymentDone,
  setIsPaymentDone,
  paymentPopup,
  setPaymentPopup,
  showSub,
  setShowSub,
  showDisplayFeatureModel,
  setDisplayFeatureModel,
  userQuestions,
  setUserQuestions,
  emailData,
  setEmailData,
  pricingPlansPopup,
  setPricingPlansPopup,
  logintype,
  setLoginType,
  show,
  setShow,
  handleClose,
  handleShowLogin,
}) {
  const [subscriptionLoader, setSubsciptionLoader] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [paymentMode, setPaymentMode] = useState("");
  const [showInappModal, setShowInappModal] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState("");
  const [subscriptionValidTil, setSubscriptionValidTil] = useState(false);
  const [menuToggle, setmenuToggle] = useState(false);
  const handleCloseInAppModal = () => setShowInappModal(false);
  const wrapperRef = useRef(null);
  const location = useLocation();

  const topbarStyle =
    location.pathname === "/" ? { background: "#F8F8F8" } : location.pathname === "/question-bank" ? { background: "black" } : { background: "white" };
  const textStyle =
    location.pathname === '/question-bank' ? { color: "white" } : { color: "#000" };
  const tradeStyle =
    location.pathname === '/question-bank' ? { color: "white" } : { color: "#000" };
  const buttonStyle =
    location.pathname === '/question-bank' ? { color: "white", borderColor: "white" } : { color: "#392BFF", borderColor: "#392BFF" };

  const handlemenuclick = () => {
    setmenuToggle(!menuToggle);
  };

  const toggleWrapper = () => {
    setIsOpen((prevState) => !prevState);
    if (!isOpen) {
      wrapperRef.current.style.height = `${wrapperRef.current.scrollHeight}px`;
    } else {
      wrapperRef.current.style.height = "0px";
    }
  };

  const navigate = useNavigate();
  const { id } = useParams();

  const redirectOnHomepage = () => {
    window.location.href = "/";
  };

  const logout = async () => {
    if (loggedInId && loggedInId !== "") {
      try {
        const response = await axios
          .post(`${process.env.REACT_APP_API_ENDPOINT}/logout`, {
            user_id: JSON.parse(localStorage.getItem("token")),
          })
          .then((response) => {
            setLoggedInId("payment");
            localStorage.setItem("token", "");
            localStorage.removeItem("customer_id");
            sessionStorage.removeItem("refer_customer_id");
            localStorage.removeItem("payment");
            localStorage.removeItem("number");
            localStorage.removeItem("key");
            setIsPaymentDone(false);
            navigate("/");
            if (response.data.status === true) {
              localStorage.setItem("token", "");
              setIsPaymentDone(false);
              setLoggedInId("");
              notify();
              delete axios.defaults.headers.common["x-access-token"];
              window.location.reload();
            }
          });
      } catch (err) {
        toast.error(err.message);
      }
    }
  };

  useEffect(() => {
    if (
      localStorage.getItem("logintype") != "" &&
      localStorage.getItem("logintype") != null &&
      localStorage.getItem("logintype") != undefined
    ) {
      setLoginType(localStorage.getItem("logintype"));
    }
    if (
      localStorage.getItem("token") != "" &&
      localStorage.getItem("token") != null &&
      localStorage.getItem("token") != undefined
    ) {
      checkUserQuestions();
    }
  }, []);

  useEffect(() => {
    document.querySelectorAll(".nav-link").forEach((link) => {
      if (link.href === window.location.href) {
        link.classList.add("nav-active");
      } else {
        link.classList.remove("nav-active");
      }
    });
  });

  const todayDate = new Date();
  const checkLoggedInStatus = async () => {
    try {
      const response = await axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}/check-payment-status`,
          {
            customer_id: localStorage.getItem("customer_id")
              ? localStorage.getItem("customer_id").toString()
              : "",
          },
          {
            headers: {
              "x-access-token": JSON.parse(localStorage.getItem("token")),
            },
          }
        )
        .then((value) => {
          window.ttq.track("InitiateCheckout", {
            value: 9.99,
            currency: "USD",
          });
          var dateObj = new Date(value.data.current_period_end * 1000);
          var date =
            dateObj.getDate() +
            "/" +
            (dateObj.getMonth() + 1) +
            "/" +
            dateObj.getFullYear();
          setSubscriptionValidTil(date);
          if (value.data.subscription == "active") {
            setSubscriptionStatus(value.data.subscription);
            setHeroLoader(false);
            setIsPaymentDone(true);
            setShowSub(true);
            localStorage.setItem("payment", true);
            setPaymentMode(value.data.payment_mode);
          } else if (value.data.subscription == "canceled") {
            if (value.data.current_period_end) {
              setSubscriptionStatus(value.data.subscription);
              if (dateObj > todayDate) {
                setHeroLoader(false);
                localStorage.setItem("payment", true);
                setIsPaymentDone(true);
                setShowSub(true);
                setPaymentMode(value.data.payment_mode);
              } else {
                localStorage.removeItem("payment");
              }
            }
          } else if (value.data.subscription == false) {
            setHeroLoader(false);
            localStorage.removeItem("payment");
            setIsPaymentDone(false);
            setDisplayFeatureModel(true);
            setPaymentPopup(true);
          }
        });
    } catch (err) {
      if (err.request) {
        if (err.request.status == 401) {
          setHeroLoader(false);
          setLoggedInId("");
          handleApiError(err);
        }
      }
    }

    if (localStorage.getItem("customer_id") === null) {
      localStorage.removeItem("token");
    }
  };

  const checkUserQuestions = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT}/check-user-questions`,
        {
          user_id: localStorage.getItem("customer_id"),
          logintype: logintype,
        },
        {
          headers: {
            "x-access-token": JSON.parse(localStorage.getItem("token")),
          },
        }
      )
      .then((response) => {
        if (response.data.userQuestion) {
          setEmailData(response.data.data);
          setUserQuestions(true);
        } else {
          localStorage.setItem("number", JSON.stringify(response.data.number));
          checkLoggedInStatus();
        }
      });
  };

  const showPaymentModalHandler = () => {
    checkUserQuestions();
  };

  const stripeManageSubscription = async (e) => {
    if (paymentMode == "in-app") {
      setShowInappModal(true);
    } else {
      setSubsciptionLoader(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_ENDPOINT}/create-customer-stripe-portal-session`,
          {
            customer_id: localStorage.getItem("customer_id").toString(),
          },
          {
            headers: {
              "x-access-token": JSON.parse(localStorage.getItem("token")),
            },
          }
        );

        if (response.data.status) {
          window.location.assign(response.data.billing_url);
          setSubsciptionLoader(false);
        } else {
          setSubsciptionLoader(false);
          toast.error(response.data.message);
        }
      } catch (error) {
        setSubsciptionLoader(false);
      }
    }
  };

  return (
    <>
      <Toaster
        toastOptions={{
          className: "",
          style: {
            border: "1px solid #713200",
            padding: "16px",
            color: "#713200",
          },
        }}
      />
      <Navbar
        collapseOnSelect
        expand="xxl"
        className="font-manrope bg-body-tertiary z-20 max-md:!pb-2"
        style={topbarStyle}
      >
        <Container className="bg-body-tertiary z-20" style={{ display: "block" }}>
          <div className="nav-container z-20">
            <div onClick={() => redirectOnHomepage()}>
              <div className="answer-ai-logo d-flex">
                <img
                  src={logo}
                  alt="AnswerAi Logo"
                  className="answer-ai-icon z-20"
                />
                <h1
                  className="logo-name d-flex align-items-center z-20"
                  style={textStyle}
                >
                  Answers
                  <span className="text-indigo-500">
                    Ai
                    <sup className=" font-normal" style={tradeStyle}>
                      &trade;
                    </sup>
                  </span>
                </h1>
              </div>
            </div>
            {loggedInId ? (
              <div>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              </div>
            ) : (
              <div
                className="relative d-flex align-items-center gap-12 flex items-center z-20"
                id={`offcanvasNavbar-expand-${"lg"}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${"lg"}`}
                placement="end"
                style={textStyle}
              >
                <Link
                  to="/question-bank"
                  className="nav-link mr-0 p-0 max-md:hidden"
                  id="questionBank"
                >
                  <div style={textStyle}>Question Bank</div>
                </Link>
                <div className="relative max-md:hidden inline-block text-left">
                  <div
                    onClick={handlemenuclick}
                    className="inline-flex justify-center w-full"
                  >
                    <div style={textStyle}>More</div>
                    <svg
                      className={`w-5 h-5 ml-2 -mr-1 ${menuToggle ? "transform scale-y-[-1]" : ''}`}
                      style={textStyle}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                  </div>

                  {menuToggle && (
                    <div className="absolute right-0 w-56 m-2 origin-top-right bg-white border-2 border-gray-200 rounded-md ">
                      <div className="space-y-2 p-4">
                        <Link to="/question-bank" className="dropdown-item">
                          Question Bank
                        </Link>
                        <Link to="/institutions" className="dropdown-item">
                          For Institutions
                        </Link>
                        <Link to="/faq" className="dropdown-item">
                          FAQ
                        </Link>
                        <Link to="/" className="dropdown-item">
                          Home
                        </Link>
                        <Link to="/about" className="dropdown-item">
                          About Us
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
                <Payment />

                <div className="flex gap-4 items-center">
                  <button style={buttonStyle} onClick={() => [handleShowLogin(), setmenuToggle(false)]} id={id}
                    className="flex w-fit font-manrope px-[20px] py-[10px] rounded-[12px] text-[16px] font-medium 
                  leading-[22.5px]  border-1 whitespace-nowrap
                  bg-[#6268FF]/3 hover:border-opacity-50 hover:text-opacity-50 transition-all z-30">
                    Get Started
                  </button>
                  <FaBars style={textStyle}
                    className="w-8 h-6 md:hidden"
                    onClick={handlemenuclick}>
                  </FaBars>
                </div>
              </div>
            )}
          </div>
          {loggedInId ? (
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto"></Nav>
              <Nav className="nav-items">
                {isPaymentDone ? (
                  <>
                    <Link
                      to={`/lecture/${id != undefined ? id : ""}`}
                      className="nav-link"
                      id="leacture"
                    >
                      <span style={textStyle}>Lecture</span>
                    </Link>
                    <Link
                      to="/essay-board"
                      className="nav-link"
                      id="essayBoard"
                    >
                      <span style={textStyle}>Essay</span>
                    </Link>
                    <Link
                      to="/talk-to-book"
                      className="nav-link"
                      id="talkToBook"
                    >
                      <span style={textStyle}>Talk to PDF</span>
                    </Link>
                    <Link to="/chat-bot" className="nav-link" id="talkToBook">
                      <span style={textStyle}>Chatbot</span>
                    </Link>
                    <Link
                      to="/question-bank"
                      className="nav-link"
                      id="talkToBook"
                    >
                      <span style={textStyle}>Question Bank</span>
                    </Link>
                  </>
                ) : (
                  ""
                )}
                <Payment />
                {loggedInId === "" || !loggedInId ? (
                  <button
                    id="nav_btn"
                    variant=""
                    onClick={() => [handleShowLogin(), setmenuToggle(false)]}
                  >
                    Get Started
                  </button>
                ) : (
                  <div className="d-flex justify-content-end login-btn-group">
                    {isPaymentDone ? (
                      ""
                    ) : (
                      <>
                        <div
                          className="relative d-flex align-items-center gap-12 flex items-center z-20 mr-10"
                          id={`offcanvasNavbar-expand-${"lg"}`}
                          aria-labelledby={`offcanvasNavbarLabel-expand-${"lg"}`}
                          placement="end"
                          style={textStyle}
                        >
                          <Link
                            to="/question-bank"
                            className="nav-link mr-0 p-0 max-md:hidden"
                            id="questionBank"
                          >
                            <div style={textStyle}>Question Bank</div>
                          </Link>
                          <div className="relative max-md:hidden inline-block text-left">
                            <div
                              onClick={handlemenuclick}
                              className="inline-flex justify-center w-full"
                            >
                              <div style={textStyle}>More</div>
                              <svg
                                className={`w-5 h-5 ml-2 -mr-1 ${menuToggle ? "transform scale-y-[-1]" : ''}`}
                                style={textStyle}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                              </svg>
                            </div>

                            {menuToggle && (
                              <div className="absolute right-0 w-56 m-2 origin-top-right bg-white border-2 border-gray-200 rounded-md ">
                                <div className="space-y-2 p-4">
                                  <Link to="/question-bank" className="dropdown-item">
                                    Question Bank
                                  </Link>
                                  <Link to="/institutions" className="dropdown-item">
                                    For Institutions
                                  </Link>
                                  <Link to="/faq" className="dropdown-item">
                                    FAQ
                                  </Link>
                                  <Link to="/" className="dropdown-item">
                                    Home
                                  </Link>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div>
                          <button id="button" style={textStyle} onClick={toggleWrapper}>
                            Subscribe now!
                          </button>
                          <div
                            id="wrapper"
                            className={isOpen ? "open mt-2" : "notOpen"}
                            style={{
                              height: isOpen ? "auto" : 0,
                              padding: isOpen ? "10px" : 0,
                              borderRadius: "10px",
                            }}
                            ref={wrapperRef}
                          >
                            <ul id="list">
                              <li style={{ cursor: "pointer" }}>
                                <div
                                  className="mb-2 cancel-btn text-dark"
                                  id=""
                                  onClick={() => showPaymentModalHandler()}
                                >
                                  Subscribe
                                </div>
                              </li>
                              <li style={{ cursor: "pointer" }}>
                                <div
                                  className="text-dark"
                                  id=""
                                  variant=""
                                  onClick={() => logout()}
                                >
                                  Logout
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </>
                    )}
                    {showSub ? (
                      <>
                        <div className="relative ">
                          <button id="button" style={textStyle} onClick={toggleWrapper}>
                            Manage Subscription
                          </button>
                          <div
                            id="wrapper"
                            className={`absolute top-12 right-0 ${isOpen ? "open mt-2" : "notOpen"}`}
                            style={{
                              height: isOpen ? "auto" : 0,
                              padding: isOpen ? "10px" : 0,
                              borderRadius: "10px",
                            }}
                            ref={wrapperRef}
                          >
                            <ul id="list">
                              <li style={{ cursor: "pointer" }}>
                                <div
                                  className="mb-2 cancel-btn d-flex justify-content-end align-items-center text-dark"
                                  id=""
                                  onClick={() => stripeManageSubscription()}
                                >
                                  {subscriptionLoader ? (
                                    <ClipLoader
                                      size={30}
                                      color={"#000"}
                                      className="me-4"
                                    />
                                  ) : (
                                    "Subscription"
                                  )}
                                </div>
                              </li>
                              <li style={{ cursor: "pointer" }}>
                                <div
                                  className="text-dark"
                                  id=""
                                  variant=""
                                  onClick={() => logout()}
                                >
                                  Logout
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </Nav>
            </Navbar.Collapse>
          ) : (
            ""
          )}
        </Container>
        <div className={`${menuToggle ? 'max-h-40' : 'max-h-0'} overflow-hidden transition-all duration-200 relative tranparent mt-6 z-10`}>
          <div className="md:hidden overflow-hidden border-b-2 border-gray-800 text-white flex flex-col items-end space-y-6 p-4 pb-8">
            <Link to="/question-bank" onClick={() => handlemenuclick} style={textStyle} className="hover:text-gray-300 px-4 transition-all">Question Bank</Link>
            <Link to="/institutions" onClick={() => setmenuToggle(false)} style={textStyle} className="hover:text-gray-300 px-4 transition-all">Institutions</Link>
            <Link to="/faq" onClick={handlemenuclick} style={textStyle} className="hover:text-gray-300 px-4 transition-all">FAQ's</Link>
          </div>
        </div>
      </Navbar>

      <LoginModal
        setHeroLoader={setHeroLoader}
        showDisplayFeatureModel={showDisplayFeatureModel}
        setDisplayFeatureModel={setDisplayFeatureModel}
        pricingPlansPopup={pricingPlansPopup}
        setPricingPlansPopup={setPricingPlansPopup}
        setFlag={setFlag}
        flag={flag}
        setEmailData={setEmailData}
        emailData={emailData}
        userQuestions={userQuestions}
        setUserQuestions={setUserQuestions}
        logintype={logintype}
        setLoginType={setLoginType}
        show={show}
        setShow={setShow}
        handleClose={handleClose}
        setLoggedInId={setLoggedInId}
        loggedInId={loggedInId}
        setIsPaymentDone={setIsPaymentDone}
        setPaymentPopup={setPaymentPopup}
        paymentPopup={paymentPopup}
        checkLoggedInStatus={checkLoggedInStatus}
      />

      <Modal
        show={showInappModal}
        onHide={handleCloseInAppModal}
        centered
        className="cancel-modal text-light"
      >
        <Modal.Header closeButton className="m-0 susbcription_modal">
          <Modal.Title className="text-light">Subscription Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-light">
            Subscription Status: {subscriptionStatus}
          </div>
          <div className="text-light">
            Subscription Valid Till: {subscriptionValidTil}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default TopBar;