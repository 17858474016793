import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Form } from "react-bootstrap";

const CookiePreferencesCenter = ({
  show,
  setShowPreferences,
  setCookieExists,
}) => {
  const [preferences, setPreferences] = useState({
    performance: true,
    functional: true,
    required: true,
  });
  const onHide = () => setShowPreferences(false);

  const handleToggle = (e) => {
    const { name, checked } = e.target;
    setPreferences((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleClose = () => {
    setShowPreferences(false);
    const preferencesString = JSON.stringify(preferences);
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 150);
    const formattedExpirationDate = expirationDate.toUTCString();
    document.cookie = `myAppCookieConsent=${preferencesString}; expires=${formattedExpirationDate}; path=/;`;
    setCookieExists(true);
  };

  return (
    <Modal show={show} onHide={onHide} className="cookie-modal border-0" centered>
        <Modal.Header className="flex flex-col p-2 py-4" closeButton>
          <Modal.Title className="text-3xl text-center w-full font-bold">
            Cookie Preferences
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="cookie-preferences p-8 px-10">
            <div className="cookies_switch">
              <Form.Check
                name="performance"
                checked={preferences.performance}
                onChange={handleToggle}
                type="switch"
                id="custom-switch"
                label="Performance Cookies"
                className="flex justify-between items-center p-0"
              />
            </div>

            <div className="cookies_switch">
              <Form.Check
                name="functional"
                checked={preferences.functional}
                onChange={handleToggle}
                type="switch"
                id="custom-switch2"
                label="Functional Cookies"
                className="flex justify-between items-center p-0"
              />
            </div>

            <div className="cookies_switch">
              <Form.Check
                name="required"
                checked={true}
                onChange={handleToggle}
                type="switch"
                id="custom-switch3"
                label="Required Cookies"
                className="flex justify-between items-center p-0"
              />
            </div>
          </div>
        </Modal.Body>
        <div className="flex flex-col gap-2 p-6 mb-4">
          <Button
            className="w-auto Cookie_preferences_button2"
            variant="secondary"
            onClick={handleClose}
          >
            Save Preferences
          </Button>
          <Button
            className="w-auto Cookie_preferences_button2"
            variant="secondary"
            onClick={handleClose}
          >
            Accept All
          </Button>
          <Button
            className="w-auto Cookie_preferences_buttondecl"
            variant="secondary"
            onClick={handleClose}
          >
            Decline All
          </Button>
          </div>
    </Modal>
  );
};

export default CookiePreferencesCenter;
