import React, { useEffect } from "react";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { PropagateLoader } from "react-spinners";

const BookPageSelectPage = (props) => {
  const handleClose = () => {
    setError("");
    props.fileName();
    props.setShowModal(false);
  };
  const [error, setError] = useState("");
  const [loaderCall, setLoaderCall] = useState(false);
  const { bookMaxPage, bookMinPage } = props;
  const submitValue = async () => {
    if (
      bookMaxPage &&
      bookMinPage != "" &&
      bookMaxPage &&
      bookMaxPage &&
      bookMinPage != null &&
      bookMinPage != undefined &&
      bookMaxPage &&
      bookMinPage != 0
    ) {
      if (parseInt(bookMinPage) > parseInt(bookMaxPage)) {
        setError("Please enter a valid page range");

      } else {
        setError("");
        try {
          setLoaderCall(true);
          const response = await axios
            .post(
              `${process.env.REACT_APP_API_ENDPOINT}/book-set-pages`,
              {
                min_page: parseInt(bookMinPage),
                max_page: parseInt(bookMaxPage),
                file_id: props.items.id,
              },
              {
                headers: {
                  "x-access-token": JSON.parse(localStorage.getItem("token")),
                },
              }
            )
            .then((value) => {
              props.setActiveId(value.data.data.id);
              setLoaderCall(false);
              props.fileName();
              props.setShowModal(false);
              props.setBookMinPage(value.data.data.minPage);
              props.setBookMaxPage(value.data.data.maxPage);
              props.setIsPagesSet(true);
            });
        } catch (err) {
          setLoaderCall(false);
        }
      }
    } else {
      setError("Book pages field is required");
    }
    props.onChange(true, props.items.id);
  };
  var totalFilePages = props.items != undefined ? props.items : 0;

  useEffect(() => {
    if (props.showModal != undefined) {
      props.setBookMinPage(
        totalFilePages.minPage > 0 ? totalFilePages.minPage : 1
      );
      props.setBookMaxPage(
        totalFilePages.maxPage > 0 ? totalFilePages.maxPage : 1
      );
    }
  }, [props.showModal]);

  const handleMinLength = (e) => {
    setError("");
    props.setBookMinPage(parseInt(e.target.value))
    if (e.target.value <= 0) {
      setError("Min page should be atleast 1");
    } else if (e.target.value > totalFilePages.totalFilePages) {
      setError(`Total ${totalFilePages.totalFilePages} Pages Only`);
    } 
  };

  const handleMaxLength = (e) => {
    props.setBookMaxPage(parseInt(e.target.value));
    setError("");
    if (e.target.value <= bookMinPage) {
      setError("Max page should not be less than min page");
    } else if (e.target.value > totalFilePages.totalFilePages) {
      setError(`Total ${totalFilePages.totalFilePages} Pages Only`);
    }
  };

  return (
    <div>
      <Modal
        backdrop="static"
        show={props.showModal != undefined ? props.showModal : false}
        onHide={handleClose}
        centered
      >
        <Modal.Header className="m-0" closeButton>
          <h5>Select Book Pages</h5>
        </Modal.Header>
        <Modal.Body>
          <div className="countofpages">
            Total Count Of Pages {totalFilePages.totalFilePages}
          </div>
          <div className="d-flex mt-4">
            <div className="w-50">
              <label htmlFor="min">
                <b>Start</b>
              </label>
              <br />
              <input
                min="0"
                oninput="this.value = Math.abs(this.value)"
                className="form-control"
                style={{ width: "95%" }}
                max={totalFilePages.totalFilePages}
                value={props.bookMinPage}
                onChange={(e) => handleMinLength(e)}
                type="number"
                name=""
                id=""
              />
            </div>
            <div className="w-50">
              <label htmlFor="max">
                <b>End</b>
              </label>
              <br />
              <input
                min="0"
                oninput="this.value = Math.abs(this.value)"
                className="form-control"
                style={{ width: "95%" }}
                max={totalFilePages.totalFilePages}
                value={props.bookMaxPage}
                onChange={(e) => handleMaxLength(e)}
                type="number"
                name=""
                id=""
              />
            </div>
          </div>
          <div className>
            {loaderCall ? (
              <div className="select_page_button mt-4 btn">
                <PropagateLoader
                  color="#fff"
                  size="8"
                  cssOverride={{ top: "-12px" }}
                />
              </div>
            ) : (
              <>
                <button
                  onClick={submitValue}
                  disabled={error?.length>0}
                  className="select_page_button mt-4 btn"
                >
                  Select
                </button>
              </>
            )}

            {error != "" ? <div className="text-danger mt-2">{error}</div> : ""}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default BookPageSelectPage;
