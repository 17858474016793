import React from "react";
import Marquee from "react-fast-marquee";
import cv from '../../../assets/icons/backers/CV.png';
import devc from '../../../assets/icons/backers/DEVC.png';
import drf from '../../../assets/icons/backers/DRF.png';
import ucb from '../../../assets/icons/backers/UCB.png';
import stnfrd from '../../../assets/icons/backers/STNFRD.png';
import cfi from '../../../assets/icons/backers/CFI.png';
import arrvc from '../../../assets/icons/backers/ARRVC.png';

function MarqueeCred(){
    return(
        <Marquee className="opacity-60 pt-40 pb-12"
        autoFill={1}
        >
            <img className="invert px-8" src={cv}/>
            <div className="p-1 rounded-full bg-accent-800 mx-8"></div>
            <img className="invert px-8" src={drf}/>
            <div className="p-1 rounded-full bg-accent-800 mx-8"></div>
            <img className="invert px-8" src={ucb}/>
            <div className="p-1 rounded-full bg-accent-800 mx-8"></div>
            <img className="invert px-8" src={stnfrd}/>
            <div className="p-1 rounded-full bg-accent-800 mx-8"></div>
            <img className="invert px-8" src={cfi}/>
            <div className="p-1 rounded-full bg-accent-800 mx-8"></div>
            <img className="invert px-8" src={arrvc}/>
            <div className="p-1 rounded-full bg-accent-800 mx-8"></div>
            <img className=" px-8" src={devc}/>
            <div className="p-1 rounded-full bg-accent-800 mx-8"></div>
        </Marquee> 
    )
}

export default MarqueeCred;