import React, { useEffect, useState } from "react";
import { MdOutlineCancel } from "react-icons/md";
const DismissibleBanner = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const bannerDismissed = localStorage.getItem("bannerDismissed");
    if (!bannerDismissed) {
      setIsVisible(true);
    }
    setIsReady(true);
  }, []);

  const handleDismiss = () => {
    setIsVisible(false);
    localStorage.setItem("bannerDismissed", "true");
  };

  if (!isReady) return null;

  if (!isVisible) return null;

  return (
    <>
      {isVisible && (
        <div className="flex justify-between items-center  text-white text-center py-2 px-4 custom_banner_main">
          <div className="flex-grow text-center banner_font_custom">
          Users of our services are prohibited from uploading, posting, or otherwise distributing any content which may violate another party's intellectual property rights. You may only upload content when you have the copyright holder’s express permission to do so.
          </div>
          <div
            onClick={handleDismiss}
            className=" btn ml-4 text-white  font-bold"
          >
            <MdOutlineCancel className="h-7 w-7" aria-hidden="true" />
          </div>
        </div>
      )}
    </>
  );
};

export default DismissibleBanner;
