import axios from 'axios';

export const handleApiError = (error) => {
  if (error.response && error.response.status === 401) {
    localStorage.removeItem("token");
    localStorage.removeItem("customer_id");
    localStorage.removeItem("payment");
    localStorage.removeItem("number");
    window.location.href = "/"; // Redirect to home page
  }
  return Promise.reject(error);
};

const setupAxiosInterceptors = () => {
    axios.interceptors.response.use(
      response => response,
      error => {
        console.log("Axios Interceptors triggered", error, "response");
        if (error.response && error.response.status === 401) {
          console.log('401 error intercepted:', error.response);
          handleApiError(error);
        }
        return Promise.reject(error);
      }
    );
  };

export default setupAxiosInterceptors;