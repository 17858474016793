import React, { useEffect, useState } from "react";

import Summary from "./Summary";
import FlashCards from "./FlashCards";
import Notes from "./Notes";
import ProgressBar from "react-bootstrap/ProgressBar";

const Tabs = ({
  summary,
  notes,
  flashcards,
  loadingFlashCards,
  loadingNotes,
  loadingSummary,
  activeId,
  isUploaded,
  reGenerateSummFn,
  progressComplete,
  summaryPercentage,
  notesPercentage,
  flashcardPercentage,
}) => {
  // State to keep track of the active tab
  const [activeTab, setActiveTab] = useState("tab1");

  // Function to change the active tab
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const lines = flashcards.split("\n"); // Splitting the paragraph into lines based on newline character.

  const tempQuestions = [];

  lines.forEach((line) => {
    if (line != "") {
      const [question, answer] = line.split(";"); // Splitting each line into question and answer.
      tempQuestions.push(question);
    }
  });

  const activeTabStyle = (tab) =>
    `!cursor-pointer px-1 py-2 text-xs font-medium rounded-lg bg-slate-100 my-3 mx-1 col ${
      activeTab === tab
        ? "border border-2 border-purple-500 text-purple-500"
        : "text-gray-700"
    } hover:text-purple-500 hover:border-purple-50 focus:outline-none focus:text-purple-500 
    focus:border-purple-500 overflow-hidden`;

  return (
    <div className="max-h-full md:h-full flex flex-col text-left">
      <div className="flex sticky flex-row text-center">
        <button
          className={activeTabStyle("tab1")}
          onClick={() => handleTabClick("tab1")}
        >
          Summary
        </button>
        <button
          className={activeTabStyle("tab2")}
          onClick={() => handleTabClick("tab2")}
        >
          Notes
        </button>
        <button
          className={activeTabStyle("tab3")}
          onClick={() => handleTabClick("tab3")}
        >
          Flashcards ({tempQuestions.length})
        </button>
      </div>

      <div className="max-h-[90%] max-md:py-0 max-md:max-h-[70%]">
        {activeTab === "tab1" &&
          (isUploaded !== "" ? (
            <div className="text-center">{isUploaded}</div>
          ) : loadingSummary ? (
            <div className="mt-1 mb-1 ">
              <div className="text-center mt-2">
                <div>
                  {!progressComplete ? (
                    <div className="examples">
                      <div className="snippet" data-title="dot-elastic">
                        <div className="stage d-flex align-items-baseline">
                          <div className="d-flex justify-content-center">
                            {" "}
                            Working our magic to get your summary
                          </div>
                          <div className="dot-elastic"></div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="examples">
                      <div className="snippet" data-title="dot-elastic">
                        <div className="stage d-flex align-items-baseline">
                          <div className="d-flex justify-content-center">
                            {" "}
                            Successful in fetching your notes! Please wait while
                            we render them
                          </div>
                          <div className="dot-elastic"></div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="d-flex justify-content-center mt-3">
                    <ProgressBar
                      id="uploadBar"
                      className="UploadProgress"
                      now={summaryPercentage}
                      label={`${summaryPercentage}%`}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <Summary summary={summary} reGenerateSummFn={reGenerateSummFn} />
          ))}
        {activeTab === "tab2" &&
          (isUploaded != "" ? (
            <div className="text-center">{isUploaded}</div>
          ) : loadingNotes ? (
            <div className="mt-1 mb-1 ">
              <div className="text-center mt-2">
                <div>
                  {!progressComplete ? (
                    <div className="examples">
                      <div className="snippet" data-title="dot-elastic">
                        <div className="stage d-flex align-items-baseline">
                          <div className="d-flex justify-content-center">
                            {" "}
                            Working our magic to get your notes
                          </div>
                          <div className="dot-elastic"></div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="examples">
                      <div className="snippet" data-title="dot-elastic">
                        <div className="stage d-flex align-items-baseline">
                          <div className="d-flex justify-content-center">
                            {" "}
                            Successful in fetching your notes! Please wait while
                            we render them
                          </div>
                          <div className="dot-elastic"></div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="d-flex justify-content-center mt-3">
                    <ProgressBar
                      id="uploadBar"
                      className="UploadProgress"
                      now={notesPercentage}
                      label={`${notesPercentage}%`}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
              <Notes
                notes={notes}
                activeId={activeId}
                reGenerateSummFn={reGenerateSummFn}
              />
          ))}
        {activeTab === "tab3" &&
          (isUploaded != "" ? (
            <div className="text-center">{isUploaded}</div>
          ) : loadingFlashCards ? (
            <div className="mt-1 mb-1 ">
              <div className="text-center mt-2">
                <div>
                  {!progressComplete ? (
                    <div className="examples">
                      <div className="snippet" data-title="dot-elastic">
                        <div className="stage d-flex align-items-baseline">
                          <div className="d-flex justify-content-center">
                            {" "}
                            Working our magic to get your flashcards
                          </div>
                          <div className="dot-elastic"></div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="examples">
                      <div className="snippet" data-title="dot-elastic">
                        <div className="stage d-flex align-items-baseline">
                          <div className="d-flex justify-content-center">
                            {" "}
                            Successful in fetching your flashcards! Please wait
                            while we render them
                          </div>
                          <div className="dot-elastic"></div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="d-flex justify-content-center mt-3">
                    <ProgressBar
                      id="uploadBar"
                      className="UploadProgress"
                      now={flashcardPercentage}
                      label={`${flashcardPercentage}%`}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <FlashCards
              flashcards={flashcards}
              reGenerateSummFn={reGenerateSummFn}
            />
          ))}
      </div>
    </div>
  );
};

export default Tabs;
