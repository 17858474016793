import React, { useState, useEffect } from "react";
import IconStar from './assets/icon/icon-star.png';
import ImageScribbleFeature from './assets/image/image-scribble-feature.png';
import pcsnap2 from '../../assets/images/product demos/desktop/snapshot2.svg';
import pcsnap3 from '../../assets/images/product demos/desktop/snapshot3.svg';
import pcsnap4 from '../../assets/images/product demos/desktop/snapshot4.svg';
import mobilesnap2 from '../../assets/images/product demos/mobile/snapshot1.svg';
import mobilesnap3 from '../../assets/images/product demos/mobile/snapshot2.svg';
import mobilesnap4 from '../../assets/images/product demos/mobile/snapshot3.svg';
import IconBubble from './assets/icon/icon-bubble.png';
import IconPhone from './assets/icon/icon-phone.svg';
import IconDesktop from './assets/icon/icon-desktop.svg';
import bgvect1 from './assets/image/Layout/Vector 11.svg'
import bgvect2 from './assets/image/Layout/Vector 10.svg'
import bgvect3 from './assets/image/Layout/Vector 9.svg'

const FEATURES = [
    {
        title: 'Snap to solve.',
        description: 'Solve your problem with record accuracy.'
    },
    {
        title: 'Follow up questions.',
        description: 'Instantly understand any part of the solution.'
    },
    {
        title: 'Your phone sees the problem too',
        description: 'Snap it, solve it - instant solutions on your phone.'
    },
];

const RadioTabData = [
    { label: 'Snapshot Mode', value: 'snapshot' },
    { label: 'Lecture Mode', value: 'lecture' },
    { label: 'Talk to Book', value: 'talkToBook' }
];

const FeatureData = [
    {
        id: 'snapshot',
        demo: pcsnap2,
        mobiledemo: mobilesnap2,
        features: FEATURES,
    },
    {
        id: 'lecture',
        demo: pcsnap3,
        mobiledemo: mobilesnap3,
        features: FEATURES,
    },
    {
        id: 'talkToBook',
        demo: pcsnap4,
        mobiledemo: mobilesnap4,
        features: FEATURES,
    }
];

export default function FeatureCards() {
    const [selectedFeature, setSelectedFeature] = useState('snapshot');
    const [activeFeature, setActiveFeature] = useState(0);
    const [animate, setAnimate] = useState(true);

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveFeature((prevFeature) => (prevFeature + 1) % FEATURES.length);
        }, 2300); 
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        setSelectedFeature(RadioTabData[activeFeature].value);
        setAnimate(false);
        setAnimate(false); // Remove the animation
        const timer = setTimeout(() => {
            setAnimate(true); // Re-apply the animation after a small delay
        }, 50); // Delay to ensure the class removal is noticed by the browser

        return () => clearTimeout(timer);
    }, [activeFeature]);

    const handleClickFeature = (index) => {
        setActiveFeature(index);
        setSelectedFeature(RadioTabData[index].value);
    };

    return (
        <div className="grid grid-cols-12 max-md:grid-cols-4 px-[100px] max-md:px-[20px] relative">
            <img src={bgvect1} className="absolute top-32 left-0"/>
            <img src={bgvect2} className="absolute top-64 right-24"/>
            <img src={bgvect3} className="absolute bottom-0 right-0"/>
            <div className="col-span-10 max-md:col-span-4 col-start-2 max-md:col-start-1 py-20 flex flex-col gap-20">
                <div className="flex flex-col gap-7 justify-center items-center">
                    <div className="w-fit flex py-1 px-[5px] items-center justify-center gap-[10px] rounded-[40px] border-[0.5px] border-purple bg-purple bg-opacity-5">
                        <img src={IconStar} width={20} height={20} alt="icon-chart"/>
                        <div className="font-manrope tracking-[-0.32px] text-b2 font-normal pr-1 text-grey-90">
                            Features
                        </div>
                    </div>
                    <span className="font-product font-normal text-[56px] max-md:text-[40px] text-grey-90 text-center leading-[114%] tracking-[-1.68px] max-md:tracking-[-1.2px]">
                        Improve your grades 
                        <span className="hidden max-md:inline">&nbsp;</span>
                        <span className="inline max-md:hidden"><br/></span>
                        <span className="relative">
                            effortlessly
                            <img src={ImageScribbleFeature} alt="image-scribble-feature" className="absolute top-[88%] md:top-[100%] left-0"/>
                        </span> 
                        <span className="hidden max-md:inline"><br/></span>
                        <span className="inline max-md:hidden">&nbsp;</span>
                        with our tools.
                    </span>
                    <span className="font-manrope font-normal text-[20px] text-[#4f4f57] leading-[130%] tracking-[-0.6px] text-center">
                        One picture or screenshot gets you instant solutions. Ask follow-up questions and
                        <span className="hidden max-md:inline">&nbsp;</span>
                        <span className="inline max-md:hidden"><br/></span>
                        explore the rest of our tools.
                    </span>
                </div>


                {FeatureData.filter(item => item.id === selectedFeature).map((item) => (
                    <img src={item.mobiledemo} alt="image-mockup-feature" className={`px-16 pt-8 w-full h-auto md:hidden
                        ${animate ? 'opacity-100 rotate-[-1.06deg] -translate-y-16 transition-all duration-[700ms]' 
                            : 'opacity-0'}`}/>
                ))}

                <div className="w-full py-10 flex flex-col items-center gap-24 max-md:gap-5 rounded-[20px] border border-[rgba(169, 176, 193, 0.60)] border-dashed bg-black bg-opacity-[0.01]">
                    <div className="flex gap-[2px] font-manrope text-[18px] tracking-[-0.48px] leading-[130%] text-purple font-normal items-center">
                        We're on 
                        <img src={IconPhone} className="w-6" alt="icon-phone" />
                        mobile
                        <span className="font-bold">&nbsp;and&nbsp;</span>
                        <img src={IconDesktop} className="w-6" alt="icon-desktop" />
                        desktop
                    </div>

                    {FeatureData.filter(item => item.id === selectedFeature).map((item, index) => (
                        <div
                            className="grid grid-cols-10 max-md:grid-cols-4 md:gap-8 max-md:px-7"
                            key={index}
                        >
                            <div 
                                className="col-span-8 max-md:col-span-4 col-start-2 max-md:col-start-1"
                            >
                                <img src={item.demo} alt="image-mockup-feature" className={`w-full h-auto max-md:hidden
                                     ${animate ? 'opacity-100 rotate-[-1.06deg] -translate-y-16 transition-all duration-[700ms]' 
                                     : 'opacity-0'}`} />
                            </div>
                            <div className="flex flex-col gap-8 col-span-6 max-md:col-span-4 col-start-3 max-md:col-start-1">
                                {item.features?.map((feature, index) => (
                                    <div 
                                        className={`${
                                            activeFeature === index ? 'rotate-[1.1deg] opacity-100 transition-all duration-500' : 'rotate-[0] opacity-[0.15] transition-all duration-1500'
                                        } w-full md:items-center flex max-md:flex-col gap-6 max-md:py-8 py-4 px-7 rounded-[12px] border-[0.5px] border-[#b1bcd9] bg-[#fefefe] hover:opacity-80 transition-all duration-300`}
                                        style={{
                                            boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.05)'
                                        }}
                                        onClick={() => handleClickFeature(index)}
                                    >
                                        <img src={IconBubble} alt="icon-bubble" className="w-[46px] h-[46px]" />
                                        <div className='flex flex-col gap-1 font-manrope'>
                                            <span className="text-[24px] max-md:text-[22px] font-semibold leading-[130%] tracking-[-0.48px] max-md:tracking-[-0.44px] text-grey-90">
                                                {feature.title}
                                            </span>
                                            <span className="text-[18px] font-normal leading-[130%] tracking-[-0.36px] text-grey-90">
                                                {feature.description}
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
