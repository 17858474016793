import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import desktop from "../images/desktop-app.svg";
import video from "../Video/hero-video.mp4";
import Footer from "../Footer";
import img1 from "../images/img1.png";
import img2 from "../images/img2.png";
import img3 from "../images/img3.png";
import img4 from "../images/img4.png";
import qrcode from "../images/qrcode.png";
import ReferCodeModel from "./ReferCodeModel";
import { useState, useEffect } from "react";
import axios from "axios";
import './styles.css';
import { Card } from "flowbite-react";
import { Link } from "react-router-dom";

export default function AfterLoginPage() {
  const [referralUrl, setReferralUrl] = useState("");

  useEffect(() => {
    referralApi();
  }, []);

  const referralApi = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT}/generate-referral-code`,
        {},
        {
          headers: {
            "x-access-token": JSON.parse(localStorage.getItem("token")),
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setReferralUrl(res.data.data);
        }
      })
      .catch((err) => { });
  };

  return (
    <>
      <div className="sectionTwo container-fluid py-5" id="m-container">
        <div className="container m-1-container">
          <div className="photo-block d-lg-flex d-md-flex" id="sub-container-1">
            <div className="p-block">
              <div>
                <h1 className="section-heading">The Chrome Extension</h1>
              </div>
              <h1 style={{ fontWeight: 'lighter', color: 'gray' }} className="section-1-description">Install and get explanations in a single snapshot.</h1>

              <div className="app-logo">
                <a
                  style={{ position: "relative", zIndex: "99" }}
                  href="https://chromewebstore.google.com/detail/snapshot-by-answersai/kmdgaoalcjcefbgaiookdaigjeepoldf?hl=en"
                  target="_blank"
                >
                  <img
                    src={desktop}
                    alt="Desktop App Download Button"
                    className="sec-7-img-1"
                  />
                </a>
              </div>
            </div>
            <div className="p-block p-blocks" id="p-block-2">
              <video width="500" loop autoPlay playsInline>
                <source src={video} />
              </video>
            </div>
          </div>
          <div >
            <div className="p-block">
              <h1 className="section-heading">More Study Tools for Desktop</h1>
            </div>
            <div className="max-w-[100%] gap-2 grid grid-cols-12 grid-rows-2 px-8">
              <Link to="/lecture/" className="col-span-12 sm:col-span-4 h-[400px] rounded-[30px] overflow-hidden shadow-md">
                <Card className=" flex flex-col w-full h-full">
                  <div className="h-1/2 overflow-hidden rounded-t-lg bg-[#6366F1] p-4 pb-0">
                    <img className="w-full h-full object-contain" alt="Lecture Mode" src={img1} />
                  </div>
                  <div className="p-4 flex-grow flex flex-col space-y-2">
                    <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                      Lecture Mode
                    </h5>
                    <p className="font-normal text-gray-700 dark:text-gray-400">
                      Master lectures with hands-free study guides and flashcards
                    </p>
                  </div>
                </Card>
              </Link>
              <Link to="/talk-to-book" className="col-span-12 sm:col-span-8 h-[400px] rounded-[30px] shadow-md overflow-hidden">
                <Card className="flex flex-col w-full h-full ">
                  <div className="h-1/2 overflow-hidden rounded-t-lg bg-[#6366F1] pt-4">
                    <img className="w-full h-full object-contain" alt="Talk to Book" src={img2} />
                  </div>
                  <div className="p-4 flex-grow flex flex-col space-y-2">
                    <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                      Talk to PDF
                    </h5>
                    <p className="font-normal text-gray-700 dark:text-gray-400">
                      Upload any PDF - lecture slides or digital notes - to learn materials and seek in-depth explanations
                    </p>
                  </div>
                </Card>
              </Link>
              <Link to="/question-bank" className="col-span-12 sm:col-span-8 h-[400px] overflow-hidden rounded-[30px] shadow-md">
                <Card className=" flex flex-col w-full h-full">
                  <div className="h-1/2 overflow-hidden rounded-t-lg bg-[#6366F1] p-4 pb-0">
                    <img className="w-full h-full object-cover" alt="Example Card 4" src={img3} />
                  </div>
                  <div className="p-4 flex-grow flex flex-col space-y-2">
                    <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                      Question Bank
                    </h5>
                    <p className="font-normal text-gray-700 dark:text-gray-400">
                      Looking for solutions? Dive into extensive archive of questions asked by over 1 million students
                    </p>
                  </div>
                </Card>
              </Link>
              <Link to="/essay-board" className="col-span-12 sm:col-span-4 h-[400px] overflow-hidden rounded-[30px] shadow-md">
                <Card className=" flex flex-col w-full h-full">
                  <div className="h-1/2 overflow-hidden rounded-t-lg bg-[#6366F1]">
                    <img className="w-full h-full object-cover" alt="Example Card 3" src={img4} />
                  </div>
                  <div className="p-4 flex-grow flex flex-col space-y-2">
                    <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                      Essay Mode
                    </h5>
                    <p className="font-normal text-gray-700 dark:text-gray-400">
                      Give instructions and let AnswersAI craft your essay in seconds
                    </p>
                  </div>
                </Card>
              </Link>

            </div>
          </div>
          <div className="max-w-[100%] gap-2 grid grid-cols-12 grid-rows-1 px-8 p-[120px] ">
            <div className="col-span-12 sm:col-span-6 h-[600px] overflow-hidden rounded-t-lg bg-white flex-col justify-start flex justify-center">
              <h2 className="text-4xl font-bold text-gray-900 dark:text-white p-4">
                Snap. Solve. Learn.
              </h2>
              <p className="text-lgtext-gray-700 dark:text-gray-400 p-4">
                Download the AnswersAI app and get solutions anywhere, anytime.
              </p>
            </div>
            <Card className="col-span-12 sm:col-span-6 h-[600px] flex flex-col overflow-hidden shadow-md">
              <div className="p-4 text-center">
                <h2 className="text-4xl font-bold text-gray-900 dark:text-white">
                  Go Mobile
                </h2>
                <p className="text-lg text-gray-700 dark:text-gray-400 mt-2">
                  Scan the QR code to download AnswersAi App
                </p>
              </div>
              <div className="flex-grow h-1/2 overflow-hidden rounded-t-lg flex justify-center items-center pb-4">
                <img className="h-full object-cover" alt="Example Card 3" src={qrcode} />
              </div>
            </Card>
          </div>
        </div>
        <Row>
          <Col lg={12} md={12} sm={12} className="mt-5">
            <ReferCodeModel referralUrl={referralUrl} />
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
}