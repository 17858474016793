import { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import stars from "../images/stars.png";
import bootom_arrow from "../images/bootom_arrow.png";
import arrow_right from "../images/ep_arrow_right.png";
import check from "../images/check.png";
import Slider from "react-slick";
import chrome_extension from "../images/chrome_extension.png";
import talk_book from "../images/talk_book.png";
import lecture_mode from "../images/lecture_mode.png";
import talkbookvideo from "../Video/Talk_to_book.mp4";
import LectureModeVideo from "../Video/LectureMode.mp4";
import essaymodeVideofrom from "../Video/essay-mode.mp4";

const tabData = [
  { name: "Monthly", isActive: false },
  { name: "Yearly", isActive: true },
];

const Tab = ({ data, isActive, handleClick }) => (
  <li
    onClick={() => handleClick(data)}
    className={isActive ? "plan_active p-2" : " p-2"}
  >
    <a href="#">{data.name}</a>
  </li>
);

const Tabs = ({ activeTab, changeTab }) => (
  <ul className="select_princing_tabs__">
    {tabData.map((tab) => (
      <Tab
        key={tab.name}
        data={tab}
        isActive={JSON.stringify(activeTab) === JSON.stringify(tab)}
        handleClick={changeTab}
      />
    ))}
  </ul>
);

const Content = ({
  setSubscriptionPlan,
  setShowStripePaymentModel,
  setShow,
  activeTab,
  setShowStripe,
  setShowPurchasePlan,
}) => (
  <div className="">
    {activeTab.name === "Monthly" ? (
      <div>
        <div className="container">
          <div className="row mt-4">
            <div className="col-1"></div>
            <div className="col-10 10 mt-3 p-0 main_princing_plan_container text-center justify-content-center">
              <div className="princing_purchase_ d-flex align-items-end justify-content-center ps-3 pe-3">
                <img src={stars} alt="stars" />
                &nbsp; Dive into Answers,
              </div>
              <div className="princing_purchase_ mt-2 ps-3 pe-3">
                Master Concepts, and{" "}
                <span style={{ color: "#6366F1" }}>Soar Academically</span>
              </div>
              <div className="discount-banner d-flex justify-content-center my-10">
                <span style={{
                  backgroundColor: "#ff6f61", 
                  color: "#fff", 
                  padding: "10px 20px",  
                  borderRadius: "5px",
                  fontWeight: "bold"
                }}>
                  Limited time - usually $14.99
                </span>
              </div>
              <div className="plan_price d-flex align-items-baseline justify-content-center ps-3 pe-3">
                <div className="mt-3 fs-3">
                  {localStorage.getItem("countryCode") == "AU" ? "A$" : "$"}{" "}
                  &nbsp;
                </div>
                <div className=" d-flex align-items-baseline">
                  <div className="dollor__price_ fw-bold">
                    {" "}
                    {localStorage.getItem("countryCode") == "AU"
                      ? "8.99"
                      : "9.99"}{" "}
                  </div>
                  <div>&nbsp;/ mo</div>
                </div>
              </div>
              <div className="purchase_plan_button mb-4 ps-3 pe-3 mt-3">
                <button
                  onClick={() => [
                    setShowPurchasePlan(false),
                    setShowStripePaymentModel(true),
                    setShow(false),
                    setSubscriptionPlan("MONTHLY"),
                  ]}
                >
                  Get Started &nbsp; <img src={arrow_right} alt="arrow_right" />
                </button>
              </div>

              <hr />
              <div className="checkmart_information_">
                <div
                  className="row"
                  style={{
                    padding: "0px 19px",
                  }}
                >
                  <div className="col-6 mt-3 p-0">
                    <div className="d-flex ps-3 ">
                      <img src={check} alt="check" />
                      <span className="ms-2 information_checkmark text-left">
                        All Features Mentioned
                      </span>
                    </div>
                    <div className="d-flex mt-3 mb-3 ps-3 ">
                      <img src={check} alt="check" />
                      <span className="ms-2 information_checkmark text-left">
                        Full Desktop App
                      </span>
                    </div>
                  </div>
                  <div className="col-6 mt-3 p-0">
                    <div className="d-flex ps-3 ">
                      <img src={check} alt="check" />
                      <span className="ms-2 information_checkmark text-left">
                        Complete Mobile App
                      </span>
                    </div>
                    <div className="d-flex mt-3 mb-3 ps-3 ">
                      <img src={check} alt="check" />
                      <span className="ms-2 information_checkmark text-left">
                        Unlimited Solutions
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-1"></div>
          </div>
        </div>
      </div>
    ) : null}
    {activeTab.name === "Yearly" ? (
      <div>
        <div className="container">
          <div className="row mt-4">
            <div className="col-1"></div>
            <div className="col-10 10 mt-3 p-0 main_princing_plan_container text-center justify-content-center">
              <div className="princing_purchase_ d-flex align-items-end justify-content-center ps-3 pe-3">
                <img src={stars} alt="stars" />
                &nbsp; Dive into Answers,
              </div>
              <div className="princing_purchase_ mt-2 ps-3 pe-3">
                Master Concepts, and{" "}
                <span style={{ color: "#6366F1" }}>Soar Academically</span>
              </div>
              <div className="discount-banner d-flex justify-content-center my-10">
                <span style={{
                  backgroundColor: "#ff6f61", 
                  color: "#fff", 
                  padding: "10px 20px",  
                  borderRadius: "5px",
                  fontWeight: "bold"
                }}>
                  {localStorage.getItem("countryCode") == "AU" ? "34% OFF - Limited Time Only!" : "67% OFF - Limited Time Only!"}
                </span>
              </div>
              <div className="plan_price d-flex align-items-baseline justify-content-center ps-3 pe-3">
                <div className="fs-3">
                  {localStorage.getItem("countryCode") == "AU" ? "A$" : "$"}
                  &nbsp;&nbsp;
                </div>
                <s className="fs-3 base_price">
                  {" "}
                  {localStorage.getItem("countryCode") == "AU"
                    ? "8.99"
                    : "9.99"}{" "}
                </s>{" "}
                <div className="mt-3">&nbsp;&nbsp;</div>
                <div className=" d-flex align-items-baseline">
                  <div className="dollor__price_ fw-bold">
                    {" "}
                    {localStorage.getItem("countryCode") == "AU"
                      ? "5.83"
                      : "3.33"}{" "}
                  </div>
                  <div>&nbsp;/ mo</div>
                </div>
              </div>
              <div className="purchase_plan_button mb-4 ps-3 pe-3 mt-3">
                <button
                  onClick={() => [
                    setShowStripePaymentModel(true),
                    setShow(false),
                    setShowPurchasePlan(false),
                    setSubscriptionPlan("YEARLY"),
                  ]}
                >
                  Get Started <img src={arrow_right} alt="arrow_right" />
                </button>
              </div>

              <hr />
              <div className="checkmart_information_">
                <div
                  className="row"
                  style={{
                    padding: "0px 19px",
                  }}
                >
                  <div className="col-6 mt-3 p-0">
                    <div className="d-flex ps-3 ">
                      <img src={check} alt="check" />
                      <span className="ms-2 information_checkmark text-left">
                        All Features Mentioned
                      </span>
                    </div>
                    <div className="d-flex mt-3 mb-3 ps-3 ">
                      <img src={check} alt="check" />
                      <span className="ms-2 information_checkmark text-left">
                        Full Desktop App
                      </span>
                    </div>
                  </div>
                  <div className="col-6 mt-3 p-0">
                    <div className="d-flex ps-3 ">
                      <img src={check} alt="check" />
                      <span className="ms-2 information_checkmark text-left">
                        Complete Mobile App
                      </span>
                    </div>
                    <div className="d-flex mt-3 mb-3 ps-3 ">
                      <img src={check} alt="check" />
                      <span className="ms-2 information_checkmark text-left">
                        Unlimited Solutions
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-1"></div>
          </div>
        </div>
      </div>
    ) : null}
  </div>
);

const SelectPricingPlanModal = ({
  setShow,
  setShowExplainModel,
  showExplainModel,
  setSubscriptionPlan,
  setShowAppStore,
  setDisplayFeatureModel,
  setSelectplanModel,
  setShowStripePaymentModel,
  handleLocalClose,
  selectplanModel,
  setShowStripe,
  setShowPurchasePlan,
}) => {
  const [activeTab, setActiveTab] = useState(tabData[1]);
  const [activeDiv, setActiveDiv] = useState(1);
  const sliderRef = useRef();

  useEffect(() => {
    let element = document.getElementById("video-component-btn");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, [showExplainModel]);

  const handleClick = (tab) => {
    setActiveTab(tab);
  };

  const settings = {
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    vertical: true,
    rows: 1,
    slidesPerRow: 1,
    autoplay: true,
    autoplaySpeed: 7000,
    infinite: true,
    afterChange: (current) => {
      setActiveDiv(current + 1);
    },
  };

  const handleClickActive = (index) => {
    if (index == 1) {
      sliderRef.current.slickGoTo(0);
    } else if (index == 2) {
      sliderRef.current.slickGoTo(1);
    } else {
      sliderRef.current.slickGoTo(2);
    }
    let element = document.getElementById("video-component-btn");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    setActiveDiv(index);
  };

  const handleCloseModal = () => {
    setShowPurchasePlan(false);
    setSelectplanModel(false);
    setDisplayFeatureModel(false);
    setShowAppStore(false);
  };

  const returnBack = () => {
    handleCloseModal();
    setDisplayFeatureModel(true);
  };

  return (
    <div>
      <Modal
        size="lg"
        show={selectplanModel}
        onHide={handleCloseModal}
        centered
      >
        <Modal.Header className="p-0 mb-0" closeButton></Modal.Header>
        {showExplainModel ? (
          <Modal.Body>
            <div className="back_button__ d-flex align-items-center ps-3">
              <div onClick={returnBack} className="button_arrow_icon">
                <span onClick={handleLocalClose} style={{ cursor: "pointer" }}>
                  &#8592; Back
                </span>
              </div>
              <div className="complete_flow_lines ms-4">
                <div
                  style={{
                    width: "190px",
                    height: "4px",
                    background: "#6366F1",
                  }}
                ></div>
                <div
                  style={{
                    width: "190px",
                    height: "4px",
                    background: "#6366F1",
                  }}
                ></div>
                <div
                  style={{
                    width: "190px",
                    height: "4px",
                    background: "rgba(255, 255, 255, 0.20)",
                  }}
                ></div>
              </div>
            </div>
            <div className="princing_plan_heading__ ps-3 mt-4">
              Wait.. There’s <span style={{ color: "#6366F1" }}>more</span>
            </div>
            <div className="mt-4">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-xl-4 col-lg-4 col-md-12">
                    <div
                      onClick={() => handleClickActive(1)}
                      className={
                        activeDiv === 1
                          ? "activeExplain essay_mode_explain_"
                          : "essay_mode_explain_"
                      }
                    >
                      <div className="essay_board_heading___ d-flex align-items-center">
                        <img
                          className="me-2"
                          src={chrome_extension}
                          alt="chrome_extension"
                        />
                        Essay Mode
                      </div>
                      <div className="essay_board_subheading___ mt-2">
                        Essay writing made effortless. Input your prompt, then
                        watch as
                      </div>
                      <div className="essaysubmenus__">
                        AI craft your perfect essay.
                      </div>
                    </div>
                    <div
                      onClick={() => handleClickActive(2)}
                      className={
                        activeDiv === 2
                          ? "activeExplain talk_book_explain_ mt-2"
                          : "talk_book_explain_ mt-2"
                      }
                    >
                      <div className="essay_board_heading___ d-flex align-items-center">
                        <img
                          className="me-2"
                          src={talk_book}
                          alt="chrome_extension"
                        />
                        Talk to Book
                      </div>
                      <div className="essay_board_subheading___ mt-1">
                        Make your textbooks talk. Upload any PDF and ask
                        away—every page becomes a conversation.
                      </div>
                    </div>
                    <div
                      onClick={() => handleClickActive(3)}
                      className={
                        activeDiv === 3
                          ? "activeExplain leacture_explain_ mt-2"
                          : "leacture_explain_ mt-2"
                      }
                    >
                      <div className="essay_board_heading___ d-flex align-items-center">
                        <img
                          className="me-2"
                          src={lecture_mode}
                          alt="chrome_extension"
                        />
                        Lecture Mode
                      </div>
                      <div className="essay_board_subheading___ mt-1">
                        Master your lectures. Provide a link and instantly
                        inquire deeper into any topic.
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-xl-8 col-lg-8 col-md-12 mt-md-4 mt-4 mt-lg-0 mt-xl-0">
                    <div className="slider-container">
                      <Slider ref={sliderRef} {...settings}>
                        <div className="custom-slide">
                          <video
                            id="video-desc-tag"
                            style={{ height: "100%" }}
                            autoPlay
                            loop
                            playsInline
                            muted
                          >
                            <source
                              src={essaymodeVideofrom}
                              typeof="video/mp4"
                            />
                          </video>
                        </div>
                        <div className="custom-slide">
                          <video
                            style={{ height: "100%" }}
                            autoPlay
                            loop
                            playsInline
                            muted
                          >
                            <source src={talkbookvideo} typeof="video/mp4" />
                          </video>
                        </div>
                        <div className="custom-slide">
                          <video
                            style={{ height: "100%" }}
                            autoPlay
                            loop
                            playsInline
                            muted
                          >
                            <source src={LectureModeVideo} typeof="video/mp4" />
                          </video>
                        </div>
                      </Slider>
                    </div>
                  </div>
                </div>

                <div className="text-end">
                  <button
                    id="video-component-btn"
                    onClick={() => setShowExplainModel(false)}
                    className="next_skip_button__"
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        ) : (
          <Modal.Body className="mb-4">
            <div className="back_button__ d-flex align-items-center ps-3">
              <div className="button_arrow_icon">
                <span
                  onClick={() => setShowExplainModel(true)}
                  style={{ cursor: "pointer" }}
                >
                  &#8592; Back
                </span>
              </div>
              <div className="complete_flow_lines ms-4">
                <div
                  style={{
                    width: "190px",
                    height: "4px",
                    background: "#6366F1",
                  }}
                ></div>
                <div
                  style={{
                    width: "190px",
                    height: "4px",
                    background: "#6366F1",
                  }}
                ></div>
                <div
                  style={{
                    width: "190px",
                    height: "4px",
                    background: "#6366F1",
                  }}
                ></div>
              </div>
            </div>
            <div className="princing_plan_heading__ mt-5 text-center">
              One step for{" "}
              <span style={{ color: "#6366F1" }}>straight A’s</span>
            </div>
            <div className="princing_plan_sub_heading__ mt-3 mb-5 text-center">
              Join 612,812 other students from top schools
            </div>
            <div className="pricing-plans-content">
              <Tabs activeTab={activeTab} changeTab={handleClick} />
              <Content
                setShow={setShow}
                setSubscriptionPlan={setSubscriptionPlan}
                setShowStripePaymentModel={setShowStripePaymentModel}
                setShowPurchasePlan={setShowPurchasePlan}
                setShowStripe={setShowStripe}
                activeTab={activeTab}
              />
            </div>
            {/* <div className="text-center mt-5">
              <a href="https://airtable.com/apptPS0LOYOx8cwdF/pag5NMyVqmL8bWIIj/form" target="_blank" rel="noopener noreferrer" className="my-5 text-blue-500 italic hover:text-purple-500">Too expensive?</a>
            </div> */}
          </Modal.Body>
        )}
      </Modal>
    </div>
  );
};

export default SelectPricingPlanModal;
