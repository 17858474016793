import React from "react"

export default function Component() {
  return (
    <div className="relative bg-gradient-to-b from-white to-gray-100">
      <section className="relative flex flex-col gap-16 items-center text-center md:m-10 pb-16 font-jakarta">
        <h1 className="text-4xl lg:text-6xl font-bold leading-tight max-w-4xl">
          AnswersAI is revolutionizing education with AI-powered learning.
        </h1>
        <div className="grid grid-cols-1 gap-6 w-full max-w-4xl">
          <div className="flex flex-col p-6 py-12 md:p-12 m-2 gap-6 col-span-2 text-center bg-gradient-to-tr from-[#D2D2FC] to-[#EDEDFF] rounded-3xl border-[2px] border-dashed"
            style={{
              borderColor: 'rgba(169, 176, 193, 0.60)',
            }}>
            <div className="flex flex-col gap-6 items-center">
              <h2 className="text-2xl md:text-4xl font-bold">Who We Are</h2>
              <p className="text-lg">
                Frustrated by overpriced textbooks and unreliable tutoring? So were we. That's why we, a team of student
                builders, created AnswersAi—harnessing cutting-edge AI to streamline learning and deliver personalized
                support.
              </p>
              <p className="text-lg font-semibold">
                Designed in SF, Delivered in LA, straight from UC Berkeley and LA.
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col text-center items-center p-8 gap-8">
          <div className="flex flex-col gap-3">
            <h2 className="max-w-5xl text-4xl md:text-5xl font-black leading-tight">
              Join the AI-powered learning revolution
            </h2>
          </div>
          <a href="/" className="inline-block">
            <button className="hover:opacity-90 hover:scale-[0.98] transition-all bg-gradient-to-b from-[#564CDA] to-[#2E16CB] p-[2px] rounded-[12px] shadow-[0_4px_17px_0px_rgba(70,_58,_209,_0.3)]">
              <div className="p-2 px-8 rounded-[10px] border bg-gradient-to-b from-[#7367F0] to-[#4839EB] shadow-[inset_0_4px_5px_0px_rgba(255,255,255,0.4)]">
                <p 
                  className="font-manrope font-regular tracking-normal text-white" 
                  style={{ textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'}}
                >
                  Get Started
                </p>
              </div>
            </button>
          </a>
        </div>
        <div className="w-full max-w-2xl mx-auto mb-8 p-6 bg-gradient-to-r from-[#D2D2FC] to-[#EDEDFF] rounded-lg shadow-lg text-center">
          <h3 className="text-2xl font-bold text-[#3F2687] mb-2">Ready to Shape the Future of Education?</h3>
          <p className="text-gray-700 mb-4">Join our team and make a difference in students' lives!</p>
          <a 
            href="https://answersai.notion.site/" 
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center gap-2 bg-white text-[#3F2687] border border-[#3F2687] font-semibold py-2 px-6 rounded-full hover:bg-[#F0F0FF] transition-colors duration-300"
            aria-label="View career opportunities at AnswersAI"
          >
            <span className="text-xl" aria-hidden="true">💼</span>
            <span>Explore Careers</span>
          </a>
        </div>
      </section>
    </div>
  )
}