import React from "react";
import twitter from './assets/logo/logo-x.svg';
import instagram from './assets/logo/logo-instagram.svg';
import linkedin from './assets/logo/logo-linkedin.svg';
import facebook from './assets/logo/logo-facebook.svg';
import youtube from './assets/logo/logo-youtube.svg';

export default function FlowbiteFooter() {
    return (
    <footer className="col-span-full grid grid-cols-4 sm:grid-cols-8 lg:grid-cols-12 gap-y-6 gap-2 sm:gap-5 flex bg-bg-light p-16 py-20 font-manrope border border-top justify-between">
      <p className="col-span-full pb-12 text-sm tracking-tight">© 2024 AnswersAi Corp. All Rights Reserved. AnswersAi, Corp. does not condone plagiarism or academic dishonesty. If our services have been misused, we reserve the right to take actions to maintain the integrity of our platform, including but not limited to co-operating with academic institutions and suspending accounts.</p>
      <div className="col-span-full sm:col-span-4 lg:col-span-8 gap-4 grid items-start text-sm text-sm justify-between ">
        <div className="flex  flex-col">
          <p className="font-bold pb-1">Address:</p>
          <p>AnswersAi, 149 New Montgomery St, San Francisco, California 94105, United States</p>
        </div>
        <div className="flex flex-col">
          <p className="pb-1">Contact:</p>
          <a href="mailto:support@answersai.com" className="max-2xl:underline text-sm font-normal">support@answersai.com</a>
        </div>
        <div className="hidden sm:flex gap-4">
          <a className="hover:opacity-80 hover:scale-[1.1] transition-all z-30" href="https://www.instagram.com/answersai/?hl=en" target="_blank">
          <img src={instagram}></img>
          </a>
          <a className="hover:opacity-80 hover:scale-[1.1] transition-all" href="https://www.linkedin.com/company/answersai/posts/?feedView=all" target="_blank">
          <img src={linkedin}></img>
          </a>
          <a className="hover:opacity-80 hover:scale-[1.1] transition-all" href="https://www.tiktok.com/@answersai.com" target="_blank">
          <img src={youtube}></img>
          </a>
        </div>
      </div>
      <div className="col-span-full sm:col-span-4 lg:col-span-4 flex items-start sm:justify-end font-semibold max-sm:pt-8 pb-8 pr-12 gap-8 sm:gap-16 ">
        <div className="flex flex-col w-fit gap-3 text-bold">
          <a className="hover:opacity-80 hover:text-accent-800 transition-all" href="https://answersai.com/">Students</a>
          <a className="hover:opacity-80 hover:text-accent-800 transition-all" href="">About Us</a>
          <a className="hover:opacity-80 hover:text-accent-800 transition-all" href="mailto:support@answersai.com">Contact Us</a>
          <a className="hover:opacity-80 hover:text-accent-800 transition-all" href="https://answersai.com/faq">FAQs</a>
        </div>
        <div className="flex flex-col gap-3 text-bold">
          <a className="hover:opacity-80 hover:text-accent-800 transition-all" href="https://answersai.com/institutions">Institutions</a>
          <a className="hover:opacity-80 hover:text-accent-800 transition-all" href="https://answersai.com/privacy-policy">Privacy</a>
          <a className="hover:opacity-80 hover:text-accent-800 transition-all" href="https://answersai.com/copyright-policy">Copyright</a>
          <a className="hover:opacity-80 hover:text-accent-800 transition-all" href="https://answersai.com/terms-of-use">T&Cs</a>
        </div>
      </div>
      <div className="col-span-full sm:hidden flex gap-4 z-30">
          <a className="hover:opacity-80 hover:scale-[1.1] transition-all" href="https://www.instagram.com/answersai/?hl=en" target="_blank">
          <img src={instagram}></img>
          </a>
          <a className="hover:opacity-80 hover:scale-[1.1] transition-all" href="https://www.linkedin.com/company/answersai/posts/?feedView=all" target="_blank">
          <img src={linkedin}></img>
          </a>
          <a className="hover:opacity-80 hover:scale-[1.1] transition-all" href="https://www.tiktok.com/@answersai.com" target="_blank">
          <img src={youtube}></img>
          </a>
        </div>
    </footer>
  );
}