import React, { useState } from "react";
import offerImg from "../images/offer.png";
import offerImg1 from "../images/offer1.png";
import audSixMonthPlan from "../images/aud_six_month_plan.png";
import audYearlyPlan from "../images/aud_yearly_plan.png";
import arrow_right from "../images/ep_arrow_right.png";
import check from "../images/check.png";

const tabData = [
  { name: "Monthly", isActive: false },
  { name: "Yearly", isActive: true },
];
const Tab = ({ data, isActive, handleClick }) => (
  <li
    onClick={() => handleClick(data)}
    className={isActive ? "plan_active p-2" : " p-2"}
  >
    {data.name}
  </li>
);

const Tabs = ({ activeTab, changeTab }) => (
  <ul className="select_princing_tabs__">
    {tabData.map((tab) => (
      <Tab
        key={tab.name}
        data={tab}
        isActive={activeTab === tab}
        handleClick={changeTab}
      />
    ))}
  </ul>
);
const Content = ({
  setSubscriptionPlan,
  setShowStripePaymentModel,
  setShow,
  activeTab,
  setShowStripe,
  setShowPurchasePlan,
  setExtPayment,
  setExSubcriptionType,
}) => (
  <div className="">
    {activeTab.name === "Monthly" ? (
      <div>
        <div className="container">
          <div className="row mt-4">
            <div className="col-12 position-relative  mt-3  main_princing_plan_container1 text-center justify-content-center w-100">
              <div className="pricing_discount d-flex pt-20">
                <div
                  className="fs-3 mt-4 dollar-sign"
                  style={{ color: "white" }}
                >
                  {localStorage.getItem("countryCode") == "AU" ? "A$" : "$"}{" "}
                  &nbsp;
                </div>
                <div
                  className=" d-flex align-items-baseline "
                  style={{ color: "white" }}
                >
                  <div className="dollor__price_" style={{ fontSize: "72px" }}>
                    {localStorage.getItem("countryCode") == "AU"
                      ? "8.99"
                      : "9.99"}
                  </div>
                  <div>&nbsp;/ mo</div>
                </div>
              </div>
              <div className="purchase_plan_button mb-4 ps-3 pe-3 mt-3 pt-4 pb-5">
                <button
                  className="pricing_modal_custom"
                  onClick={() => [
                    setShowPurchasePlan(false),
                    setShowStripePaymentModel(true),
                    setShow(false),
                    setSubscriptionPlan("MONTHLY"),
                    setExSubcriptionType("MONTHLY"),
                    setExtPayment(true),
                  ]}
                >
                  <a href="#">Get Started &nbsp;</a>
                  <img src={arrow_right} alt="arrow_right" />
                </button>
              </div>

              <div style={{ borderBottom: "2px solid #6366f1" }}></div>
              <div className="checkmart_information_">
                <div
                  className="row"
                  style={{
                    padding: "0px 19px",
                  }}
                >
                  <div className="col-6 mt-4 p-0">
                    <div className="d-flex ps-3 ">
                      <img src={check} alt="check" />
                      <span className="ms-2 information_checkmark_display text-left">
                        All Features Mentioned
                      </span>
                    </div>
                    <div className="d-flex mt-3 mb-3 ps-3 ">
                      <img src={check} alt="check" />
                      <span className="ms-2 information_checkmark_display text-left">
                        Full Desktop App
                      </span>
                    </div>
                  </div>
                  <div className="col-6 mt-4 p-0">
                    <div className="d-flex ps-3 ">
                      <img src={check} alt="check" />
                      <span className="ms-2 information_checkmark_display text-left">
                        Complete Mobile App
                      </span>
                    </div>
                    <div className="d-flex mt-3 mb-3 ps-3 ">
                      <img src={check} alt="check" />
                      <span className="ms-2 information_checkmark_display text-left">
                        Unlimited Solutions
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : null}
    {activeTab.name === "Yearly" ? (
      <div>
        <div className="container">
          <div className="row mt-4">
            <div className="col-1"></div>
            <div className="col-10 mt-3 position-relative main_princing_plan_container1 text-center justify-content-center w-100">
              <div className="discount_image_custom">
                <img
                  className="discount_image"
                  src={
                    localStorage.getItem("countryCode") == "AU"
                      ? audYearlyPlan
                      : offerImg1
                  }
                  width={164}
                  height={164}
                  alt="bootom_arrow"
                />
              </div>
              <div
                className="pricing_discount d-flex pt-20"
                style={{ color: "white" }}
              >
                <div className="fs-3 mt-4 dollar-sign">
                  {" "}
                  {localStorage.getItem("countryCode") == "AU"
                    ? "A$"
                    : "$"}{" "}
                  &nbsp;
                </div>
                <div className=" d-flex align-items-baseline ">
                  <div className="dollor__price_ " style={{ fontSize: "72px" }}>
                    {" "}
                    {localStorage.getItem("countryCode") == "AU"
                      ? "5.83"
                      : "3.33"}
                    &nbsp;
                  </div>
                  <s className="mt-3 fs-3">
                    {" "}
                    {localStorage.getItem("countryCode") == "AU"
                      ? "8.99"
                      : "9.99"}
                  </s>
                  <div>&nbsp;/ mo</div>
                </div>
              </div>
              <div className="purchase_plan_button mb-4 ps-3 pe-3 mt-3 pt-4 pb-5">
                <button
                  className="pricing_modal_custom"
                  onClick={() => [
                    setShowStripePaymentModel(true),
                    setShow(false),
                    setShowPurchasePlan(false),
                    setSubscriptionPlan("YEARLY"),
                    setExSubcriptionType("YEARLY"),
                    setExtPayment(true),
                  ]}
                >
                  <a href="#">Get Started &nbsp;</a>
                  <img src={arrow_right} alt="arrow_right" />
                </button>
              </div>

              <hr />
              <div style={{ borderBottom: "2px solid #6366f1" }}></div>
              <div className="checkmart_information_">
                <div
                  className="row"
                  style={{
                    padding: "0px 19px",
                  }}
                >
                  <div className="col-6 mt-4 p-0">
                    <div className="d-flex ps-3 ">
                      <img src={check} alt="check" />
                      <span className="ms-2 information_checkmark_display text-left">
                        All Features Mentioned
                      </span>
                    </div>
                    <div className="d-flex mt-3 mb-3 ps-3 ">
                      <img src={check} alt="check" />
                      <span className="ms-2 information_checkmark_display text-left">
                        Full Desktop App
                      </span>
                    </div>
                  </div>
                  <div className="col-6 mt-4 p-0">
                    <div className="d-flex ps-3 ">
                      <img src={check} alt="check" />
                      <span className="ms-2 information_checkmark_display text-left">
                        Complete Mobile App
                      </span>
                    </div>
                    <div className="d-flex mt-3 mb-3 ps-3 ">
                      <img src={check} alt="check" />
                      <span className="ms-2 information_checkmark_display text-left">
                        Unlimited Solutions
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-1"></div>
          </div>
        </div>
      </div>
    ) : null}
  </div>
);

export default function Pricing({
  setExtPayment,
  extPayment,
  setExSubcriptionType,
}) {
  const [show, setShow] = useState(false);
  const [showAppStore, setShowAppStore] = useState(false);
  const [showStripe, setShowStripe] = useState(false);
  const [showPurchasePlan, setShowPurchasePlan] = useState(false);
  const [selectplanModel, setSelectplanModel] = useState(false);
  const [showStripePaymentModel, setShowStripePaymentModel] = useState(false);
  const [subscriptionPlan, setSubscriptionPlan] = useState("");
  const [showExplainModel, setShowExplainModel] = useState(true);
  const [activeTab, setActiveTab] = useState(tabData[1]);
  const handleClick = (tab) => {
    setActiveTab(tab);
  };
  return (
    <section className="flex flex-col bg-black h-auto md:max-xl:h-fit md:max-xl:py-20 justify-start items-center gap-[24px] sm:gap-[36px] md:gap-[48px] max-md:py-10 md:py-20">
      <div className="container ">
        <div className="row">
          <div className="col-12">
            <h2
              className="text-center text-white text-4xl font-bold mx-auto  max-md:text-3xl px-4"
              style={{ lineHeight: "1.25" }}
            >
              <span style={{ color: "#6366F1", px: "2px" }}>
                One Subscription{" "}
              </span>
              for Desktop and Mobile
            </h2>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-8 col-md-10 col-sm-12 col-xs-12 rounded-[20px] xl:border-none xl:border-4 justify-center">
            <div className="mb-4 mt-5">
              <div className="back_button__ d-flex align-items-center">
                <div className="button_arrow_icon"></div>
              </div>
              <div>
                <Tabs activeTab={activeTab} changeTab={handleClick} />
                <Content
                  setExtPayment={setExtPayment}
                  setShow={setShow}
                  setSubscriptionPlan={setSubscriptionPlan}
                  setShowStripePaymentModel={setShowStripePaymentModel}
                  setShowPurchasePlan={setShowPurchasePlan}
                  setShowStripe={setShowStripe}
                  activeTab={activeTab}
                  setExSubcriptionType={setExSubcriptionType}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
