import { useState, useEffect } from "react";
import { RiAiGenerate } from "react-icons/ri";
import FlashCard from "./FlashCard";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Button } from "react-bootstrap";
function FlashCards({ flashcards, reGenerateSummFn }) {
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);

  const settings = {
    dots: false,
    lazyLoad: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 2,
  };
  useEffect(() => {
    // Assuming `flashcards` is a string with each question-answer pair separated by a semicolon
    // and each pair is separated from the next by a newline.
    const lines = flashcards.split("\n"); // Splitting the paragraph into lines based on newline character.

    const tempQuestions = [];
    const tempAnswers = [];

    lines.forEach((line) => {
      if (line != "") {
        const [question, answer] = line.split(";"); // Splitting each line into question and answer.
        tempQuestions.push(question);
        tempAnswers.push(answer);
      }
    });

    setQuestions(tempQuestions);
    setAnswers(tempAnswers);
  }, [flashcards]);

  return (
    <>
      <style>
        {`
                .slick-dots li button:before {
                    color: black;
                    font-size: 18px; /* Adjust the size as needed */
                }
                .slick-dots li.slick-active button:before {
                    color: black;
                    font-size: 18px; /* Adjust the size as needed */
                }
                .slick-prev:before, .slick-next:before {
                    font-size: 24px !important; /* Adjust the size as needed */
                    color: black !important; /* Adjust the color as needed */
                }
                .slick-prev, .slick-next {
                    top: 60%;
                    z-index: 10;
                }
                .slick-prev {
                    left: -40px !important; /* Further from the center on mobile */
                }
                .slick-next {
                    right: -40px !important; /* Further from the center on mobile */
                }
                @media (min-width: 640px) { /* Adjust for larger screens, 640px is Tailwind's default for 'sm' */
                .slick-prev:before, .slick-next:before {
                    font-size: 35px !important; /* Adjust the size as needed */
                } 
                .slick-prev, .slick-next {
                    top: 50% !important; /* Center vertically */
                    transform: translateY(-50%) !important; /* Ensure it's centered vertically */
                }    
                .slick-prev {
                        left: -60px !important; /* Original positioning for larger screens */
                    }
                    .slick-next {
                        right: -40px !important; /* Original positioning for larger screens */
                    }
                }
            `}
      </style>
      <div className="d-flex justify-content-end mb-3 ">
        <Button
          className="btn regenerate_btn"
          style={{ width: "fit-content" }}
          onClick={() => reGenerateSummFn("flashCards")}
        >
          Regenerate
        </Button>
      </div>
      <div className="!bg-slate-50 px-4 rounded py-4 relative h-full max-h-full overflow-y-auto flash-card-component overflow-x-auto">
        {questions.length > 0 ? (
          <Slider {...settings}>
            {questions.map((question, index) => (
              <FlashCard
                key={index}
                question={question}
                answer={answers[index]}
              />
            ))}
          </Slider>
        ) : (
          "Please enter media to get flashcards."
        )}
      </div>
    </>
  );
}
export default FlashCards;
