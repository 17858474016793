import React from "react";

const TermsForUse = () => {
  const redirectOnHomepage = () => {
    window.location.href = "/";
  };

  return (
    <div className="bg-black">
      <div className="container flex-col justify-center items-center h-screen">
        <div className="text-center row">
          <div
            className="py-10 col-12 privacy_policy_heading text-white"
            onClick={() => redirectOnHomepage()}
          >
            Answers
            <span className="privacy_ai_color__">
              Ai<sup className="text-white font-normal">&trade;</sup>
            </span>
          </div>
        </div>
        <iframe
          src="https://drive.google.com/file/d/1Wddr2b3nbkVTHZy_PANclLSjjdi81Luc/preview"
          title="Privacy Policy"
          width="100%"
          height="700"
          style={{ border: "none" }}
          className="shadow-lg rounded-lg"
        >
          Your browser does not support iframes. Please visit
          https://drive.google.com/file/d/1Wddr2b3nbkVTHZy_PANclLSjjdi81Luc/view?usp=sharing
          to view the Terms of Use.
        </iframe>
      </div>
    </div>
  );
};

export default TermsForUse;
