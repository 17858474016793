import React, { useEffect } from "react";
import TopBar from "../TopBar";
import Footer from "../Footer";
import Chat from "../commonComponents/Chat";
import UploadSideBar from "./UploadSideBar";
import Tabs from "../commonComponents/Tabs";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useState } from "react";
import toast from "react-hot-toast";
import Banner from "../commonComponents/Banner";
import { PanelGroup, Panel, PanelResizeHandle } from "react-resizable-panels";

function BookPage({
  setHeroLoader,
  loggedInId,
  setLoggedInId,
  isPaymentDone,
  setIsPaymentDone,
  paymentPopup,
  setPaymentPopup,
  showSub,
  setShowSub,
  showDisplayFeatureModel,
  setDisplayFeatureModel,
  userQuestions,
  setUserQuestions,
  emailData,
  setEmailData,
  pricingPlansPopup,
  setPricingPlansPopup,
  flag,
  setFlag,
  logintype,
  setLoginType,
  show,
  setShow,
  handleClose,
  handleShowLogin,
}) {
  const [activeId, setActiveId] = useState("");
  const [talkBookLoader, setTalkBookLoader] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 768);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const sendMessageApi = async (message) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/web-user-book-chat`,
      {
        method: "POST",
        headers: {
          "x-access-token": JSON.parse(localStorage.getItem("token")),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          question: message,
          file_id: activeId,
        }),
      }
    );
    return response;
  };

  const { id } = useParams();
  const [summary, setSummary] = useState("");
  const [notes, setNotes] = useState("");
  const [flashcards, setFlashcards] = useState("");
  const [loadingSummary, setLoadingSummary] = useState(false);
  const [loadingNotes, setLoadingNotes] = useState(false);
  const [loadingFlashCards, setLoadingFlashCards] = useState(false);
  const [summaryPercentage, setSummaryPercentage] = useState(0);
  const [notesPercentage, setNotesPercentage] = useState(0);
  const [flashcardPercentage, setFlashcardPercentage] = useState(0);
  const [messages, setMessages] = useState([]);
  const [isUploaded, setIsUploaded] = useState(
    "Please upload and select pdf to continue"
  );
  const [progressComplete, setProgessComplete] = useState(false);
  useEffect(() => {
    if (summaryPercentage && notesPercentage && flashcardPercentage === 100) {
      setProgessComplete(true);
    } else {
      setProgessComplete(false);
    }
  }, [summaryPercentage, notesPercentage, flashcardPercentage]);
  const increasePercentageForSummary = () => {
    let timer = 1;
    setSummaryPercentage((prevPercentage) => {
      let newPercentage = prevPercentage + timer;
      return newPercentage > 100 ? 100 : newPercentage;
    });
  };
  const increasePercentageForNotes = () => {
    let timer = 1;
    setNotesPercentage((prevPercentage) => {
      let newPercentage = prevPercentage + timer;
      return newPercentage > 100 ? 100 : newPercentage;
    });
  };
  const increasePercentageForFlashCards = () => {
    let timer = 1;
    setFlashcardPercentage((prevPercentage) => {
      let newPercentage = prevPercentage + timer;
      return newPercentage > 100 ? 100 : newPercentage;
    });
  };
  const fetchSummary = async (paramType = false) => {
    if (activeId) {
      setLoadingSummary(true);
      setSummaryPercentage(0);
      var interval = setInterval(increasePercentageForSummary, 100);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_ENDPOINT}/web-user-book-summary`,
          { regenerate: paramType },
          {
            headers: {
              "x-access-token": JSON.parse(
                localStorage.getItem("token")
              ),
            },
          }
        );
        setSummaryPercentage(100);
        clearInterval(interval);
        if (response.data.status) {
          setSummary(response.data.data.summary);
          setSummaryPercentage(0);
          setLoadingSummary(false);
        } else {
          toast.error(response.data.message);
          setSummaryPercentage(0);
          setProgessComplete(false);
          setLoadingSummary(false);
        }
      } catch (error) { }
    }
  };

  const fetchNotes = async (paramType = false) => {
    if (activeId) {
      setLoadingNotes(true);
      setNotesPercentage(0);
      var interval = setInterval(increasePercentageForNotes, 180);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_ENDPOINT}/web-user-book-notes`,
          { regenerate: paramType },
          {
            headers: {
              "x-access-token": JSON.parse(
                localStorage.getItem("token")
              ),
            },
          }
        );
        setNotesPercentage(100);
        clearInterval(interval);
        if (response.data.status) {
          setNotes(response.data.data.notes);
          setNotesPercentage(0);
          setLoadingNotes(false);
        }
      } catch (error) {
        setNotesPercentage(0);
        setProgessComplete(false);
        setLoadingNotes(false);
      }
    }
  };
  const fetchFlashCards = async (paramType = false) => {
    if (activeId) {
      setLoadingFlashCards(true);
      setFlashcardPercentage(0);
      var interval = setInterval(increasePercentageForFlashCards, 160);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_ENDPOINT}/web-user-book-flashcard`,
          { video_id: id, regenerate: paramType },
          {
            headers: {
              "x-access-token": JSON.parse(
                localStorage.getItem("token")
              ),
            },
          }
        );
        setFlashcardPercentage(100);
        clearInterval(interval);
        if (response.data.status) {
          setFlashcards(response.data.data.answer);
          setFlashcardPercentage(0);
          setLoadingFlashCards(false);
        }
      } catch (error) {
        setFlashcardPercentage(0);
        setLoadingFlashCards(false);
      }
    }
  };

  const fetchChatHistoryApi = async () => {
    setTalkBookLoader(true);
    try {
      const dataJson = {
        file_id: activeId,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/web-user-chatbook-history`,
        dataJson,
        {
          headers: {
            "x-access-token": JSON.parse(
              localStorage.getItem("token")
            ),
          },
        }
      );
      setTalkBookLoader(false);
      return response;
    } catch (err) {
      setTalkBookLoader(false);
    }
  };

  useEffect(() => {
    if (activeId) {
      setLoadingFlashCards(true);
      setLoadingNotes(true);
      setLoadingSummary(true);
      setIsUploaded("");
    }

    fetchSummary()
      .then((summaryData) => {
        setLoadingSummary(false);
      })
      .catch((error) => {
        setLoadingSummary(false);
      });

    fetchNotes()
      .then((notesData) => {
        setLoadingNotes(false);
      })
      .catch((error) => {
        setLoadingNotes(false);
      });

    fetchFlashCards()
      .then((flashCardsData) => {
        setLoadingFlashCards(false);
      })
      .catch((error) => {
        setLoadingFlashCards(false);
      });
    fetchChatHistoryApi()
      .then((flashCardsData) => { })
      .catch((error) => { });
  }, [activeId]);

  const reGenerateSummFn = (type) => {
    if (type == "summary") {
      setLoadingSummary(true);
      fetchSummary(true);
    } else if (type == "notes") {
      setLoadingNotes(true);
      fetchNotes(true);
    } else if (type == "flashCards") {
      setLoadingFlashCards(true);
      fetchFlashCards(true);
    }
  };
  return (
    <>
      <TopBar
        handleShowLogin={handleShowLogin}
        handleClose={handleClose}
        show={show}
        setShow={setShow}
        logintype={logintype}
        setLoginType={setLoginType}
        pricingPlansPopup={pricingPlansPopup}
        setPricingPlansPopup={setPricingPlansPopup}
        emailData={emailData}
        setEmailData={setEmailData}
        userQuestions={userQuestions}
        setUserQuestions={setUserQuestions}
        showDisplayFeatureModel={showDisplayFeatureModel}
        setDisplayFeatureModel={setDisplayFeatureModel}
        setHeroLoader={setHeroLoader}
        setFlag={setFlag}
        flag={flag}
        loggedInId={loggedInId}
        setLoggedInId={setLoggedInId}
        isPaymentDone={isPaymentDone}
        setIsPaymentDone={setIsPaymentDone}
        setPaymentPopup={setPaymentPopup}
        paymentPopup={paymentPopup}
        showSub={showSub}
        setShowSub={setShowSub}
      />
      <Banner />
      <div className="flex container-fluid md:h-screen md:max-h-screen main-div-container overflow-y-auto">
        <div className="container-fluid py-14 md:py-8 px-21 lg:px-12 flex-grow flex flex-col">
          <div className="pb-9">
            <h1 className="text-4xl pb-3">Talk to PDF</h1>
            <h3 className="text-base text-gray-500">
              Upload a PDF and instantly receive tailored summaries, interactive flashcards, practice questions, and AI-powered assistance
            </h3>
          </div>
          <div className="flex flex-grow flex-col md:flex-row">

            <PanelGroup
              direction={isMobile ? "vertical" : "horizontal"}
              className="max-md:h-screen panel-group"
            >
              <Panel className="w-full border border-gray-500 rounded-l-2xl lg:w-1/4 div-border-responsive md:h-[650px] overflow-y-auto div-margin-height-responsive">
                <UploadSideBar
                  talkBookLoader={talkBookLoader}
                  setTalkBookLoader={setTalkBookLoader}
                  setMessages={setMessages}
                  setSummary={setSummary}
                  setFlashcards={setFlashcards}
                  setNotes={setNotes}
                  activeId={activeId}
                  setActiveId={setActiveId}
                  setIsUploaded={setIsUploaded}
                  fetchChatHistoryApi={fetchChatHistoryApi}
                />
              </Panel>
              <PanelResizeHandle />
              <Panel>
                <div className="w-full p-4 border border-gray-500 max-h-full div-border-responsive md:h-[650px] div-margin-height-responsive max-md:p-0 notes-panel">
                  <Tabs
                    summary={summary}
                    notes={notes}
                    flashcards={flashcards}
                    loadingFlashCards={loadingFlashCards}
                    loadingNotes={loadingNotes}
                    loadingSummary={loadingSummary}
                    activeId={activeId}
                    isUploaded={isUploaded}
                    reGenerateSummFn={reGenerateSummFn}
                    summaryPercentage={summaryPercentage}
                    notesPercentage={notesPercentage}
                    flashcardPercentage={flashcardPercentage}
                  />
                </div>
              </Panel>
              <PanelResizeHandle />
              <Panel minSize={10}>
                <div className="w-full p-4 border border-gray-500 rounded-r-2xl md:h-[650px]
                                overflow-y-auto div-border-responsive div-margin-height-responsive">
                  <Chat
                    messages={messages}
                    setMessages={setMessages}
                    sendMessageApi={sendMessageApi}
                    fetchChatHistoryApi={
                      fetchChatHistoryApi
                    }
                    isUploaded={isUploaded}
                  />
                </div>
              </Panel>
            </PanelGroup>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default BookPage;
