import React from "react";
import FeatureScroll from "./components/FeatureScroll";
import FeatureSection2 from "./components/FeatureSection2";
import FeatureSection3 from "./components/FeatureSection3";

function Features({toggleSlideout}){
    return(
        <div className="flex flex-col items-center p-4 ">
            <FeatureScroll toggleSlideout={toggleSlideout}/>
            <FeatureSection2 />
            <FeatureSection3 />
        </div>
    )
}

export default Features;