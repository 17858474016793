import React from "react";

function FeatureCard2({ description, title, sno }) {
    return (
        <div className="bg-white max-w-md rounded-[48px]">
            <div className="flex flex-col w-full h-full p-2 gap-y-8 rounded-[48px] bg-gradient-to-t from-[#9C9EFF]/[0.3] to-accent-800/[0.3] font-jakarta">
                <div className="flex flex-col h-full gap-y-20 bg-white p-8 md:p-12 rounded-[40px]">
                    <div className="flex rounded-full items-center justify-center w-12 h-12 border-2 border-accent-800 font-bold text-lg">
                        {sno}</div>
                    <div className=" flex flex-col gap-6">
                        <h3 className="font-black text-2xl md:text-3xl">{title}</h3>
                        <p className="font-medium text-gray-600 leading-tight max-w-2xl">{description}</p>
                    </div>
                </div>
            </div>
        </div>
        
    );
}

export default FeatureCard2;