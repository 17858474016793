import React from "react";
import portal from '../../../assets/images/eduportal.svg';

function FeatureSection3(){
    return(
        <section className="flex flex-col items-center gap-24 p-4 font-jakarta py-36">
            <div className="flex flex-col text-center gap-8">
                <p className="text-accent-800 text-md font-bold">Educator Portal</p>
                <div className="flex flex-col items-center gap-8">
                    <h1 className="text-4xl lg:text-6xl leading-tight font-bold">
                        Understand your students better.
                    </h1>
                    <p className="max-w-2xl leading-tight">Keep track of courses and students effortlessly, identify key strengths and weaknesses, and improve course understanding.</p>
                </div>
            </div>
            <div className="">
                <img src={portal} className=""/>
            </div>

        </section>
    )
}

export default FeatureSection3;