import React, {useEffect, useState} from "react";
import IconHeart from "./assets/icon/icon-heart.png"
import ImageProfile1 from './assets/image/image-profile-1.png'
import ImageProfile2 from './assets/image/image-profile-2.png'
import ImageProfile3 from './assets/image/image-profile-3.png'
import ImageScribble1 from './assets/image/image-scribble-testimonial-1.png'
import ImageScribble2 from './assets/image/image-scribble-testimonial-2.png'
import ImageScribble3 from './assets/image/image-scribble-testimonial-3.png'
import ImageRating from './assets/image/image-rating.png'
import LogoTiktok from './assets/logo/logo-tiktok.png'
import LogoInstagram from './assets/logo/logo-instagram.png'
import ImageTiktok1 from './assets/image/image-mockup-testimonial-1.svg'
import ImageTiktok2 from './assets/image/image-mockup-testimonial-2.svg'
import ImageGradient from './assets/image/image-gradient-testimonials.png'

export default function Testimonials(props) {
  const handleShow = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const [isSmallScreen, setIsSmallScreen] = React.useState(false);
  const TestimonialsData = [
    {
      quote: (
        <>
        "Answers.ai has made<br/>my life so much easier!"
        </>
      ),
      name: 'Jane Argy',
      school: 'Havard Student',
      profileImage: ImageProfile1,
      scribbleImage: ImageScribble1,
    },
    {
      quote: (
        <>
        "Answers.ai makes it<br/>so easy to get the<br/>solutions to my<br/>problems!"
        </>
      ),
      name: 'Marcus Jones',
      school: 'James Logan High School',
      profileImage: ImageProfile2,
      scribbleImage: ImageScribble2
    },
    {
      quote: (
        <>
        "You’ve got to try it,<br/>better than Photomath<br/>and everything else"
        </>
      ),
      name: 'Anna Bright',
      school: 'University of Miami Student',
      profileImage: ImageProfile3,
      scribbleImage: ImageScribble3
    }
  ]
  
  const TiktokData = [
    {
      number: '39 million',
      text: (
        <>
          views<br/>on this video
        </>
      ),
      videoImage: ImageTiktok1
    },
    {
      number: '49 million',
      text: (
        <>
          views<br/>on this video
        </>
      ),
      videoImage: ImageTiktok2
    },
  ];

  const LogoData = [LogoTiktok, LogoInstagram];

  React.useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768); 
    };

    window.addEventListener('resize', handleResize);
    handleResize(); 

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="pb-20 relative overflow-hidden">
      <img src={ImageGradient} alt="image-gradient" width={627} height={1166} className="absolute max-md:transform scale-x-[-100] left-0 md:right-0 top-[20%] z-0"/>
      {TiktokData.map((item, index) => (
        <div
          className="flex flex-col absolute max-md:hidden group"
          style={{
            left: `${isSmallScreen ? `${index === 0 ? '0' : ''}` : `${index === 0 ? '0' : ''}`}`,
            right: `${isSmallScreen ? `${index === 0 ? '0' : ''}` : `${index === 0 ? '' : '-3%'}`}`,
            top: `${isSmallScreen ? `${index === 0 ? '50%' : '40%'}` : `${index === 0 ? '50%' : '40%'}`}`,
          }}
          key={index}
        >
          <a href={`${index === 0 ? "https://www.tiktok.com/t/ZTFX1fhwb/" : "https://www.tiktok.com/t/ZTFX1fhwb/"}`} target="_blank"
            style= {{
              rotate: `${isSmallScreen ? `${index === 0 ? '-8.4deg' : '8.4deg'}` : `${index === 0 ? '-8.4deg' : '8.4deg'}`}`,
              marginLeft: `${isSmallScreen ? `${index === 0 ? '' : '140px'}` : `${index === 0 ? '' : '140px'}`}`,
              alignSelf: `${isSmallScreen ? `${index === 0 ? '' : 'flex-end'}` : `${index === 0 ? '' : 'flex-end'}`}`,
            }}
            className={`mt-[-20px] opacity-30 hover:opacity-100 transition-all duration-500 peer order-2
            ${index === 0 ? 'hover:rotate-[8.4deg] hover:translate-x-20 hover:translate-y-8' : 
              'hover:-rotate-[8.4deg] hover:-translate-x-20 hover:translate-y-8'}`}
          >
              <img src={item.videoImage} alt="image-video" width={211.09} height={436.395}/>
          </a>
          <div
            className='z-20 inline-flex w-fit py-5 px-[30px] items-center gap-5 rounded-[10px] border-[0.5px] border-[#b1bcd9] transition-all duration-500
            peer-hover:-translate-y-4'
            style={{ 
              boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.05)', 
              rotate: `${index === 0 ? '4.08deg' : '-4.08deg'}`,
              background: 'rgba(254, 254, 254, 0.50)'
            }}
          >
            <div>
              <span className="font-manrope text-[20px] font-semibold leading-[130%]">
                {item.number}
              </span>
              &nbsp;
              <span className="font-manrope text-[18px] font-light leading-[130%]">
                {item.text}
              </span>
            </div>
            <div className="flex gap-[10px]">
              {LogoData.map((logo, index) => (
                  <div 
                    className="flex items-center justify-center w-[31px] h-[31px] rounded-[5px] border-[0.5px] border-[#b1bcd9] bg-[#fefefe]"
                    style={{
                      boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.05)'
                    }}
                    key={index}
                  >
                    <img src={logo} alt='logo' width={19} height={19} />
                  </div>
              ))}
            </div>
          </div>
          </div>
      ))}
      <div className="grid grid-cols-12 max-md:grid-cols-4 px-[100px] max-md:px-[20px] gap-y-[114px]">
        <div className="col-span-10 col-start-2 max-md:col-span-4 max-md:col-start-1 py-20 max-md:py-5 flex flex-col gap-12 items-center">
            <div className="flex flex-col gap-4 justify-center items-center">
                <div className="w-fit flex py-1 px-2 items-center justify-center gap-[6px] rounded-[40px] border-[0.5px] border-purple bg-purple bg-opacity-5">
                    <img src={IconHeart} width={16} height={16} alt="icon-chart"/>
                    <div className="font-manrope tracking-[-0.32px] text-b2 font-normal text-grey-90">
                      Testimonials
                    </div>
                </div>
                <span className="font-product font-normal text-[56px] max-md:text-[40px] text-grey-90 text-center leading-[114%] tracking-[-1.68px] max-md:tracking-[-1.2px]">
                  Answers you can trust..
                </span>
                <span className="font-manrope font-normal text-[20px] text-[#4f4f57] leading-[130%] tracking-[-0.6px] text-center">
                  “This app has transformed how I find answers!”
                </span>
            </div>
            <button 
            className="hover:opacity-90 hover:scale-[0.98] transition-all bg-gradient-to-b from-[#564CDA] to-[#2E16CB] p-[2px] w-fit rounded-[12px] shadow-[0_4px_17px_0px_rgba(70, 58, 209, 0.3)]"
            onClick={handleShow}>
              <div className="p-2 px-8 rounded-[10px] border bg-gradient-to-b from-gradient-secondary-start to-gradient-secondary-end shadow-[inset_0_4px_5px_0px_rgba(255,255,255,0.4)]">
                <p 
                  className="font-manrope text-white font-regular tracking-normal" 
                  style={{ textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'}}
                >
                  Start Asking 
                </p>
              </div>
            </button>
        </div>

        <div className="col-span-8 col-start-3 max-md:col-span-4 max-md:col-start-1 flex flex-col gap-[136px] md:gap-[96px]">
              {TestimonialsData.map((item, index) => (
                <div 
                  className="relative flex flex-col max-md:self-center items-center group mx-8"
                  style={{
                    alignSelf: `${index === 1 ? 'flex-end' : 'flex-start' }`
                  }}
                  key={index}
                >
                  <div 
                    className="z-30 inline-flex flex-col p-[35px] w-fit items-center justify-center rounded-[20px] border-[0.5px] border-[#b1bcd9] bg-white"
                    style={{ 
                      boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.05)',
                      rotate: `${index === 0 ? '4.75deg' : index === 1 ? '-3.27deg' : '1.8deg'}`
                    }}

                  >
                    <span className="font-manrope text-[30px] group-hover:text-[35px] font-semibold text-center leading-[130%] group-hover:leading-[45.5px] transition-all duration-300">
                      {item.quote}
                    </span>
                  </div>
                  <div 
                    className="z-50 mt-[-20px] flex items-center justify-center w-fit px-[10px] py-[5px] rounded-[50px] border-[0.5px] border-[#b1bcd9] bg-white"
                    style={{
                      boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.05)',
                      rotate: `${index === 0 ? '-0.11deg' : index === 1 ? '-8.13deg' : '5.71deg'}`
                    }}
                  >
                    <img src={ImageRating} alt="image-rating" className="w-[180px] h-auto" />
                  </div>
                  
                  <>
                    <div 
                      className="absolute flex gap-[10px] items-center z-0"
                      style={{ 
                        flexDirection: `${isSmallScreen ? `${index === 1 ? 'row-reverse' : 'row' }` : `${index === 1 ? 'row-reverse' : 'row' }`}`,
                        right: `${isSmallScreen ? `${index === 1 ? '20%' : index === 0 ? '' : '' }` : `${index === 1 ? '' : index === 0 ? '-30%' : '-60%' }`}`,
                        left: `${isSmallScreen ? `${index === 1 ? '' : '20%' }` : `${index === 1 ? '-47%' : '' }`}`,
                        top: `${isSmallScreen ? `${index === 2 ? '-30%' : index === 0 ? '-38%' : '-28%' }` : `${index === 2 ? '' : index === 0 ? '-22%' : '-16%' }`}`,
                        bottom: `${isSmallScreen ? `${index === 2 ? '' : '' }` : `${index === 2 ? '0' : '' }`}`,
                      }}
                    >
                      <img src={item.profileImage} alt="image-profile" width={82} height={71} />
                      <div className="flex flex-col">
                        <span className="font-manrope text-[16px] font-semibold leading-[150%]">
                          {item.name}
                        </span>
                        <span className="font-manrope text-[14px] font-light leading-[150%] tracking-[0.28px]">
                          {item.school}
                        </span>
                      </div>
                    </div>
                    <div 
                      className="absolute flex gap-[10px] items-center z-0"
                      style={{ 
                        flexDirection: `${isSmallScreen ? `${index === 1 ? 'row-reverse' : 'row' }` : `${index === 1 ? 'row-reverse' : 'row' }`}`,
                        right: `${isSmallScreen ? `${index === 1 ? '-6%' : index === 0 ? '' : '-30%' }` : `${index === 1 ? '' : index === 0 ? '12%' : '-30%' }`}`,
                        left: `${isSmallScreen ? `${index === 1 ? '' : index === 2 ? '-1%' : '0%' }` : `${index === 1 ? '-28%' : '' }`}`,
                        top: `${isSmallScreen ? `${index === 2 ? '-112%' : index === 0 ? '-32%' : '-26%' }` : `${index === 2 ? '' : index === 0 ? '-28%' : '12%' }`}`,
                        bottom: `${isSmallScreen ? `${index === 2 ? '32%' : '' }` : `${index === 2 ? '32%' : '' }`}`,
                      }}
                    >
                      <img 
                        src={item.scribbleImage} 
                        alt="image-profile" 
                        style={{
                          width : `${index === 0 ? '66.261px' : index === 1 ? '97px' : '112.801px'}`,
                          height : `${index === 0 ? '47.087px' : index === 1 ? '68px' : '85.813px'}`,
                          rotate: `${isSmallScreen ? (index === 1 ? '180deg' : index === 2 ? '-60deg' : '') : ''}`
                        }}
                      />
                    </div>
                  </>
                </div>
              ))}
        </div>
        <div className="md:hidden flex-col items-center flex gap-20 col-span-10">
        {TiktokData.map((item, index) => (
          <div
            className="flex flex-col items-center"
            style={{
              left: `${isSmallScreen ? `${index === 0 ? '0' : ''}` : `${index === 0 ? '0' : ''}`}`,
              right: `${isSmallScreen ? `${index === 0 ? '0' : ''}` : `${index === 0 ? '' : '-3%'}`}`,
              top: `${isSmallScreen ? `${index === 0 ? '50%' : '40%'}` : `${index === 0 ? '50%' : '40%'}`}`,
            }}
            key={index}
          >
            <div
              className="z-20 inline-flex w-fit py-5 px-[30px] items-center gap-5 rounded-[10px] border-[0.5px] border-[#b1bcd9]"
              style={{ 
                boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.05)', 
                rotate: `${index === 0 ? '4.08deg' : '-4.08deg'}`,
                background: 'rgba(254, 254, 254, 0.50)'
              }}
            >
              <div>
                <span className="font-manrope text-[20px] font-semibold leading-[130%]">
                  {item.number}
                </span>
                &nbsp;
                <span className="font-manrope text-[18px] font-light leading-[130%]">
                  {item.text}
                </span>
              </div>
              <div className="flex gap-[10px]">
                {LogoData.map((logo, index) => (
                    <div 
                      className="flex items-center justify-center w-[31px] h-[31px] rounded-[5px] border-[0.5px] border-[#b1bcd9] bg-[#fefefe]"
                      style={{
                        boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.05)'
                      }}
                      key={index}
                    >
                      <img src={logo} alt='logo' width={19} height={19} />
                    </div>
                ))}
              </div>
            </div>
            <a href={`${index === 0 ? "https://www.tiktok.com/t/ZTFX1fhwb/" : "https://www.tiktok.com/t/ZTFX1fhwb/"}`} target="_blank"
              style= {{
                rotate: `${isSmallScreen ? `${index === 0 ? '-8.4deg' : '8.4deg'}` : `${index === 0 ? '-8.4deg' : '8.4deg'}`}`,
                marginLeft: `${isSmallScreen ? `${index === 0 ? '' : '140px'}` : `${index === 0 ? '' : '140px'}`}`,
                alignSelf: `${isSmallScreen ? `${index === 0 ? '' : 'flex-end'}` : `${index === 0 ? '' : 'flex-end'}`}`,
              }}
              className={`mt-[-20px] opacity-30 hover:opacity-100 transition-all duration-500 peer order-2
                ${index === 0 ? 'ml-[-20px] hover:rotate-[8.4deg] hover:translate-x-4 hover:translate-y-8' : 
                  'ml-[20px] hover:-rotate-[8.4deg] hover:-translate-x-4 hover:translate-y-8'}`}
            >
                <img src={item.videoImage} alt="image-video" width={316.635} height={654.592}/>
            </a>
          </div>
        ))}
        </div>
      </div>
    </div>
  )
}