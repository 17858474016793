import React, { useState } from "react";
import DOMPurify from "dompurify";

const FAQSection = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleQuestion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const faqData = [
    {
      question: "Where can I use AnswersAi?",
      answer:
        'After purchasing our subscription, you have unlimited access to our online tools through our <a href="https://answersai.com" target="_blank" rel="noopener noreferrer" style="color: #1a73e8; text-decoration: underline;">website</a>, our <a href="https://chromewebstore.google.com/detail/snapshot-by-answersai/kmdgaoalcjcefbgaiookdaigjeepoldf?hl=en" target="_blank" rel="noopener noreferrer" style="color: #1a73e8; text-decoration: underline;">Chrome Extension</a>, and our <a href="https://onelink.to/9h62gy" target="_blank" rel="noopener noreferrer" style="color: #1a73e8; text-decoration: underline;">Mobile App</a> (available on iOS and Android)!',
    },
    {
      question: "How can I use the Chrome Extension?",
      answer:
        'Download our extension at the <a href="https://chromewebstore.google.com/detail/snapshot-by-answersai/kmdgaoalcjcefbgaiookdaigjeepoldf?hl=en" target="_blank" rel="noopener noreferrer" style="color: #1a73e8; text-decoration: underline;">Chrome Web Store</a>. Once you have installed the extension, you can use it on any website by clicking on the AnswersAi icon in the top right corner of your browser.',
    },
    {
      question: "How can I download the mobile app?",
      answer:
        'You can download the app <a href="https://onelink.to/9h62gy" target="_blank" rel="noopener noreferrer" style="color: #1a73e8; text-decoration: underline;">here</a>! The app is available on both iOS and Android devices.',
    },
    {
      question:
        "How do I change my subscription plan or cancel my subscription?",
      answer:
        'Please log on to your account on our website and go to the "Subscription" tab. From there, you can change your subscription plan or cancel your subscription. If you have any issues, please email us at support@answersai.com.',
    },
    {
      question: "Any other questions?",
      answer:
        "Please email support@answersai.com! We will do our best to help you with your question.",
    },
    {
      question: "What if I see my content being infringed on this website?",
      answer:
        'Please refer to our  <a href="/copyright-policy" style="color: #1a73e8; text-decoration: underline;">Copyright Dispute Policy</a> to learn how to request a takedown.',
    },
  ];

  return (
    <section className="bg-black">
      <div className="container max-w-4xl px-6 py-10 mx-auto">
        <h1 className="text-2xl font-semibold text-center text-white lg:text-3xl dark:text-white">
          Frequently asked questions
        </h1>

        <div className="mt-12 space-y-8">
          {faqData.map((faq, index) => (
            <div
              key={index}
              className="border-2 border-gray-100 rounded-lg dark:border-gray-700"
            >
              <button
                className="flex items-center justify-between  w-full p-8"
                onClick={() => toggleQuestion(index)}
              >
                <h1 className="font-semibold md:text-left text-center w-full text-gray-200 ">
                  {faq.question}
                </h1>

                <span
                  className={`${
                    openIndex === index
                      ? "text-gray-400 bg-gray-200"
                      : "text-gray-400 bg-gray-200"
                  } rounded-full`}
                ></span>
              </button>

              {openIndex === index && (
                <>
                  <hr className="border-gray-200 dark:border-gray-700" />
                  <p
                    className="p-8 text-sm text-white dark:text-gray-300"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(faq.answer),
                    }}
                  ></p>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQSection;
