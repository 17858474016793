import React, {useState, useEffect} from "react";
import bgvector from "../../assets/images/herovector.svg";
import MarqueeCred from "./components/MarqueeCred";
import rtarrow from "../../assets/icons/rtarrow2.svg";
import heroscreen from "../../assets/images/heroscreenvector.svg";

function Hero({toggleSlideout}){
    const texts=['grade assignments.','detect AI plagiarism.', 'create course material.', 'plan your lessons.'];
    const [currentText, setCurrentText]=useState(texts[0]);
    const [slide, setSlide] = useState(true);
    const [index, setIndex] = useState(0);
  
    useEffect(() => {
      const interval = setInterval(() => {
        setSlide(false); 
        setTimeout(() => {
          setIndex((prevIndex) => (prevIndex + 1) % texts.length);
          setCurrentText(texts[(index + 1) % texts.length]);
          setSlide(true);  
        }, 700); 
      }, 1000);  
  
      return () => clearInterval(interval);
    }, [index]);

    return(
     <div className="flex flex-col space-y-20 py-24 justify-between items-center font-jakarta ">     
        <div className="flex flex-col items-center text-center z-10" >
            <div className="flex flex-col text-center justify-center">
                <div className="relative text-4xl md:text-7xl max-md:max-w-3xl max-w-5xl font-black p-4 pb-8">
                    The<span className="p-4 text-transparent bg-gradient-to-r from-accent-800 to-[#352C94] bg-clip-text">AI Assistant</span>to help you&nbsp;
                    <div className={`inline-block ${slide ? 'animate-slidein' : ''}`}> {currentText}</div>
                </div>
                <div className="flex max-w-lg self-center pb-10">
                    Supercharge your teachers with the most reliable, safest educator-focused AI Assistant.
                </div>
            </div>
            <button className="p-6 py-3 bg-accent-800/[0.2] text-black w-fit backdrop-blur-3xl rounded-full border border-2 border-accent-800 drop-shadow-[0_0_24px_rgba(0,0,0,0.10)] hover:scale-[1.01] hover:shadow-[0_0_32px_rgba(91,94,216,0.6)] transition-all duration-300">
                <div className="flex gap-2 font-medium" onClick={toggleSlideout}>
                Get Started
                <img className="w-4" src={rtarrow}></img>
                </div>
            </button>
        </div>
        <div className="flex flex-col items-center relative w-full">
            <img src={bgvector} className="absolute -top-8 object- lg:-top-32 w-full z-0"></img>
            <div className="flex justify-center max-w-6xl p-4 z-10">
                <img src={heroscreen}></img>
            </div>
        </div>
        <MarqueeCred/>
     </div>   
    )
}

export default Hero;