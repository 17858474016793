import React, { useState, useRef, useEffect } from "react";
import Hero from "./Hero";
import Features from './Features';
import Testimonial from "./Testimonial";
import About from "./About";
import Footer from "../Footer";
import TopBar from "../TopBar";
import FormEmbed from "./components/FormEmbed";
import close from "../../assets/icons/closebtn.svg";
import Blurb from "../AboutUs/Blurb"

function Institution({
    heroLoader,
    setHeroLoader,
    loggedInId,
    setLoggedInId,
    isPaymentDone,
    setIsPaymentDone,
    paymentPopup,
    setPaymentPopup,
    showSub,
    setShowSub,
    showDisplayFeatureModel,
    setDisplayFeatureModel,
    userQuestions,
    setUserQuestions,
    emailData,
    setEmailData,
    pricingPlansPopup,
    setPricingPlansPopup,
    flag,
    setFlag,
    logintype,
    setLoginType,
    show,
    setShow,
    handleClose,
    handleShowLogin,
  }){
    const [isOpen, setIsOpen] = useState(false);
    const formRef = useRef(null);

    const toggleSlideout = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (formRef.current && !formRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen]);
    

    return(
        <section className="relative">
           <div
            className={`fixed flex top-0 right-0 h-full w-full bg-black bg-opacity-40 shadow-lg justify-end transition-all
            ${isOpen ? 'z-30 opacity-100 ' : 'opacity-0 z-0'}`}>
                <div 
                className={`flex flex-col p-4 bg-white max-md:w-sm max-w-lg items-end transform transition-transform duration-300 
                ${isOpen ? 'translate-x-0' : 'translate-x-full'}`}
                ref={formRef}
                >
                    <div className="flex items-center justify-center cursor-pointer" 
                    onClick={toggleSlideout}><img className="p-3 bg-accent-800 bg-opacity-30 hover:bg-opacity-20 rounded-full transition-all" src={close} /></div>
                    <FormEmbed />
                </div>
            </div>
            <TopBar
                handleShowLogin={handleShowLogin}
                handleClose={handleClose}
                show={show}
                setShow={setShow}
                logintype={logintype}
                setLoginType={setLoginType}
                pricingPlansPopup={pricingPlansPopup}
                setPricingPlansPopup={setPricingPlansPopup}
                emailData={emailData}
                setEmailData={setEmailData}
                userQuestions={userQuestions}
                setUserQuestions={setUserQuestions}
                showDisplayFeatureModel={showDisplayFeatureModel}
                setDisplayFeatureModel={setDisplayFeatureModel}
                setHeroLoader={setHeroLoader}
                setFlag={setFlag}
                flag={flag}
                loggedInId={loggedInId}
                setLoggedInId={setLoggedInId}
                isPaymentDone={isPaymentDone}
                setIsPaymentDone={setIsPaymentDone}
                setPaymentPopup={setPaymentPopup}
                paymentPopup={paymentPopup}
                showSub={showSub}
                setShowSub={setShowSub}
            />
            <Hero toggleSlideout={toggleSlideout}/>
            <Features toggleSlideout={toggleSlideout} />
            <Testimonial />
            <About toggleSlideout={toggleSlideout}/>
            <Blurb />
            <Footer />
        </section>
    )
}

export default Institution;