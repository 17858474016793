import React from 'react'
import Blurb from './Blurb'
import About from '../Institution/About'
import TopBar from '../TopBar'
import Footer from '../Footer'


function index({
    heroLoader,
    setHeroLoader,
    loggedInId,
    setLoggedInId,
    isPaymentDone,
    setIsPaymentDone,
    paymentPopup,
    setPaymentPopup,
    showSub,
    setShowSub,
    showDisplayFeatureModel,
    setDisplayFeatureModel,
    userQuestions,
    setUserQuestions,
    emailData,
    setEmailData,
    pricingPlansPopup,
    setPricingPlansPopup,
    flag,
    setFlag,
    logintype,
    setLoginType,
    show,
    setShow,
    handleClose,
    handleShowLogin,
}) {
  return (
    <>
        <TopBar
        handleShowLogin={handleShowLogin}
        handleClose={handleClose}
        show={show}
        setShow={setShow}
        logintype={logintype}
        setLoginType={setLoginType}
        pricingPlansPopup={pricingPlansPopup}
        setPricingPlansPopup={setPricingPlansPopup}
        emailData={emailData}
        setEmailData={setEmailData}
        userQuestions={userQuestions}
        setUserQuestions={setUserQuestions}
        showDisplayFeatureModel={showDisplayFeatureModel}
        setDisplayFeatureModel={setDisplayFeatureModel}
        setHeroLoader={setHeroLoader}
        setFlag={setFlag}
        flag={flag}
        loggedInId={loggedInId}
        setLoggedInId={setLoggedInId}
        isPaymentDone={isPaymentDone}
        setIsPaymentDone={setIsPaymentDone}
        setPaymentPopup={setPaymentPopup}
        paymentPopup={paymentPopup}
        showSub={showSub}
        setShowSub={setShowSub}
      />
        <About />
        <Blurb />
        <Footer />
    </>
  )
}

export default index