import React, { useEffect, useState } from "react";
import { LuSearch } from "react-icons/lu";
import { Card } from "react-bootstrap";
import share from "../images/share.png";
import { useParams } from "react-router-dom";
import explanation from "../images/explantion.png";
import axios from "axios";
import { MdClear } from "react-icons/md";
import blur2 from "../../components/images/Blur2.png";
import ShareChatPopUp from "./ShareChatPopUp";
import LoginModal from "../LoginModal";
import { handleApiError } from '../../utils/apiErrorHandler';

export const ShowFulllAnswer = ({
  searchQuery,
  setSearchQuery,
  loadData,
  setShowFullQuestion,
  isPaymentDone,
  setShareChatModal,
  shareChatModal,
  setHeroLoader,
  setIsPaymentDone,
  setShowSub,
  setSubStatus,
  setSubValidTill,
  setCancelButton,
  setCancelSubDiv,
  setSubDetails,
  setDisplayFeatureModel,
  setPaymentPopup,
  setLoggedInId,
  setUserQuestions,
  showDisplayFeatureModel,
  pricingPlansPopup,
  setPricingPlansPopup,
  flag,
  setFlag,
  setEmailData,
  emailData,
  userQuestions,
  logintype,
  setLoginType,
  show,
  setShow,
  handleClose,
  loggedInId,
  paymentPopup,
  handleShowLogin,
  setQuestionLoader,
  setQuestionBankData,
  setCurrentPage,
}) => {
  const [showData, setShowData] = useState([]);
  const { id } = useParams();
  const [clearButton, setClearButton] = useState(false);

  const showAnswer = async () => {
    let token =
      localStorage.getItem("token") != ""
        ? JSON.parse(localStorage.getItem("token"))
        : "";
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/question-id`,
      {
        id: id,
      },
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
    setShowData(response.data.data[0]);
  };

  useEffect(() => {
    if (!id) {
      setShowFullQuestion(false);
    }

    showAnswer();
  }, [id]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    loadData();
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setSearchQuery(inputValue);
    inputValue.length > 0 ? setClearButton(true) : setClearButton(false);
    setQuestionLoader(true);
  };

  const handleClearData = async () => {
    setSearchQuery("");
    setQuestionBankData([]);
    setCurrentPage(1);
    setClearButton(false);
    await loadData();
  };

  const todayDate = new Date();
  const checkLoggedInStatus = async () => {
    try {
      await axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}/check-payment-status`,
          {
            customer_id: localStorage.getItem("customer_id")
              ? localStorage.getItem("customer_id").toString()
              : "",
          },
          {
            headers: {
              "x-access-token": JSON.parse(localStorage.getItem("token")),
            },
          }
        )
        .then((value) => {
          window.ttq.track("InitiateCheckout", {
            value: 14.99,
            currency: "USD",
          });
          var dateObj = new Date(value.data.current_period_end * 1000);
          var date =
            dateObj.getDate() +
            "/" +
            (dateObj.getMonth() + 1) +
            "/" +
            dateObj.getFullYear();
          if (value.data.subscription == "active") {
            setHeroLoader(false);
            setIsPaymentDone(true);
            setShowSub(true);
            localStorage.setItem("payment", true);
            setSubStatus(true);
            setSubValidTill(date);
          } else if (value.data.subscription == "canceled") {
            if (value.data.current_period_end) {
              if (dateObj > todayDate) {
                setHeroLoader(false);
                localStorage.setItem("payment", true);
                setCancelButton(false);
                setCancelSubDiv(false);
                setSubDetails(true);
                setIsPaymentDone(true);
                setShowSub(true);
                setSubValidTill(date);
                setSubStatus(true);
              } else {
                setSubStatus(false);
                localStorage.removeItem("payment");
              }
            }
          } else if (value.data.subscription == false) {
            setHeroLoader(false);
            localStorage.removeItem("payment");
            setIsPaymentDone(false);
            setDisplayFeatureModel(true);
            setPaymentPopup(true);
          }
        });
    } catch (err) {
      if (err.request.status == 401) {
        setHeroLoader(false);
        setLoggedInId("");
        handleApiError(err);
      }
    }

    if (localStorage.getItem("customer_id") === null) {
      localStorage.removeItem("token");
    }
  };

  const checkUserQuestions = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT}/check-user-questions`,
        {
          user_id: localStorage.getItem("customer_id"),
        },
        {
          headers: {
            "x-access-token": JSON.parse(localStorage.getItem("token")),
          },
        }
      )
      .then((response) => {
        if (response.data.userQuestion) {
          setUserQuestions(true);
        } else {
          localStorage.setItem("number", JSON.stringify(response.data.number));
          checkLoggedInStatus();
        }
      });
  };

  const handleShowPaymentPopup = () => {
    let customer_id = localStorage.getItem("customer_id");
    if (!customer_id) {
      handleShowLogin();
    } else if (!isPaymentDone) {
      checkUserQuestions();
    } else if (isPaymentDone) {
      setShareChatModal(true);
    }
  };

  const ParseAnswer = (answer) => {
    try {
      if (typeof answer !== "string" || answer.trim() === "") {
        return null;
      }
      const sanitizedAnswer = answer.replace(/[\u0000-\u001F]/g, "");
      const startIndex = sanitizedAnswer.indexOf("{");
      if (startIndex === -1 || startIndex === sanitizedAnswer.length - 1) {
        return null;
      }
      const jsonString = sanitizedAnswer.slice(startIndex);
      const answersObject = JSON.parse(jsonString);
      if (Array.isArray(answersObject.explanation)) {
        answersObject.explanation = answersObject.explanation.join("\n");
        answersObject.explanation = answersObject.explanation.replace(/"/g, "");
        let explain = answersObject.explanation.slice(0, 120);
        if (answersObject.explanation.length > 120) {
          explain += "...";
        }
        return explain;
      } else if (typeof answersObject.explanation === "string") {
        answersObject.explanation = answersObject.explanation.replace(/"/g, "");
        let explain = answersObject.explanation;
        return explain;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  const optionChoice = (option) => {
    try {
      const jsonData = JSON.parse(option);

      if (jsonData.result && Array.isArray(jsonData.result)) {
        const choices = jsonData.result.map((item) => item.option_choice);
        const formattedChoices = choices.map((choice) => {
          if (typeof choice === "string" && /^\d+$/.test(choice)) {
            return choice.split("").join(", ");
          } else {
            return choice;
          }
        });
        return formattedChoices.join(", ");
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  function parseQuestions(questions) {
    return questions.split("\n");
  }

  return (
    <>
      <form
        className="container-fluid main_Question_bank_"
        onSubmit={handleFormSubmit}
      >
        <div className="container">
          <div className="row">
            <div className="co-12 position-relative">
              <div className="question_bank_searchbar__ m-auto text-center">
                <input
                  type="text"
                  value={searchQuery}
                  onChange={handleInputChange}
                  className="question___search_bar_ multiline-input input-placeholder"
                  placeholder="Need explanations? Ask any question here."
                />
                {!clearButton ? (
                  <div
                    className="search_icon_question_bank"
                    style={{ cursor: "pointer" }}
                  >
                    <LuSearch />
                  </div>
                ) : (
                  <div
                    className="search_icon_question_bank"
                    style={{ cursor: "pointer" }}
                    onClick={handleClearData}
                  >
                    <MdClear />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>

      <div
        className="container-fluid second_Question_bank_"
        id="showFullQuestion"
      >
        <div className="container">
          <div className="row">
            <div className="container">
              <div className="col-12 d-flex justify-content-center">
                <Card
                  style={{ width: "90%", backgroundColor: "black" }}
                  className="mx-3 mt-3"
                >
                  <Card.Body className="second_question_explanation p-5">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="second_Question_bank_subject">
                        General
                      </div>
                      <div className="share_question_card position-relative">
                        <img
                          src={share}
                          onClick={handleShowPaymentPopup}
                          alt="share"
                          style={{ cursor: "pointer" }}
                          className="share_chat"
                        />
                      </div>
                    </div>
                    <div className="share_question_body">
                      {showData?.questions &&
                        parseQuestions(showData.questions).map(
                          (line, index) => <div key={index}>{line}</div>
                        )}
                    </div>
                    <div className="footer_div_share_question2">
                      <div className="share_question_exlanation p-4">
                        {isPaymentDone ? (
                          <div className="explanation_icon d-flex align-items-center pb-5 mt-4">
                            <img src={explanation} alt="" />
                            <span className="Explanation_text ms-2 text-light">
                              Explanation
                            </span>
                          </div>
                        ) : (
                          ""
                        )}
                        <div>
                          {isPaymentDone ? (
                            <div className="p-3 pt-1">
                              {optionChoice(showData?.answers) !== null ? (
                                optionChoice(showData?.answers) !== "" ? (
                                  <div className="explantion_button_ text-light">
                                    {optionChoice(showData?.answers)}
                                  </div>
                                ) : (
                                  <div className="other_div"></div>
                                )
                              ) : null}

                              <div className="share_question_subheading pt-2 text-light mt-5">
                                <div>{ParseAnswer(showData?.answers)}</div>
                              </div>
                            </div>
                          ) : (
                            <img
                              src={blur2}
                              alt="blur"
                              onClick={handleShowPaymentPopup}
                              className="w-100"
                              style={{ cursor: "pointer" }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
              <div className="row justify-content-center">
                <div
                  style={{ width: "90%" }}
                  className="showHeading text-light"
                >
                  Other students also asked...
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ShareChatPopUp
        setShareChatModal={setShareChatModal}
        shareChatModal={shareChatModal}
      />

      <LoginModal
        setHeroLoader={setHeroLoader}
        showDisplayFeatureModel={showDisplayFeatureModel}
        setDisplayFeatureModel={setDisplayFeatureModel}
        pricingPlansPopup={pricingPlansPopup}
        setPricingPlansPopup={setPricingPlansPopup}
        setFlag={setFlag}
        flag={flag}
        setEmailData={setEmailData}
        emailData={emailData}
        userQuestions={userQuestions}
        setUserQuestions={setUserQuestions}
        logintype={logintype}
        setLoginType={setLoginType}
        show={show}
        setShow={setShow}
        handleClose={handleClose}
        setLoggedInId={setLoggedInId}
        loggedInId={loggedInId}
        setIsPaymentDone={setIsPaymentDone}
        setPaymentPopup={setPaymentPopup}
        paymentPopup={paymentPopup}
        checkLoggedInStatus={checkLoggedInStatus}
      />
    </>
  );
};
