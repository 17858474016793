import React from "react";

function FeatureCard1({ description, title, image }) {
    return (
        <div className="flex flex-col gap-y-8">
            <div className="bg-gray-200 rounded-3xl max-w-2xl w-full">
                <img src={image}></img>
            </div>
            <div className="flex flex-col gap-y-6">
                <div className=" flex flex-col gap-y-3">
                    <h3 className="font-semibold text-2xl">{title}</h3>
                    <p className="max-w-2xl">{description}</p>
                </div>
            </div>
        </div>
    );
}

export default FeatureCard1;